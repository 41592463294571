import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() text: string;
  @Input() btnStyle: 'secondary' | 'info' | 'warn' | 'clear';
  @Input() disabled?: boolean;
  @Input() shadow?: boolean;
  @Input() lightText?: boolean;
  @Output() clickEvent = new EventEmitter<any>();
/**
 * @param btnStyle btnStyle
 * @param shadow shadow
 * @param disabled disabled
 * @param lightText lightText
 * @returns return
 */
  addBtnClass(btnStyle: string, shadow?: boolean, disabled?: boolean, lightText?: boolean): Array<string> {
    const classToAdd: Array<string> = [];

    if (shadow) {
      classToAdd.push('btn--shadow');
    }

    if (disabled) {
      classToAdd.push('btn--disabled');
    }

    if (lightText) {
      classToAdd.push('btn--light-text');
    }

    switch (btnStyle) {
      case 'secondary':
        classToAdd.push('btn--secondary');
        break;
      case 'info':
        classToAdd.push('btn--info');
        break;
      case 'warn':
        classToAdd.push('btn--warn');
        break;
      case 'clear':
        classToAdd.push('btn--clear');
        break;
      default:
        classToAdd.push('btn--primary');
        break;
    }

    return classToAdd;
  }
/**
 * on clicke
 */
  onClick(): void {
    this.clickEvent.emit();
  }
}
