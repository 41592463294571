/**
 * @description Extract only the currend day, month and year from server date
 * @param date date
 * @returns return
 */
export const extractCurrentDateWithoutHours: (date: string) => string = (date: string): string => {
  const dateServerRegExp = new RegExp(/((\d{2})[-](\d{2})[-](\d{4})(\s)(\d{2})[:](\d{2})[:](\d{2})*)/gi);
  const DDMMYYYYregexp = new RegExp(/(\d{2})[-](\d{2})[-](\d{4})*/);
  const validDate: string = date.replace(/[/]/, '-');
  const isValidDate: boolean = dateServerRegExp.test(validDate);
  let validate: string = date;
  if (!isValidDate) {
    validate = date;
  } else {
    validate = validDate.match(DDMMYYYYregexp)[0];
  }

  return validate;
};
