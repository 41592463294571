import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from './tab.interface';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: [ './tabs.component.scss' ]
})
export class TabsComponent {
  @Input() tabs: Tab[];
  @Input() selectedTab: string;
  @Output() tabChange = new EventEmitter<{
    index: number;
    tab: Tab;
  }>();
/**
 * @param index index
 * @param tab tab
 */
  public onChangeTab(index: number, tab: Tab): void {
    this.tabChange.emit({
      index,
      tab
    });
  }

  /**
   * trackByFn
   * @param item item
   * @returns item id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
