import { AfterViewChecked, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appInputAria]'
})
/**
 * InputAriaDirective
 */
export class InputAriaDirective implements AfterViewChecked, OnInit {
  @Input() inputAriaLabel: string;

  public currentElement: any;
/**
 * @param el element
 * @param translate translate
 */
  constructor(private el: ElementRef, private translate: TranslateService) {
    this.el = el;
    this.translate = translate;
  }
/**
 * on iit
 */
  ngOnInit(): void {
    this.currentElement = this.el.nativeElement;
  }
/**
 * after view checked
 */
  ngAfterViewChecked(): void {
    const inputId: any = [...this.currentElement.querySelectorAll('input')][0];

    let ariaLabel: string;

    if (this.inputAriaLabel) {
      this.translate.get(this.inputAriaLabel).subscribe((res) => {
        ariaLabel = res;
      });
    }

    inputId.setAttribute('aria-label', ariaLabel || 'ND');
  }
}
