import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-backdrop-ribbon-modal',
  templateUrl: './backdrop-ribbon-modal.component.html',
  styleUrls: [ './backdrop-ribbon-modal.component.scss' ]
})
export class BackdropRibbonModalComponent {
  @Input() literals: {
    HEADER?: string,
    BUTTON1?: string,
    BUTTON2?: string
  } = {};
  @Input() display = false;
  @Input() button2isDisabled: boolean = null;
  @Output() hideModal: EventEmitter<any> = new EventEmitter();
  @Output() button1Clicked: EventEmitter<any> = new EventEmitter();
  @Output() button2Clicked: EventEmitter<any> = new EventEmitter();
  draggable = false;
  mask = true;

/**
 * hide
 */
  public hide(): void {
    this.hideModal.emit();
  }
/**
 * button1
 */
  public button1(): void {
    this.button1Clicked.emit();
  }
/**
 * button2
 */
  public button2(): void {
    this.button2Clicked.emit();
  }
/**
 * disabled button 2
 */
  public disableButton2(): void {
    this.button2isDisabled = false;
  }
}
