import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ProveedoresAseguradosService } from './datos-comunes/proveedoresAsegurados.service';
import { MisConsultasService } from './datos-comunes/misConsultas.service';
import { IdentidadDigitalSOSService } from './datos-comunes/identidadDigitalSOS.service';
import { FAQSService } from './datos-comunes/fAQS.service';
import { MaestrosService } from './datos-comunes/maestros.service';

import { AseguradosService } from './asegurados/asegurados.service';
import { CredencialesService } from './identidad-digital/credenciales.service';
import { IdentidadDigitalService } from './identidad-digital/identidadDigital.service';

const aseguradosServices = [AseguradosService];
const commonsServices = [FAQSService, IdentidadDigitalSOSService, MisConsultasService, ProveedoresAseguradosService, MaestrosService];
const stlaServices = [CredencialesService, IdentidadDigitalService];

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [...aseguradosServices, ...commonsServices, ...stlaServices]
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
