/**
 * iSOS Datos Comunes
 * La API de datos comunes iSOS proporciona a los desarrolladores un conjunto de operaciones comunes a los asegurados y proveedores
 *
 * OpenAPI spec version: 1.3
 * Contact: apis@santalucia.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Adjunto } from '../../../models/api/datos-comunes/Adjunto';
import { Adjuntos } from '../../../models/api/datos-comunes/Adjuntos';
import { DetallesSalida } from '../../../models/api/datos-comunes/DetallesSalida';
import { DevolverAvances } from '../../../models/api/datos-comunes/DevolverAvances';
import { DevolverMensaje } from '../../../models/api/datos-comunes/DevolverMensaje';
import { DevolverNotas } from '../../../models/api/datos-comunes/DevolverNotas';
import { EncargoActualizarEntrada } from '../../../models/api/datos-comunes/EncargoActualizarEntrada';
import { IdentidadesSalida } from '../../../models/api/datos-comunes/IdentidadesSalida';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ProveedoresAseguradosService {
  protected basePath = '/isos/datos-comunes';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath + '';
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param - consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Actualiza el certificado de un encargo.
   *
   * @param - identificador Identificador a crear.
   * @param - datosActualizar Consentimientos a modificar
   * @param - canal Canal para recibir el archivo.(PP(PortalProveedor),PA(PortalAsegurado))
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public proveedoresAseguradosActualizarEncargo(
    identificador: string,
    datosActualizar: EncargoActualizarEntrada,
    canal?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DevolverAvances>;
  public proveedoresAseguradosActualizarEncargo(
    identificador: string,
    datosActualizar: EncargoActualizarEntrada,
    canal?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DevolverAvances>>;
  public proveedoresAseguradosActualizarEncargo(
    identificador: string,
    datosActualizar: EncargoActualizarEntrada,
    canal?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DevolverAvances>>;
  public proveedoresAseguradosActualizarEncargo(
    identificador: string,
    datosActualizar: EncargoActualizarEntrada,
    canal?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (identificador === null || identificador === undefined) {
      throw new Error('Required parameter identificador was null or undefined when calling proveedoresAseguradosActualizarEncargo.');
    }

    if (datosActualizar === null || datosActualizar === undefined) {
      throw new Error('Required parameter datosActualizar was null or undefined when calling proveedoresAseguradosActualizarEncargo.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (canal !== undefined && canal !== null) {
      queryParameters = queryParameters.set('canal', canal as any);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<DevolverAvances>(`${this.basePath}/provedores-asegurados/v1/encargos/${encodeURIComponent(String(identificador))}`, datosActualizar, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress
    });
  }

  /**
   * Adjunta un fichero a una referencia, caso,etc...
   *
   * @param - identificador Identificadorde la referencia, caso, etc
   * @param - canal Canal desde el que se manda el archivo.(PP(PortalProveedores),PA(PortalAsegurados))
   * @param - uploadedFile Fichero a insertar
   * @param - tipoEncargo Tipo de encargo a procesar
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public proveedoresAseguradosAdjuntarDocumentacion(
    identificador: string,
    canal: string,
    uploadedFile: Blob,
    tipoEncargo?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DevolverMensaje>;
  public proveedoresAseguradosAdjuntarDocumentacion(
    identificador: string,
    canal: string,
    uploadedFile: Blob,
    tipoEncargo?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DevolverMensaje>>;
  public proveedoresAseguradosAdjuntarDocumentacion(
    identificador: string,
    canal: string,
    uploadedFile: Blob,
    tipoEncargo?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DevolverMensaje>>;
  public proveedoresAseguradosAdjuntarDocumentacion(
    identificador: string,
    canal: string,
    uploadedFile: Blob,
    tipoEncargo?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (identificador === null || identificador === undefined) {
      throw new Error('Required parameter identificador was null or undefined when calling proveedoresAseguradosAdjuntarDocumentacion.');
    }

    if (canal === null || canal === undefined) {
      throw new Error('Required parameter canal was null or undefined when calling proveedoresAseguradosAdjuntarDocumentacion.');
    }

    if (uploadedFile === null || uploadedFile === undefined) {
      throw new Error('Required parameter uploadedFile was null or undefined when calling proveedoresAseguradosAdjuntarDocumentacion.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (canal !== undefined && canal !== null) {
      queryParameters = queryParameters.set('canal', canal as any);
    }
    if (tipoEncargo !== undefined && tipoEncargo !== null) {
      queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'multipart/form-data', 'application/xml', 'text/xml'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void | HttpParams };
    let useForm = false;
    const convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (uploadedFile !== undefined) {
      formParams = formParams.append('uploadedFile', uploadedFile as any) || formParams;
    }

    return this.httpClient.post<DevolverMensaje>(
      `${this.basePath}/provedores-asegurados/v1/documentacion/${encodeURIComponent(String(identificador))}`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Consulta el detalle de un encargo.
   *
   * @param - identificador Identificador del Encargo
   * @param - idProveedor Identificador del proveedor
   * @param - canal Canal para recibir el archivo.(PP(PortalProveedor),PA(PortalAsegurado))
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public proveedoresAseguradosConsultarDetalle(identificador: string, idProveedor: string, canal?: string, observe?: 'body', reportProgress?: boolean): Observable<DetallesSalida>;
  public proveedoresAseguradosConsultarDetalle(identificador: string, idProveedor: string, canal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DetallesSalida>>;
  public proveedoresAseguradosConsultarDetalle(identificador: string, idProveedor: string, canal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DetallesSalida>>;
  public proveedoresAseguradosConsultarDetalle(identificador: string, idProveedor: string, canal?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (identificador === null || identificador === undefined) {
      throw new Error('Required parameter identificador was null or undefined when calling proveedoresAseguradosConsultarDetalle.');
    }

    if (idProveedor === null || idProveedor === undefined) {
      throw new Error('Required parameter idProveedor was null or undefined when calling proveedoresAseguradosConsultarDetalle.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idProveedor !== undefined && idProveedor !== null) {
      queryParameters = queryParameters.set('idProveedor', idProveedor as any);
    }
    if (canal !== undefined && canal !== null) {
      queryParameters = queryParameters.set('canal', canal as any);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<DetallesSalida>(`${this.basePath}/provedores-asegurados/v1/encargos/${encodeURIComponent(String(identificador))}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress
    });
  }

  /**
   * Devuelve un texto desencriptado.
   *
   * @param - texto Texto a desencriptar
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public proveedoresAseguradosDesencriptar(texto: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public proveedoresAseguradosDesencriptar(texto: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public proveedoresAseguradosDesencriptar(texto: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public proveedoresAseguradosDesencriptar(texto: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (texto === null || texto === undefined) {
      throw new Error('Required parameter texto was null or undefined when calling proveedoresAseguradosDesencriptar.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (texto !== undefined && texto !== null) {
      queryParameters = queryParameters.set('texto', texto as any);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<string>(`${this.basePath}/provedores-asegurados/v1/desencriptar`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress
    });
  }

  /**
   * Elimina un adjunto de un Caso, Referencia, etc...
   *
   * @param - identificador Identificador de la referencia, caso, etc
   * @param - nombreFichero Nombre del fichero a eliminar.
   * @param - canal Canal desde el que se manda el archivo.(PP(PortalProveedores),PA(PortalAsegurados))
   * @param - tipoEncargo Tipo de encargo a procesar
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public proveedoresAseguradosEliminarFicherosDocumentacion(
    identificador: string,
    nombreFichero: string,
    canal: string,
    tipoEncargo?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DevolverMensaje>;
  public proveedoresAseguradosEliminarFicherosDocumentacion(
    identificador: string,
    nombreFichero: string,
    canal: string,
    tipoEncargo?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DevolverMensaje>>;
  public proveedoresAseguradosEliminarFicherosDocumentacion(
    identificador: string,
    nombreFichero: string,
    canal: string,
    tipoEncargo?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DevolverMensaje>>;
  public proveedoresAseguradosEliminarFicherosDocumentacion(
    identificador: string,
    nombreFichero: string,
    canal: string,
    tipoEncargo?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (identificador === null || identificador === undefined) {
      throw new Error('Required parameter identificador was null or undefined when calling proveedoresAseguradosEliminarFicherosDocumentacion.');
    }

    if (nombreFichero === null || nombreFichero === undefined) {
      throw new Error('Required parameter nombreFichero was null or undefined when calling proveedoresAseguradosEliminarFicherosDocumentacion.');
    }

    if (canal === null || canal === undefined) {
      throw new Error('Required parameter canal was null or undefined when calling proveedoresAseguradosEliminarFicherosDocumentacion.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (nombreFichero !== undefined && nombreFichero !== null) {
      queryParameters = queryParameters.set('nombreFichero', nombreFichero as any);
    }
    if (canal !== undefined && canal !== null) {
      queryParameters = queryParameters.set('canal', canal as any);
    }
    if (tipoEncargo !== undefined && tipoEncargo !== null) {
      queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<DevolverMensaje>(`${this.basePath}/provedores-asegurados/v1/documentacion/${encodeURIComponent(String(identificador))}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress
    });
  }

  /**
   * Elimina una nota en un caso.
   *
   * @param - identificador Identificador del caso, referencia de la nota
   * @param - idNota Identificador de la nota.
   * @param - idCasoPadre Identificador del caso padre.
   * @param - canal Canal para recibir el archivo.(PP(PortalProveedor),PA(PortalAsegurado))
   * @param - tipoEncargo Tipo de encargo a procesar
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public proveedoresAseguradosEliminarNota(
    identificador: string,
    idNota: string,
    idCasoPadre: string,
    canal?: string,
    tipoEncargo?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DevolverMensaje>;
  public proveedoresAseguradosEliminarNota(
    identificador: string,
    idNota: string,
    idCasoPadre: string,
    canal?: string,
    tipoEncargo?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DevolverMensaje>>;
  public proveedoresAseguradosEliminarNota(
    identificador: string,
    idNota: string,
    idCasoPadre: string,
    canal?: string,
    tipoEncargo?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DevolverMensaje>>;
  public proveedoresAseguradosEliminarNota(
    identificador: string,
    idNota: string,
    idCasoPadre: string,
    canal?: string,
    tipoEncargo?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (identificador === null || identificador === undefined) {
      throw new Error('Required parameter identificador was null or undefined when calling proveedoresAseguradosEliminarNota.');
    }

    if (idNota === null || idNota === undefined) {
      throw new Error('Required parameter idNota was null or undefined when calling proveedoresAseguradosEliminarNota.');
    }

    if (idCasoPadre === null || idCasoPadre === undefined) {
      throw new Error('Required parameter idCasoPadre was null or undefined when calling proveedoresAseguradosEliminarNota.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idNota !== undefined && idNota !== null) {
      queryParameters = queryParameters.set('idNota', idNota as any);
    }
    if (idCasoPadre !== undefined && idCasoPadre !== null) {
      queryParameters = queryParameters.set('idCasoPadre', idCasoPadre as any);
    }
    if (canal !== undefined && canal !== null) {
      queryParameters = queryParameters.set('canal', canal as any);
    }
    if (tipoEncargo !== undefined && tipoEncargo !== null) {
      queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<DevolverMensaje>(`${this.basePath}/provedores-asegurados/v1/notas/${encodeURIComponent(String(identificador))}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress
    });
  }

  /**
   * Devuelve el link para poder hacer login.
   *
   * @param - identificador Identificador del caso, referencia, ect
   * @param - canal Canal
   * @param - empresa Identificador de la empresa.
   * @param - email Correo electrónico.
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public proveedoresAseguradosGenerarLink(identificador: string, canal: 'PP' | 'PA', empresa?: string, email?: string, observe?: 'body', reportProgress?: boolean): Observable<IdentidadesSalida>;
  public proveedoresAseguradosGenerarLink(
    identificador: string,
    canal: 'PP' | 'PA',
    empresa?: string,
    email?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<IdentidadesSalida>>;
  public proveedoresAseguradosGenerarLink(
    identificador: string,
    canal: 'PP' | 'PA',
    empresa?: string,
    email?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<IdentidadesSalida>>;
  public proveedoresAseguradosGenerarLink(identificador: string, canal: 'PP' | 'PA', empresa?: string, email?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (identificador === null || identificador === undefined) {
      throw new Error('Required parameter identificador was null or undefined when calling proveedoresAseguradosGenerarLink.');
    }

    if (canal === null || canal === undefined) {
      throw new Error('Required parameter canal was null or undefined when calling proveedoresAseguradosGenerarLink.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (identificador !== undefined && identificador !== null) {
      queryParameters = queryParameters.set('identificador', identificador as any);
    }
    if (canal !== undefined && canal !== null) {
      queryParameters = queryParameters.set('canal', canal as any);
    }
    if (empresa !== undefined && empresa !== null) {
      queryParameters = queryParameters.set('empresa', empresa as any);
    }
    if (email !== undefined && email !== null) {
      queryParameters = queryParameters.set('email', email as any);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<IdentidadesSalida>(`${this.basePath}/provedores-asegurados/v1/link`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress
    });
  }

  /**
   * Guarda un justifcante de entrega
   *
   * @param - identificador Identificador de la referencia a guardar
   * @param - uploadedFile Fichero a insertar
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public proveedoresAseguradosGuardarJustificanteEntrega(identificador: string, uploadedFile: Blob, observe?: 'body', reportProgress?: boolean): Observable<DevolverMensaje>;
  public proveedoresAseguradosGuardarJustificanteEntrega(identificador: string, uploadedFile: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverMensaje>>;
  public proveedoresAseguradosGuardarJustificanteEntrega(identificador: string, uploadedFile: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverMensaje>>;
  public proveedoresAseguradosGuardarJustificanteEntrega(identificador: string, uploadedFile: Blob, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (identificador === null || identificador === undefined) {
      throw new Error('Required parameter identificador was null or undefined when calling proveedoresAseguradosGuardarJustificanteEntrega.');
    }

    if (uploadedFile === null || uploadedFile === undefined) {
      throw new Error('Required parameter uploadedFile was null or undefined when calling proveedoresAseguradosGuardarJustificanteEntrega.');
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'multipart/form-data', 'application/xml', 'text/xml'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void | HttpParams };
    let useForm = false;
    const convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (uploadedFile !== undefined) {
      formParams = formParams.append('uploadedFile', uploadedFile as any) || formParams;
    }

    return this.httpClient.post<DevolverMensaje>(
      `${this.basePath}/provedores-asegurados/v1/justificante-entrega/${encodeURIComponent(String(identificador))}`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Inserta una nota nueva en un caso.
   *
   * @param - identificador Identificador donde insertar la nota
   * @param - usuario Usuario que guarda la nota
   * @param - comentario Mensaje a guardar.
   * @param - idCasoPadre Identificador del caso padre.
   * @param - canal Canal para recibir el archivo.(PP(PortalProveedor),PA(PortalAsegurado))
   * @param - tipoEncargo Tipo de encargo a procesar
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public proveedoresAseguradosInsertarNota(
    identificador: string,
    usuario: string,
    comentario: string,
    idCasoPadre: string,
    canal?: string,
    tipoEncargo?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DevolverMensaje>;
  // tslint:disable-next-line:max-line-length
  public proveedoresAseguradosInsertarNota(
    identificador: string,
    usuario: string,
    comentario: string,
    idCasoPadre: string,
    canal?: string,
    tipoEncargo?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DevolverMensaje>>;
  // tslint:disable-next-line:max-line-length
  public proveedoresAseguradosInsertarNota(
    identificador: string,
    usuario: string,
    comentario: string,
    idCasoPadre: string,
    canal?: string,
    tipoEncargo?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DevolverMensaje>>;
  public proveedoresAseguradosInsertarNota(
    identificador: string,
    usuario: string,
    comentario: string,
    idCasoPadre: string,
    canal?: string,
    tipoEncargo?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (identificador === null || identificador === undefined) {
      throw new Error('Required parameter identificador was null or undefined when calling proveedoresAseguradosInsertarNota.');
    }

    if (usuario === null || usuario === undefined) {
      throw new Error('Required parameter usuario was null or undefined when calling proveedoresAseguradosInsertarNota.');
    }

    if (comentario === null || comentario === undefined) {
      throw new Error('Required parameter comentario was null or undefined when calling proveedoresAseguradosInsertarNota.');
    }

    if (idCasoPadre === null || idCasoPadre === undefined) {
      throw new Error('Required parameter idCasoPadre was null or undefined when calling proveedoresAseguradosInsertarNota.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (usuario !== undefined && usuario !== null) {
      queryParameters = queryParameters.set('usuario', usuario as any);
    }
    if (comentario !== undefined && comentario !== null) {
      queryParameters = queryParameters.set('comentario', comentario as any);
    }
    if (idCasoPadre !== undefined && idCasoPadre !== null) {
      queryParameters = queryParameters.set('idCasoPadre', idCasoPadre as any);
    }
    if (canal !== undefined && canal !== null) {
      queryParameters = queryParameters.set('canal', canal as any);
    }
    if (tipoEncargo !== undefined && tipoEncargo !== null) {
      queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<DevolverMensaje>(`${this.basePath}/provedores-asegurados/v1/notas/${encodeURIComponent(String(identificador))}`, null, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress
    });
  }

  /**
   * Devuelve el contenido de un fichero
   *
   * @param - identificador Identificador de la referencia, caso, etc
   * @param - nombreFichero Nombre del fichero a recuperar.
   * @param - canal Canal para recibir el archivo.(PP(PortalProveedor),PA(PortalAsegurado)).
   * @param - tipoEncargo Tipo de encargo a procesar
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public proveedoresAseguradosRecuperarFicherosDocumentacion(
    identificador: string,
    nombreFichero: string,
    canal: string,
    tipoEncargo?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Adjunto>;
  public proveedoresAseguradosRecuperarFicherosDocumentacion(
    identificador: string,
    nombreFichero: string,
    canal: string,
    tipoEncargo?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Adjunto>>;
  public proveedoresAseguradosRecuperarFicherosDocumentacion(
    identificador: string,
    nombreFichero: string,
    canal: string,
    tipoEncargo?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Adjunto>>;
  public proveedoresAseguradosRecuperarFicherosDocumentacion(
    identificador: string,
    nombreFichero: string,
    canal: string,
    tipoEncargo?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (identificador === null || identificador === undefined) {
      throw new Error('Required parameter identificador was null or undefined when calling proveedoresAseguradosRecuperarFicherosDocumentacion.');
    }

    if (nombreFichero === null || nombreFichero === undefined) {
      throw new Error('Required parameter nombreFichero was null or undefined when calling proveedoresAseguradosRecuperarFicherosDocumentacion.');
    }

    if (canal === null || canal === undefined) {
      throw new Error('Required parameter canal was null or undefined when calling proveedoresAseguradosRecuperarFicherosDocumentacion.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (nombreFichero !== undefined && nombreFichero !== null) {
      queryParameters = queryParameters.set('nombreFichero', nombreFichero as any);
    }
    if (canal !== undefined && canal !== null) {
      queryParameters = queryParameters.set('canal', canal as any);
    }
    if (tipoEncargo !== undefined && tipoEncargo !== null) {
      queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Adjunto>(`${this.basePath}/provedores-asegurados/v1/documentacion/${encodeURIComponent(String(identificador))}/fichero`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress
    });
  }

  /**
   * Devuelve un listado de ficheros
   *
   * @param - identificador Identificador de la referencia, caso, etc
   * @param - canal Canal para recibir el archivo.(PP(PortalProveedor),PA(PortalAsegurado))
   * @param - tipoEncargo Tipo de encargo a procesar
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public proveedoresAseguradosRecuperarListaFicherosDocumentacion(identificador: string, canal: string, tipoEncargo?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Adjuntos>>;
  public proveedoresAseguradosRecuperarListaFicherosDocumentacion(
    identificador: string,
    canal: string,
    tipoEncargo?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Adjuntos>>>;
  public proveedoresAseguradosRecuperarListaFicherosDocumentacion(
    identificador: string,
    canal: string,
    tipoEncargo?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Adjuntos>>>;
  public proveedoresAseguradosRecuperarListaFicherosDocumentacion(identificador: string, canal: string, tipoEncargo?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (identificador === null || identificador === undefined) {
      throw new Error('Required parameter identificador was null or undefined when calling proveedoresAseguradosRecuperarListaFicherosDocumentacion.');
    }

    if (canal === null || canal === undefined) {
      throw new Error('Required parameter canal was null or undefined when calling proveedoresAseguradosRecuperarListaFicherosDocumentacion.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (canal !== undefined && canal !== null) {
      queryParameters = queryParameters.set('canal', canal as any);
    }
    if (tipoEncargo !== undefined && tipoEncargo !== null) {
      queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Adjuntos>>(`${this.basePath}/provedores-asegurados/v1/documentacion/${encodeURIComponent(String(identificador))}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress
    });
  }

  /**
   * Listado de las notas del identificador.
   *
   * @param - identificador Identificador del caso, referencia, ect
   * @param - canal Canal para recibir el archivo.(PP(PortalProveedor),PA(PortalAsegurado))
   * @param - tipoEncargo Tipo de encargo a procesar
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public proveedoresAseguradosRecuperarNota(identificador: string, canal?: string, tipoEncargo?: string, observe?: 'body', reportProgress?: boolean): Observable<DevolverNotas>;
  public proveedoresAseguradosRecuperarNota(identificador: string, canal?: string, tipoEncargo?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverNotas>>;
  public proveedoresAseguradosRecuperarNota(identificador: string, canal?: string, tipoEncargo?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverNotas>>;
  public proveedoresAseguradosRecuperarNota(identificador: string, canal?: string, tipoEncargo?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (identificador === null || identificador === undefined) {
      throw new Error('Required parameter identificador was null or undefined when calling proveedoresAseguradosRecuperarNota.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (identificador !== undefined && identificador !== null) {
      queryParameters = queryParameters.set('identificador', identificador as any);
    }
    if (canal !== undefined && canal !== null) {
      queryParameters = queryParameters.set('canal', canal as any);
    }
    if (tipoEncargo !== undefined && tipoEncargo !== null) {
      queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<DevolverNotas>(`${this.basePath}/provedores-asegurados/v1/notas`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress
    });
  }
}
