import { Renderer2 } from '@angular/core';
import { Numbers } from '../../../utils/constants';
/**
 * @param text text
 * @param renderer renderer
 * @param priority priority
 */
export const speak: (text: string, renderer: Renderer2, priority?: string) => void =
(text: string, renderer: Renderer2,  priority?: string): void => {
  const el: HTMLDivElement = renderer.createElement('div');
  const id = 'speak-' + Date.now();
  renderer.setAttribute(el, 'id', id);
  renderer.setAttribute(el, 'aria-live', priority || 'polite');
  renderer.addClass(el, 'sr-only');
  renderer.appendChild(document.body, el);

  window.setTimeout(() => {
    document.getElementById(id).innerHTML = text;
  }, Numbers.number100);

  window.setTimeout(() => {
    renderer.removeChild(document.body, document.getElementById(id));
  }, Numbers.number1000);
};
