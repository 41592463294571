import { Injectable } from '@angular/core';

@Injectable()
export class UserService {

  private TEST2: string;
/**
 * @returns return
 */
  public test(): string {
    return 'test';
  }
/**
 * test2
 */
  set test2Value(value: string) {
    this.TEST2 = value;
  }
/**
 * test3
 */
  get test2(): string {
    return this.TEST2;
  }
}
