/**
 * AppConfig
 */
export interface AppConfig {
  loginUrl: string;
  environment: string;
  configKeycloak: {
    url: string;
    realm: string;
    clientId: string;
  };
}
/**
 * AccesTokenResponse
 */
export interface AccesTokenResponse {
  access_token: string;
  expires_in: number;
  'not-before-policy': number;
  refresh_expires_in: number;
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: string;
}
/**
 * TokenUrl
 */
export enum TokenUrl {
  CommonData = '/generate-token-datos-comunes',
  IdentDig = '/generate-token-identidad-digital'
}
