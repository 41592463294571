import { Component } from '@angular/core';

@Component({
  selector: 'app-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: [ './basic-modal.component.scss' ]
})
export class BasicModalComponent {
  public basicModalShown = false;
/**
 * constructor
 */
  constructor() {
  }
/**
 * show basic modal
 */
  public showBasicModal(): void {
    this.basicModalShown = !this.basicModalShown;
  }
}
