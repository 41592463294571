import { Component, Input, OnInit } from '@angular/core';
/**
 * component
 */
@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
/**
 * ConfirmationModalComponent
 */
export class ConfirmationModalComponent {
  @Input() text: string;
}
