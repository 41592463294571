import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StatusModal, StatusModalService } from './status-modal.service';
import { Numbers } from 'projects/portal-asegurados-front/src/app/utils/constants';
import { StatusModalId } from '../../../utils/utils';

@Component({
  selector: 'app-status-modal',
  templateUrl: './status-modal.component.html',
  styleUrls: ['./status-modal.component.scss']
})
export class StatusModalComponent implements OnInit {
  public observable: Observable<any> = this.statusModalServ.statusModalState;

  public statusModalArray: any[] = [];

  public selfDisplay: boolean;
  public isSuccess: boolean;
  public translateText: string;
  public variableText: string;
/**
 * @param statusModalServ statusModalServ
 */
  constructor(private statusModalServ: StatusModalService) {
    this.statusModalServ = statusModalServ;
  }
/**
 * on init
 */
  ngOnInit(): void {
    this.observable.subscribe((res: StatusModal) => {
      if (res) {
        if (this.statusModalArray.length === Numbers.number2) {
          this.statusModalArray.shift();
        }
        this.statusModalArray.push(this.createNewStatusModal(res, Math.floor(Math.random() * Numbers.number1000)));
      } else {
        this.onTurnOff();
      }
    });
  }
/**
 * @param statusModal status modal
 * @param id id
 * @returns return
 */
  private createNewStatusModal(statusModal: StatusModal, id: number): StatusModalId {
    return {
      id,
      selfDisplay: statusModal.display,
      isSuccess: statusModal.isSuccess,
      translateText: statusModal.displayedText,
      variableText: statusModal.variableText
    };
  }
/**
 * on turn off
 */
  public onTurnOff(): void {
    this.statusModalArray = [];
    // this.statusModalServ.turnOffStatusDisplay();
  }
/**
 * @param id id
 */
  public onTurnOffSpecificModal(id: number): void {
    this.statusModalArray = this.statusModalArray.filter((statusModal) => statusModal.id !== id);
  }

  /**
   * trackByFn
   * @param item item
   * @returns item id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
