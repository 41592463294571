import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { concat, Observable } from 'rxjs';
import { IdentidadActualizar } from '../../core/models/api/datos-comunes/IdentidadActualizar';
import { Identidades } from '../../core/models/api/datos-comunes/Identidades';
import { IdentidadDigitalAlta } from '../../core/models/api/identidad-digital/IdentidadDigitalAlta';
import { IdentidadDigitalConsulta } from '../../core/models/api/identidad-digital/IdentidadDigitalConsulta';
import { LogoPath } from '../../core/models/local/logoPath.model';
import { RibbonModal } from '../../core/models/local/ribbon-modal.model';
import { BootService } from '../../core/providers/local/boot/boot.service';
import { ManageRequestSosService } from '../../core/providers/local/manage-request-SOS/manage-request-sos.service';
import { ManageRequestServiceExtended } from '../../core/providers/local/manage-request-SOS/manage-request-sos.service.utils-extended';
import { StatusModalService } from '../../shared/components/modals/status-modal/status-modal.service';
import { Utils } from '../../shared/utils/utils';
import { validCIFValidator, validNIEValidator, validNIFValidator } from '../../shared/validators/document-validator';
import { RegistrationForm, REGISTRATION_DATA_DOCUMENT_TYPE_OPTIONS, REGISTRATION_DATA_MAP_USER_DATA } from './data/registration-data';
import { Numbers } from 'projects/portal-asegurados-front/src/app/utils/constants';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {
  //#region STATE
  public loginUrl: string;

  public documentTypeOptions: any = REGISTRATION_DATA_DOCUMENT_TYPE_OPTIONS;
  public mapUserData: any = REGISTRATION_DATA_MAP_USER_DATA;
  public userId: string;

  private userIdForRequest: string;

  public companyLogo: string;

  // REGISTRATION FORM
  public registrationForm: FormGroup = new FormGroup({
    user: new FormControl({ value: '', disabled: true }), // USUARIO. SÓLO LECTURA
    documentType: new FormControl('', Validators.required), // OBLIGATORIO
    idNumber: new FormControl('', Validators.required),
    name: new FormControl('', [Validators.required, Validators.maxLength(Numbers.number50)]), // NOMBRE O RAZÓN SOCIAL
    surname1: new FormControl(''), // OBLIGATORIO SI ES PERSONA FÍSICA
    surname2: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.pattern(Utils.variables.emailRegex)]), // OBLIGATORIO
    password: new FormControl('', [Validators.required, Validators.maxLength(Numbers.number50)]),
    checkPass: new FormControl('', [Validators.required, Validators.maxLength(Numbers.number50)]),
    userType: new FormControl(''),
    language: new FormControl(''),
    company: new FormControl(''),
    activeAcount: new FormControl(''),
    changePass: new FormControl(''),
    identityType: new FormControl(''),
    state: new FormControl('')
  });

  public displayPassError: any = { display: false, message: '' };

  // ** Ribbon modal status
  public ribbonModal: RibbonModal = { display: false, type: null };
  public confirmationModalText: string;
  // ** Habilitar botón enviar
  public enableButton = true;
  public encrypt = false;
  public identity = false;
  public dataUser = false;
  public textErr = false;
  //#endregion
/**
 * @param title title
 * @param manageReqSOS manageReqSOS
 * @param manageReqSOSExtended manageReqSOSExtended
 * @param statusModal statusModal
 * @param bootService bootService
 */
  constructor(private title: Title, private manageReqSOS: ManageRequestSosService, private manageReqSOSExtended: ManageRequestServiceExtended,
              private statusModal: StatusModalService, private bootService: BootService) {
    this.title = title;
    this.manageReqSOS = manageReqSOS;
    this.manageReqSOSExtended = manageReqSOSExtended;
    this.statusModal = statusModal;
    this.bootService = bootService;
    this.title.setTitle('Registration');
  }
/**
 * on init
 */
  ngOnInit(): void {
    this.loginUrl = this.bootService.getAppConfig().loginUrl;
    this.checkUserData(this.getHashFromUrl(window.location.href));
  }
/**
 * on destroy
 */
  ngOnDestroy(): void {
    this.statusModal.turnOffStatusDisplay();
  }
/**
 * @param hash hash
 */
  private checkUserData(hash: string): void {
    this.manageReqSOSExtended.provAsegDevolverTextoDesencriptado$(hash).subscribe(
      (desencriptedHash: string) => {
        // Get UserID and company from HASH
        const rawParameters: string[][] = desencriptedHash.split('&').map((m) => m.split('='));
        const rawUserId: string[] = rawParameters.find((f) => f.indexOf('identificador') !== Numbers.numberN1);
        const rawCompany: string[] = rawParameters.find((f) => f.indexOf('empresa') !== Numbers.numberN1);
        const userId: string = rawUserId && rawUserId.length === Numbers.number2 ? rawUserId[Numbers.number1] : null;
        const company: string = rawCompany && rawCompany.length === Numbers.number2 ? rawCompany[Numbers.number1] : null;

        this.userIdForRequest = userId;

        this.changeCompanyLogo(company);

        Utils.lookFeel.changeLookAndFeel(company);
        this.encrypt = true;
        this.getInsuredData(userId);
      },
      (error: HttpErrorResponse) => {
        this.encrypt = false;
        this.EnableButton();
      });
  }
/**
 * @param userId user id
 */
  private getInsuredData(userId: string): void {
    // Check if user exist on LDAP ('Tipo identidad' it's always 'A')
    this.manageReqSOSExtended.identDigSLConsultarIdentidadDigital$(userId, 'A').subscribe(
      // If user exists on LDAP, navigate to LOGIN
      (userDataSL: IdentidadDigitalConsulta) => {
        window.location.href = this.loginUrl;
      },

      // If user is not yet on LDAP, get data from ISOS and fill the form
      (errUserDataSL: HttpErrorResponse) => {
        if (errUserDataSL.status !== Numbers.number404) {
          this.identity = true;
        } else {
          this.identity = false;
          this.EnableButton();
        }
        const hash: string = this.getHashFromUrl(window.location.href);

        // Get user data from ISOS...
        this.manageReqSOS.identDigSOSDevolverDatosUsuario$(hash).subscribe((userDataSOS: Identidades) => {
          if (!userDataSOS) {
            this.statusModal.displayErrorMessage('USER-NOT-FOUND', true);
            this.dataUser = false;
            this.EnableButton();
            return;
          } else {
            this.dataUser = true;
          }

          // ** With documentType, set validators (regex, maxlength, require)
          this.changeIdNumberValidator(userDataSOS.tipoDocumento);

          this.setInitialFormValues(userDataSOS);
          this.setUser(this.userIdForRequest);
          this.EnableButton();
        });
      }
    );
  }
/**
 * @param url url
 * @returns retunr
 */
  private getHashFromUrl(url: string): string {
    const hash = `${url.split('a=')[1]}`;

    return decodeURIComponent(hash);
  }
/**
 * @param documentTypeSelected document type selected
 */
  setFormState(documentTypeSelected: string): void {
    this.changeIdNumberValidator(documentTypeSelected);
    this.manageFormDependsOnDocumentType(documentTypeSelected);
  }
/**
 * @param company campanny
 * @returns retunr
 */
  changeCompanyLogo(company: string): void {
    if (company) {
      const selectedCompany: string = company.toLowerCase();
      if (selectedCompany === 'santander') {
        this.companyLogo = LogoPath.Santander;
        return;
      }
    }

    this.companyLogo = LogoPath.ISOS;
  }
/**
 * Fill form with user data from param userData
 * @param userData user data
 */
  private setInitialFormValues(userData: Identidades): void {
    for (const data in userData) {
      if (userData.hasOwnProperty(data) && this.registrationForm.get(this.mapUserData[data]) ) {
        const formElement: any = this.registrationForm.get(this.mapUserData[data]);
        formElement.setValue(userData[data]);
      }
    }

    this.manageFormDependsOnDocumentType(userData.tipoDocumento);
  }
/**
 * @param value value
 */
  private setUser(value: string): void {
    this.registrationForm.get('user').setValue(value);
    this.userId = value;
  }
/**
 * on cancel
 */
  onCancel(): void {
    this.ribbonModal = { display: true, type: 'MODAL.CANCEL-REGISTRATION-MODAL' };
    this.confirmationModalText = 'MODAL.CANCEL-REGISTRATION-MODAL.TEXT';
  }
/**
 * @param form form
 */
  onSubmit(form: any): void {
    const formValue: any = this.registrationForm.value;
    this.registrationForm.markAllAsTouched();

    if (form.valid && this.isValidPass()) {
      concat(this.createUserOnLDAP$(formValue, this.userIdForRequest), this.updateUserOnISOS$(formValue, this.userIdForRequest)).subscribe((res: any) => {
        this.registrationForm.reset();

        // ** If user has been created, redirect to Login
        setTimeout(() => {
          window.location.href = this.loginUrl;
        }, Numbers.number3000);
      });
    }
  }
/**
 * @param userForm user form
 * @param idForRequest id request
 * @returns retunr
 */
  private createUserOnLDAP$(userForm: RegistrationForm, idForRequest: string): Observable<any> {
    const datosIdentidadDigital: IdentidadDigitalAlta = {
      identidad: userForm.idNumber,
      tipoIdentidad: 'A', // TODO: ¿Qué tipo de indentidad ponemos?
      nombre: userForm.name,
      // TODO: Cambiar valor si no es empresa
      primerApellido: userForm.surname1 || 'empresa',
      segundoApellido: userForm.surname2 || 'empresa',
      idioma: 'E', // TODO: Ojo con el tipado del idioma
      telefono: '919199191',
      correo: userForm.email,
      credenciales: {
        clave: userForm.password
      },
      atributos: {
        'isos.tipoDocumentoIdentidad': userForm.documentType,
        'isos.empresa': userForm.company,
        'isos.usuario': userForm.email,
        'isos.tipoUsuario': userForm.userType,
        'isos.idUsuario': idForRequest,
        'isos.cuentaActiva': userForm.activeAcount ? 'TRUE' : 'FALSE',
        'isos.cambioPassword': userForm.changePass ? 'TRUE' : 'FALSE'
      }
    };

    return this.manageReqSOSExtended.identDigSLCrearIdentidadDigital$(datosIdentidadDigital);
  }
/**
 * @param userForm user form
 * @param id id
 * @returns retunr
 */
  private updateUserOnISOS$(userForm: RegistrationForm, id: string): Observable<any> {
    const identificacion: string = id;
    const identidad: IdentidadActualizar = {
      nombre: userForm.name,
      apellido1: userForm.surname1 || '',
      apellido2: userForm.surname2 || '',
      tipoDocumento: userForm.documentType,
      email: userForm.email,
      tipoUsuario: userForm.userType,
      idioma: userForm.language,
      empresa: userForm.company,
      cuentaActiva: true,
      cambioContrasena: true
    };

    return this.manageReqSOS.identDigSOSActualizarUsuario$(identificacion, identidad);
  }
/**
 * @param documentType docuemnt type
 */
  changeIdNumberValidator(documentType: string): void {
    const validatorSelected: RegExp = Utils.validatorGen.getValidatorFromType(documentType);
    const idNumberInput: any = this.registrationForm.get('idNumber');

    if (documentType.toLowerCase() === 'nif' || documentType.toLowerCase() === 'dni') {
      idNumberInput.setValidators([Validators.pattern(validatorSelected), Validators.maxLength(Numbers.number10), Validators.required, validNIFValidator]);
    } else if (documentType.toLowerCase() === 'cif') {
      idNumberInput.setValidators([Validators.pattern(validatorSelected), Validators.maxLength(Numbers.number10), Validators.required, validCIFValidator]);
    } else if (documentType.toLowerCase() === 'nie') {
      idNumberInput.setValidators([Validators.pattern(validatorSelected), Validators.maxLength(Numbers.number10), Validators.required, validNIEValidator]);
    } else {
      idNumberInput.setValidators([Validators.pattern(validatorSelected), Validators.maxLength(Numbers.number10), Validators.required]);
    }

    idNumberInput.updateValueAndValidity();
    idNumberInput.markAllAsTouched();
  }
/**
 * @param typeOfDocumentType type of document
 */
  manageFormDependsOnDocumentType(typeOfDocumentType: string): void {
    const inputToModify = ['surname1', 'surname2'];
    if (typeOfDocumentType === 'CIF') {
      this.changeInputState(inputToModify, 'disable');
    } else {
      this.changeInputState(inputToModify, 'enable');
      inputToModify.forEach((input) => {
        this.registrationForm.get(input).setValidators([Validators.required, Validators.maxLength(Numbers.number50)]);
      });
    }
  }
/**
 * @param list list
 * @param state state
 */
  private changeInputState(list: Array<string>, state: string): void {
    if (state === 'disable') {
      list.forEach((elem) => this.registrationForm.get(elem).disable());
    } else {
      list.forEach((elem) => this.registrationForm.get(elem).enable());
    }
  }
/**
 * @returns retunr
 */
  private isValidPass(): boolean {
    // PASS HAS AT LEAST 8 CHARACTERS AND SPECIAL ONE
    const regex = /^(?=.*[a-zA-Z0-9])(?=.*[!@#\$%\^&\*\[\]"\';:_\-<>\., =\+\/\\]).{8,}$/;
    const password: any = this.registrationForm.get('password').value;
    const checkPass: any = this.registrationForm.get('checkPass').value;

    // MANAGE PASS ERROR MESSAGE
    if (!regex.test(password)) {
      this.displayPassError = { display: true, message: 'regex' };
    } else if (password !== checkPass) {
      this.displayPassError = { display: true, message: 'check' };
    } else {
      this.displayPassError.display = false;
    }

    return password === checkPass && regex.test(password);
  }
/**
 * hide modal
 */
  onHideRegisterModal(): void {
    this.ribbonModal.display = false;
  }
/**
 * navigate to login
 */
  onNavigateToLogin(): void {
    window.location.href = this.loginUrl;
  }

  /**
   * habilitar botón
   */
  EnableButton(): void {
    if (this.encrypt === true && this.identity === true && this.dataUser === true) {
      this.enableButton = false;
    } else {
      this.enableButton = true;
      this.textErr = true;
    }
  }
}
