/**
 * RegistrationForm
 */
export interface RegistrationForm {
  user: string;
  documentType: string;
  idNumber: string;
  name: string;
  surname1: string;
  surname2: string;
  email: string;
  password: string;
  checkPass: string;
  userType?: string;
  language?: string;
  company?: string;
  activeAcount?: boolean;
  changePass?: boolean;
  identityType?: any;
  state?: any;
}
/**
 * REGISTRATION_DATA_MAP_USER_DATA
 */
export const REGISTRATION_DATA_MAP_USER_DATA: any = {
  tipoDocumento: 'documentType',
  identificacion: 'idNumber',
  nombre: 'name',
  apellido1: 'surname1',
  apellido2: 'surname2',
  email: 'email',
  tipoUsuario: 'userType',
  idioma: 'language',
  empresa: 'company',
  cuentaActiva: 'activeAcount',
  cambioContrasena: 'changePass',
  tipoIdentidad: 'identityType',
  estado: 'state'
};
/**
 * REGISTRATION_DATA_DOCUMENT_TYPE_OPTIONS
 */
export const REGISTRATION_DATA_DOCUMENT_TYPE_OPTIONS: any = [
  { label: 'DNI', value: 'DNI' },
  { label: 'NIF', value: 'NIF' },
  { label: 'NIE', value: 'NIE' },
  { label: 'Pasaporte', value: 'Pasaporte' }
];
