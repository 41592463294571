import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './components/main/main.component';
import { LayoutComponent } from './layout.component';
import { Error404Component } from './components/error404/error404.component';
import { NavigatorIncompatibleComponent } from './components/navigator-incompatible/navigator-incompatible.component';
import { NavigationMenuComponent } from './components/header/navigation-menu/navigation-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { LogoutModalComponent } from './components/header/navigation-menu/logout-modal/logout-modal.component';
/**
 * NgModule
 */
@NgModule({
  declarations: [LayoutComponent, HeaderComponent, MainComponent, Error404Component, NavigatorIncompatibleComponent, NavigationMenuComponent, HeaderComponent, FooterComponent, LogoutModalComponent],
  imports: [SharedModule],
  exports: [HeaderComponent]
})
/**
 * LayoutModule
 */
export class LayoutModule {}
