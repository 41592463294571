// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  loginUrl: 'https://portal-asegurados-front-isosportal-front-dev.app-dev.santalucia.net/',
  test: false,
  configKeycloak: {
    url: 'https://sso-sldev.santalucia.es/auth',
    realm: 'stl-isos-asg',
    clientId: '44ec7c43-1aa2-4312-9191-c6e4580282bf'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
