import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDataInfo'
})
export class ConvertDataInfoPipe implements PipeTransform {
  /**
   * @param value value
   * @param convertValues convertValues
   * @returns return
   */
  transform(value: any, convertValues: object): string {
    let valueConverted: string;

    if (value === undefined || value === null || value === '') {
      valueConverted = '-';
    } else {
      const strValue = String(value);

      valueConverted = strValue;

      if (convertValues.hasOwnProperty(strValue.toLowerCase())) {
        valueConverted = convertValues[strValue.toLowerCase()];
      }
    }

    return valueConverted;
  }
}
