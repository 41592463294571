import { FormGroup } from '@angular/forms';
/**
 * Marca los inputs como 'touched'
 * @param form form
 */
export const markAllInputsAsTouched: (form: FormGroup) => void = (form: FormGroup): void => {
  Object.keys(form.controls).forEach((controlName: string) => {
    form.get(controlName).markAsDirty();
  });
};

/**
 * transformIntoDropdownValues
 * @param data data
 * @returns return
 */
export const transformIntoDropdownValues: (data: Array<{ Id: any; Description: any; }>) => Array<{
  value: any;
  label: any;
  }> = (data: Array<{ Id: any; Description: any }>): Array<{ value: any; label: any }> => {
  return data.reduce((acc, elem) => {
    return [...acc, { ['value']: String(elem.Id) || '', ['label']: elem.Description || '' }];
  }, []);
};
/**
 * transformLocations
 * @param property property
 * @param formDataValue form data value
 */
/* export function transformLocations(property: any, formDataValue: any): void {
  if (property.includes('poblacion')) {
    formDataValue[property] = this.locationMappedData[formDataValue[property]];
    if (formDataValue[property] === `${this.translate$.instant('CERTIFICATE-DATA.DROPDOWN.SELECT-CITY')}`) {
      formDataValue[property] = '';
    }
  }
  if (property.includes('ciudad')) {
    formDataValue[property] = this.locationOcurrencyMappedData[formDataValue[property]];
    if (formDataValue[property] === `${this.translate$.instant('CERTIFICATE-DATA.DROPDOWN.SELECT-CITY')}`) {
      formDataValue[property] = '';
    }
  }
  if (property.includes('localidad')) {
    formDataValue[property] = this.locationBornMappedData[formDataValue[property]];
    if (formDataValue[property] === `${this.translate$.instant('CERTIFICATE-DATA.DROPDOWN.SELECT-CITY')}`) {
      formDataValue[property] = '';
    }
  }
}; */

/**
 * handleFormElement
 * @param formElement form element
 * @param serverResult server result
 */
/* export const handleFormElement = function(formElement: any, serverResult: any): void {
  if (formElement.type === 'radio' && formElement.insertControlRadio) {
    // SET BASE PARAMS AND CONFIG OF RADIO BUTTONS
    this.setRadioButtonConfig(formElement, serverResult);
  }
  if (formElement.type === 'date') {
    // SET BASE PARAMS AND CONFIG OF DATE
    this.setDateSelectorConfig(formElement, serverResult);
  }
  if (formElement.type === 'dropdown' && !formElement.dropdownType) {
    // SET BASE PARAMS AND CONFIG OF DROPDOWN
    this.setBaseDropdownConfig(formElement, serverResult);
  }
  if (formElement.type === 'dropdown' && formElement.dropdownType === 'pais') {
    // SET BASE PARAMS AND CONFIG OF DROPDOWN
    this.setBaseDropdownConfig(formElement, serverResult);
  }
  if (formElement.type === 'dropdown' && formElement.dropdownType === 'province') {
    this.configProvinceDropdown(formElement, serverResult);
  }
  if (formElement.type === 'checkbox') {
    this.setCheckboxConfig(formElement, serverResult);
  }
  if (formElement.type === 'text') {
    this.formData.addControl(formElement.name, this.generateFormControl(serverResult[formElement.name], formElement.disabled || false));
  }
  this.checkLocationsDropdowns(formElement, serverResult);
  this.manageValidators(formElement);
}; */
