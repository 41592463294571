import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Utils } from 'projects/portal-asegurados-front/src/app/shared/utils/utils';
import { StatusModalService } from 'projects/portal-asegurados-front/src/app/shared/components/modals/status-modal/status-modal.service';
import { IdentidadDigitalService } from '../../api/identidad-digital/identidadDigital.service';
import { IdentidadDigitalAlta } from '../../../models/api/identidad-digital/IdentidadDigitalAlta';
import { IdentidadDigitalBusqueda } from '../../../models/api/identidad-digital/IdentidadDigitalBusqueda';
import { IdentidadDigitalModificacion, TipoIdentidadEnum } from '../../../models/api/identidad-digital/IdentidadDigitalModificacion';
import { IdentidadDigitalConsulta } from '../../../models/api/identidad-digital/IdentidadDigitalConsulta';
import { IdentidadDigitalParcheo } from '../../../models/api/identidad-digital/IdentidadDigitalParcheo';
import { ProveedoresAseguradosService } from '../../api/datos-comunes/proveedoresAsegurados.service';
import { Adjuntos } from '../../../models/api/datos-comunes/Adjuntos';
import { DevolverMensaje } from '../../../models/api/datos-comunes/DevolverMensaje';
import { Adjunto } from '../../../models/api/datos-comunes/Adjunto';
import { DevolverNotas } from '../../../models/api/datos-comunes/DevolverNotas';
import { IdentidadesSalida } from '../../../models/api/datos-comunes/IdentidadesSalida';
import { EncargoActualizarEntrada } from '../../../models/api/datos-comunes/EncargoActualizarEntrada';
import { DevolverAvances } from '../../../models/api/asegurados/DevolverAvances';
import { ResActualizarConsulta } from '../../../models/api/datos-comunes/ResActualizarConsulta';
import { ResConsultasAsegurado } from '../../../models/api/datos-comunes/ResConsultasAsegurado';
import { ResInsertarConsulta } from '../../../models/api/datos-comunes/ResInsertarConsulta';
import { ResListadoMisConsultas } from '../../../models/api/datos-comunes/ResListadoMisConsultas';
import { ResMisConsultas } from '../../../models/api/datos-comunes/ResMisConsultas';
import { MisConsultasService } from '../../api/datos-comunes/misConsultas.service';
import { InsertarConsulta } from '../../../models/api/datos-comunes/InsertarConsulta';
import { ActualizarConsulta } from '../../../models/api/datos-comunes/ActualizarConsulta';
import { CredencialesService } from '../../api/identidad-digital/credenciales.service';

@Injectable({
    providedIn: 'root'
})
export class ManageRequestServiceExtended {
/**
 * @param err error
 * @returns return
 */
  public handleBaseErrorFromServer(err: HttpErrorResponse): Observable<HttpErrorResponse> {
    Utils.error.displayServerErrorInConsole(err);

    this.statusModalServ.displayErrorMessage(err.error.mensaje);

    return throwError(err);
  }
/**
 * @param statusModalServ Status
 * @param identDigSLServ Identidad Digital
 * @param provAsegServ prov aseg
 * @param misConsultasServ mis consultas
 */
  constructor(
    private statusModalServ: StatusModalService,
    private identDigSLServ: IdentidadDigitalService,
    private provAsegServ: ProveedoresAseguradosService,
    private misConsultasServ: MisConsultasService,
    private credentServ: CredencialesService
  ) {
      this.statusModalServ = statusModalServ;
      this.identDigSLServ = identDigSLServ;
      this.provAsegServ = provAsegServ;
      this.misConsultasServ = misConsultasServ;
      this.credentServ = credentServ;
  }
    // <<<<----- IDENTIDAD DIGITAL ----->>>>>

  //#region *** IDENTIDAD DIGITAL SL ***
  /**
   * @description(ISL1) Alta identidad digital
   * @param datosIdentidadDigital datos de la identidad digital
   * @param xRequestId Id
   * @returns return
   */
  identDigSLCrearIdentidadDigital$(datosIdentidadDigital: IdentidadDigitalAlta, xRequestId?: string): Observable<any> {
    return this.identDigSLServ.postIdentidades(datosIdentidadDigital, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(ISL2) Búsqueda identidad digital
   * @param datosBusquedaIdentidadDigital datos a buscar
   * @param xRequestId Id
   * @returns return
   */
  identDigSLBuscarIdentidadDigital$(datosBusquedaIdentidadDigital: IdentidadDigitalBusqueda, xRequestId?: string): Observable<Array<IdentidadDigitalConsulta> | HttpErrorResponse> {
    return this.identDigSLServ.postBusquedaIdentidades(datosBusquedaIdentidadDigital, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(ISL3) Consulta de la identidad digital
   * @param identidad id
   * @param tipoIdentidad tipo
   * @param xRequestId Id
   * @returns return
   */
  identDigSLConsultarIdentidadDigital$(identidad: string, tipoIdentidad: TipoIdentidadEnum, xRequestId?: string): Observable<IdentidadDigitalConsulta | HttpErrorResponse> {
    return this.identDigSLServ.getIdentidadDigital(identidad, tipoIdentidad, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(ISL4) Modificación de la identidad digital
   * @param identidad id
   * @param datosIdentidadDigitalActualizar datos a actualizar
   * @param xRequestId Id
   * @returns return
   */
  identDigSLModificarIdentidadDigital$(identidad: string, datosIdentidadDigitalActualizar: IdentidadDigitalModificacion, xRequestId?: string): Observable<any> {
    return this.identDigSLServ.putIdentidadDigital(identidad, datosIdentidadDigitalActualizar, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(ISL5) Borrado identidad digital
   * @param identidad id
   * @param tipoIdentidad tipo
   * @param xRequestId Id
   * @returns return
   */
  identDigSLBorrarIdentidadDigital$(identidad: string, tipoIdentidad: TipoIdentidadEnum, xRequestId?: string): Observable<any> {
    return this.identDigSLServ.deleteIdentidadDigital(identidad, tipoIdentidad, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(ISL6) Modificar atributos particulares de la identidad digital
   * @param identidad id
   * @param datosParcheoIdentidadDigital datos a modificar
   * @param xRequestId Id
   * @returns return
   */
  identDigSLModificarAtributosParticularesIdentidadDigital$(identidad: string, datosParcheoIdentidadDigital: IdentidadDigitalParcheo, xRequestId?: string): Observable<any> {
    return this.identDigSLServ.patchIdentidadDigital(identidad, datosParcheoIdentidadDigital, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  //#endregion

    //#region *** PROV-ASEG ***
  /**
   * @description(PA 1) Devuelve un listado de ficheros
   * @param identificador identificador
   * @returns return
   */
  provAsegRecuperarListadoFicheros$(identificador: string): Observable<Array<Adjuntos> | HttpErrorResponse> {
    return this.provAsegServ.proveedoresAseguradosRecuperarListaFicherosDocumentacion(identificador, 'PA').pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(PA 2) Adjunta un fichero a una referencia, caso, etc...
   * @param identificador identificador
   * @param uploadedFile adjunto
   * @returns return
   */
  provAsegAdjuntarFichero$(identificador: string, uploadedFile: Blob): Observable<DevolverMensaje | HttpErrorResponse> {
    return this.provAsegServ.proveedoresAseguradosAdjuntarDocumentacion(identificador, 'PA', uploadedFile).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(PA 3) Elimina un adjunto de un caso, referencia, etc...
   * @param identificador identificador
   * @param nombreFichero nombre a eliminar
   * @returns return
   */
  provAsegEliminarAdjunto$(identificador: string, nombreFichero: string): Observable<DevolverMensaje | HttpErrorResponse> {
    return this.provAsegServ.proveedoresAseguradosEliminarFicherosDocumentacion(identificador, nombreFichero, 'PA').pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(PA 4) Devuelve el contenido de un fichero
   * @param identificador identificador
   * @param nombreFichero name
   * @returns return
   */
  provAsegDevolverContenidoFichero$(identificador: string, nombreFichero: string): Observable<Adjunto | HttpErrorResponse> {
    return this.provAsegServ.proveedoresAseguradosRecuperarFicherosDocumentacion(identificador, nombreFichero, 'PA').pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(PA 5) Listado de las notas del identificador
   * @param identificador identificador
   * @returns return
   */
  provAsegDevolverListadoNotas$(identificador: string): Observable<DevolverNotas | HttpErrorResponse> {
    return this.provAsegServ.proveedoresAseguradosRecuperarNota(identificador, 'PA').pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(PA 6) Devuelve el link para poder hacer login
   * @param identificador identificador
   * @param empresa empresa
   * @returns return
   */
  provAsegDevolverLinkParaLogin$(identificador: string, empresa: string): Observable<IdentidadesSalida | HttpErrorResponse> {
    return this.provAsegServ.proveedoresAseguradosGenerarLink(identificador, 'PA', empresa).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(PA 7) Inserta una nota nueva en un caso
   * @param identificador identificador ds
   * @param usuario user
   * @param comentario pass
   * @returns return
   */
  provAsegInsertarNota$(identificador: string, usuario: string, comentario: string): Observable<DevolverMensaje | HttpErrorResponse> {
    return this.provAsegServ.proveedoresAseguradosInsertarNota(identificador, usuario, comentario, 'PA').pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(PA 8) Elimina una nota en un caso
   * @param identificador identificador
   * @param idNota id de la nota
   * @returns return
   */
  provAsegEliminarNota$(identificador: string, idNota: string): Observable<DevolverMensaje | HttpErrorResponse> {
    return this.provAsegServ.proveedoresAseguradosEliminarNota(identificador, idNota, 'PA').pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(PA 9) Guarda un justificante de entrega
   * @param identificador identificador
   * @param uploadedFile archivo
   * @returns return
   */
  provAsegGuardarJustificanteEntrega$(identificador: string, uploadedFile: Blob): Observable<DevolverMensaje | HttpErrorResponse> {
    return this.provAsegServ.proveedoresAseguradosGuardarJustificanteEntrega(identificador, uploadedFile).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(PA 10) Devuelve un texto desencriptado
   * @param texto text
   * @returns return
   */
  provAsegDevolverTextoDesencriptado$(texto: string): Observable<string | HttpErrorResponse> {
    return this.provAsegServ.proveedoresAseguradosDesencriptar(texto).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(PA 11) Consulta el detalle de un encargo
   * @param identificador identificador
   * @param idProveedor id proveedor
   * @returns return
   */
  provAsegConsultarDetalleEncargo$(identificador: string, idProveedor: string): Observable<DevolverMensaje | HttpErrorResponse> {
    return this.provAsegServ.proveedoresAseguradosConsultarDetalle(identificador, idProveedor, 'PA').pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(PA 12) Actualiza el certificado de un encargo
   * @param identificador identificador
   * @param datosActualizar data
   * @returns return
   */
  proveedoresAseguradosActualizarEncargo$(identificador: string, datosActualizar: EncargoActualizarEntrada): Observable<DevolverAvances | HttpErrorResponse> {
    return this.provAsegServ.proveedoresAseguradosActualizarEncargo(identificador, datosActualizar, 'PA').pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  //#endregion

  //#region *** MIS CONSULTAS ***
  /**
   * @description(Q1) Insertar una consulta
   * @param consulta consulta
   * @returns return
   */
  misConsultasInsertarConsulta$(consulta: InsertarConsulta): Observable<ResInsertarConsulta | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasInsertarConsulta(consulta).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(Q2) Devuelve una consulta determinada
   * @param idConsulta id
   * @returns return
   */
  misConsultasRecuperarConsulta$(idConsulta: string): Observable<ResMisConsultas | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasRecuperarConsulta(idConsulta).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(Q3) Actualiza una consulta determinada
   * @param idConsulta id
   * @param consulta consulta
   * @returns return
   */
  misConsultasActualizarConsulta$(idConsulta: string, consulta: ActualizarConsulta): Observable<ResActualizarConsulta | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasActualizarConsulta(idConsulta, consulta).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description(Q4) Devuelve un listado de consultas de un caso
   * @param identificador identificador
   * @returns return
   */
  misConsultasRecuperarListadoConsulta$(identificador: string): Observable<ResListadoMisConsultas | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasRecuperarListadoConsulta(identificador).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (Q5) Devuelve una consulta por asegurado
   * @param idAsegurado id asegurado
   * @returns return
   */
  misConsultasRecuperarConsultaAsegurado$(idAsegurado: string): Observable<ResConsultasAsegurado | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasRecuperarConsultaAsegurado(idAsegurado).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  //#endregion
    //#region *** CREDENCIALES ***
  /**
   * @description (C1) Reseteo de userPassword. Se recibe un id polimorfico
   * @param identidad id
   * @param tipoIdentidad  type
   * @param xRequestId reques id
   * @returns return
   */
  credentResetearPassSL$(identidad: string, tipoIdentidad: TipoIdentidadEnum, xRequestId?: string): Observable<any> {
    return this.credentServ.putReseteoPassword(identidad, tipoIdentidad, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
}
