import { Component } from '@angular/core';
import { LoaderService } from './loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  isLoading: Observable<boolean> = this.loaderService.isLoading$;
/**
 * loaderService
 * @param loaderService loaderService
 */
  constructor(private loaderService: LoaderService) {
    this.loaderService = loaderService;
  }
}
