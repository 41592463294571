import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  //#region STATE
  public userData: any = {
    name: 'Antonio Torres',
    profile: 'Proveedor',
    initials: 'AT',
    color: ''
  };
  public title: string;
  public headerMobile: boolean;
  //#endregion
/**
 * @param router router
 * @param route route
 */
  constructor(protected router: Router, protected route: ActivatedRoute) {
    this.router = router;
    this.route = route;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((r1) => {
          while (r1.firstChild) {
            r1 = r1.firstChild;
          }
          return r1;
        }),
        filter((r2) => r2.outlet === 'primary'),
        mergeMap((r3) => r3.data)
      )
      .subscribe((data) => {
        this.title = data.header;
        if (data.headerMobile !== undefined) {
          this.headerMobile = data.headerMobile;
        } else {
          this.headerMobile = true;
        }
      });
  }
}
