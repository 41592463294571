import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Numbers } from '../../utils/constants';

@Directive({
  selector: '[appAvoidNumberOrZeros]'
})
export class AvoidNumberOrZerosDirective {

  public regexStr = '^[1-9]*$';
  @Input() isNumeric: boolean;
/**
 * @param el element
 */
  constructor(private el: ElementRef) {
    this.el = el;
  }
/**
 * @param event event
 * @returns return
 */
  @HostListener('keypress', ['$event']) onKeyPress(event: any): boolean {
    return new RegExp(this.regexStr).test(event.key);
  }
/**
 * @param event event
 */
  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent): void {
    this.validateFields(event);
  }
/**
 * @param event event
 */
  validateFields(event: any): void {
    setTimeout(() => {

      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^1-9.]/g, '').replace(/\s/g, '');
      event.preventDefault();

    }, Numbers.number100);
  }

}
