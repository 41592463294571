import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Numbers } from '../../utils/constants';

@Directive({
  selector: '[appLimitCharacters]'
})
export class LimitCharactersDirective {

  @Input() limit: number;
  /**
   * @param el element
   */
  constructor(private el: ElementRef) {
    this.el = el;
  }
/**
 * @param event evento
 */
  @HostListener('keypress', ['$event']) onKeyPress(event: any): void {
    this.validateFields(event);
  }
/**
 * @param event evento
 */
  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent): void {
    this.validateFields(event);
  }
/**
 * @param event evento
 */
  validateFields(event: any): void {
    setTimeout(() => {

      if (this && this.el && this.el.nativeElement && this.el.nativeElement.maxLength && this.el.nativeElement.value
        && this.el.nativeElement.value.length && this.el.nativeElement.value.length > this.el.nativeElement.maxLength) {
        event.preventDefault();
      }

    }, Numbers.number100);
  }
}

