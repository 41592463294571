import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './modules/layout/layout.component';
import { Error404Component } from './modules/layout/components/error404/error404.component';
import { NavigatorsCheckGuard } from './core/guards/navigators-check-guard/navigators-check.guard';
import { NavigatorIncompatibleComponent } from './modules/layout/components/navigator-incompatible/navigator-incompatible.component';
import { RegistrationComponent } from './modules/registration/registration.component';

/**
 * routes
 */
const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'record'
      },
      {
        path: 'record',
        canLoad: [NavigatorsCheckGuard],
        loadChildren: () => import('./modules/record/list-record.module').then((m) => m.ListRecordModule),
        data: {
          header: 'HEADER.TITLE.RECORD',
          headerMobile: false
        }
      },
      {
        path: 'help',
        canLoad: [NavigatorsCheckGuard],
        loadChildren: () => import('./modules/help/help.module').then((m) => m.HelpModule),
        data: {
          header: 'HEADER.TITLE.HELP'
        }
      },
      {
        path: 'queries',
        canLoad: [NavigatorsCheckGuard],
        loadChildren: () => import('./modules/queries/queries.module').then((m) => m.QueriesModule),
        data: {
          header: 'HEADER.TITLE.QUERIES'
        }
      }
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'registration',
    canLoad: [NavigatorsCheckGuard],
    component: RegistrationComponent
  },
  { path: 'browser-no-compatible', component: NavigatorIncompatibleComponent },
  { path: '**', component: Error404Component }
];

/**
 * @NgModule({
 * imports: [RouterModule.forRoot(routes)],
 * exports: [RouterModule]
 * })
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
