import { DocumentContext } from 'projects/portal-asegurados-front/src/app/core/models/api/asegurados/Documentacion';
import { PreguntasRespuestas } from 'projects/portal-asegurados-front/src/app/core/models/api/datos-comunes/PreguntasRespuestas';
/**
 * Table
 */
export interface Table {
  dataSource: PreguntasRespuestas[] | DocumentContext [] | any;
  cols: TableCol[];
  selectable?: TableSelectableType;
  pagination?: boolean;
  rowsPerPage?: number;
  orderable?: boolean;
  fromDocModal?: boolean;
}
/**
 * TableDataSource
 */
export interface TableDataSource {
  [name: string]: string | number;
}
/**
 * TableCol
 */
export interface TableCol {
  field: string;
  header: string;
  showFilter: boolean;
  showInfoTooltip?: boolean;
  totalFiles?: boolean;
  class?: string;
  columnOrderInTable?: number;
  columnOrderInCard?: number;
  hideInTable?: boolean;
  hideInCard?: boolean;
  icon?: string;
  order?: TableOrder;
  isDate?: boolean;
  dateFormat?: string;
  launchSelectRowEvent?: string;
  headerCard?: string;
  isBoolean?: boolean;
  colType?: string;
  width?: string;
  convertData?: { [key: string]: string };
}
/**
 * TableSelectableType
 */
export enum TableSelectableType {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  NONE = 'none'
}
/**
 * TableOrder
 */
export enum TableOrder {
  UP = 'up',
  DOWN = 'down'
}
/**
 * TableStatus
 */
export interface TableStatus {
  rowsToDisplay: number;
  firstElement: number;
  rowsSelected?: any | any[];
}
