import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2 } from '@angular/core';
import { Utils } from '../../utils/utils';
import { TableStatus } from '../no-category/table/models/table';
import { Numbers } from '../../../utils/constants';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() dataElements: number;

  @Output() tableUpdate = new EventEmitter<TableStatus>();

  public tableStatus: TableStatus = {
    rowsToDisplay: Numbers.number20,
    firstElement: Numbers.number0
  };

  public dropDownValues: any = [
    { label: Numbers.number10, value: Numbers.number10 },
    { label: Numbers.number20, value: Numbers.number20 },
    { label: Numbers.number50, value: Numbers.number50 },
    { label: Numbers.number100, value: Numbers.number100 }
  ];

  public positionSelected: number;
  public totalPages: number;
  public range: Array<number> = [];
/**
 * constructor
 */
  constructor(private renderer: Renderer2) {
    this.renderer = renderer;
  }
/**
 * on init
 */
  ngOnInit(): void {
    this.positionSelected = Numbers.number1;
    this.updateTable();
    this.generatePaginator();
  }
/**
 * on changes
 */
  ngOnChanges(): void {
    this.positionSelected = Numbers.number1;
    this.generatePaginator();
    this.updateTable();
  }
/**
 * generate paginator
 */
  generatePaginator(): void {
    this.range = [];
    this.totalPages = Math.ceil(this.dataElements / this.tableStatus.rowsToDisplay);

    for (let i: number = Numbers.number1; i < this.totalPages; i++) {
      this.range.push(i);
    }
  }
/**
 * @param dir dir
 */
  onChangeSelectPosition(dir: string): void {
    if (dir === 'advance' && this.positionSelected < this.totalPages) {
      this.positionSelected++;
      this.updateTable();
    } else if (dir === 'descend' && this.positionSelected > Numbers.number1) {
      this.positionSelected--;
      this.updateTable();
    } else {}
  }
/**
 * @param n selected position
 */
  onSelectPosition(n: number): void {
    this.positionSelected = n;
    this.updateTable();
  }
/**
 * @param event event
 */
  onChangeRowsToDisplay(event: any): void {
    this.positionSelected = Numbers.number1;
    this.generatePaginator();
    this.updateTable();

    Utils.screenReader.speak(`Se están mostrando ${event.value} elementos`, this.renderer);
  }
/**
 * update table
 */
  private updateTable(): void {
    const rowsPerPage: number = this.tableStatus.rowsToDisplay;
    this.tableStatus.firstElement = this.positionSelected * rowsPerPage - rowsPerPage;
    this.tableUpdate.emit(this.tableStatus);
  }

  /**
   * trackByFn
   * @param item item
   * @returns item id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
