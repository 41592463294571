/**
 * imports
 */
import { extractCurrentDateWithoutHours } from './library/date.utils';
import { displayServerErrorInConsole, displayClientErrorInConsole, setCriticality } from './library/error.utils';
import { markAllInputsAsTouched, transformIntoDropdownValues } from './library/form.utils';
import { changeLookAndFeel } from './library/look-feel.utils';
import { downloadPDF, showPDF } from './library/manage-pdf.utils';
import { simplifyUserDataSL } from './library/manage-server-data.utils';
import { generateRandomPass } from './library/pass.utils';
import { speak } from './library/screen-reader.utils';
import { getValidatorFromType, validateNIF } from './library/validator-gen.utils';
import { emailRegex } from './library/variables.utils';
import { capitalizeFirstLetterOfEveryWord, deleteUnderscoreAndCapitalize } from './library/text.utils';
/**
 * Utils
 */
export const Utils: any = {
  date: { extractCurrentDateWithoutHours },
  error: { displayServerErrorInConsole, displayClientErrorInConsole, setCriticality },
  form: { markAllInputsAsTouched, transformIntoDropdownValues},
  lookFeel: { changeLookAndFeel },
  managePdfUtils: { downloadPDF, showPDF },
  manageServerData: { simplifyUserDataSL },
  pass: { generateRandomPass },
  screenReader: { speak },
  text: { capitalizeFirstLetterOfEveryWord, deleteUnderscoreAndCapitalize },
  validatorGen: { getValidatorFromType, validateNIF },
  variables: { emailRegex }
};
/**
 * HistoryTabs
 */
export interface HistoryTabs {
  key: string;
  value: string;
  APIid: string;
  APIname: string;
}

export interface RadioButton {
  extraTextWhenTrue: any;
  itemWithExtraTextWhenTrue: any;
}

export interface DataHistory {
    extraTextWhenTrue: any;
    itemWithExtraTextWhenTrue: any;
}
/**
 * GeneratedForm
 */
export interface GeneratedForm {
  type: string;
  class: string;
  max: number;
  required: boolean;
}

/**
 * StatusModalId
 */
export interface StatusModalId {
  id: number;
  selfDisplay: boolean;
  isSuccess: boolean;
  translateText?: string;
  displayedText?: string;
  variableText?: string;
}
