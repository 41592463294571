import { Component } from '@angular/core';

@Component({
  selector: 'app-navigator-incompatible',
  templateUrl: './navigator-incompatible.component.html',
  styleUrls: [ './navigator-incompatible.component.scss' ]
})
/**
 * NavigatorIncompatibleComponent
 */
export class NavigatorIncompatibleComponent {
}
