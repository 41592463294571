import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, Observable } from 'rxjs';
import { AccesTokenResponse, AppConfig, TokenUrl } from './models/boot.model';

@Injectable()
export class BootService {
  /**
   * @param http http
   */
  constructor(private http: HttpClient) {
    this.http = http;
  }

  private appConfig: AppConfig;
/**
 * @returns init return
 */
  init(): Promise<void> {
    return new Promise<void>((resolve) => {
      forkJoin([this.getTokenRequest('comunes'), this.getTokenRequest('identidad')]).subscribe((result: AccesTokenResponse[]) => {
        localStorage.setItem('X-TOKEN-DatosComunes', result[0].access_token);
        localStorage.setItem('X-TOKEN-IdentidadDigital', result[1].access_token);
        resolve();
      });
    });
  }
/**
 * @param selectedToken token selected
 * @returns return
 */
  getTokenRequest(selectedToken: 'comunes' | 'identidad'): Observable<any> {
    let tokenUrl: string;
    switch (selectedToken) {
      case 'comunes':
        tokenUrl = TokenUrl.CommonData;
        break;
      case 'identidad':
        tokenUrl = TokenUrl.IdentDig;
        break;
      default:
        console.error(`Can't get token from server`);
        break;
    }
    if (tokenUrl) {
      return  this.http.get(tokenUrl);
    }
  }
/**
 * @returns return
 */
  loadAndStoreAppConfig(): Promise<AppConfig> {
    return this.http
      .get('/credentials')
      .toPromise()
      .then((res: AppConfig) => {
        this.appConfig = res;
        return res;
      });
  }
/**
 * @returns return
 */
  getAppConfig(): AppConfig {
    return this.appConfig;
  }
}
