import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Table } from '../../no-category/table/models/table';

@Component({
  selector: 'app-mobile-filter-menu',
  templateUrl: './mobile-filter-menu.component.html',
  styleUrls: ['./mobile-filter-menu.component.scss']
})
export class MobileFilterMenuComponent {
  @Input() tableData: Table;
  /**
   * status type
   */
  @Input() set statusType(value: number) {
    this.inputRadio = value;
  }

  @Output() closeModal = new EventEmitter<any>();
  @Output() filter = new EventEmitter<number>();

  public inputRadio = 0;
  public displayFilterItems = false;
/**
 * on close
 */
  public onClose(): void {
    this.closeModal.emit();
  }
/**
 * on submit
 */
  public onSubmit(): void {
    this.filter.emit(Number(this.inputRadio));
    this.closeModal.emit();
  }
}
