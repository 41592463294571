import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { LogoPath } from 'projects/portal-asegurados-front/src/app/core/models/local/logoPath.model';
import { UserData } from 'projects/portal-asegurados-front/src/app/core/models/local/userdata.model';
import { AuthService } from 'projects/portal-asegurados-front/src/app/core/providers/local/auth/auth.service';

import { MenuListData } from './navigation-menu/navigation-menu.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  //#region PROPS
  @ViewChild('languageFocus', { static: true }) languageFocus: ElementRef;
  @Input() inputTitle: string;
  @Input() headerMobile: boolean;
  @Input() userData: UserData;
  //#endregion

  //#region STATE
  public logoPath: LogoPath = this.authServ.getUserParam('userCompany').toLowerCase() === 'sos' ? LogoPath.ISOS : LogoPath.Santander;

  public displayMenu = false;
  public displayLanguageSelector = false;
  public languageSelected = 'ES';
  public mockNotificationData: any = [
    {
      id: 'GD-01',
      date: '05-09-2019',
      hour: '13:48',
      message:
        'Se ha producido una incidencia en el expediente GD-50, entre para solucionarloSe ha producido una incidencia en el expediente GD-50, entre para solucionarloSe ha producido una incidencia en el expediente GD-50, entre para'
    },
    {
      id: 'GD-02',
      date: '05-09-2019',
      hour: '13:40',
      message: 'Se ha cambiado a estado En Tramitación'
    },
    {
      id: 'GD-04',
      date: '05-09-2019',
      hour: '13:48',
      message: 'Se ha cambiado a estado Finalizado'
    },
    {
      id: 'GD-04',
      date: '05-09-2019',
      hour: '13:48',
      message: 'Se ha cambiado a estado Finalizado'
    },
    {
      id: 'GD-04',
      date: '05-09-2019',
      hour: '13:48',
      message: 'Se ha cambiado a estado Finalizado'
    }
  ];
  public menuList: MenuListData[] = [
    {
      icon: 'icon-document',
      id: 'MENU.OPTIONS.RECORD',
      url: '/record'
    },
    {
      icon: 'icon-warning',
      id: 'MENU.OPTIONS.HELP',
      url: '/help'
    },
    {
      icon: 'icon-info',
      id: 'MENU.OPTIONS.QUERIES',
      url: '/queries'
    }
  ];
  //#endregion
/**
 * @param translate translate
 * @param router router
 * @param authServ authServ
 */
  constructor(private translate: TranslateService, private router: Router, private authServ: AuthService) {
    this.translate = translate;
    this.router = router;
    this.authServ = authServ;
  }

/**
 * on init
 */
  ngOnInit(): void {
    this.languageNavigator();
  }
/**
 * togglemenu
 */
  public onToggleMenu(): void {
    this.displayMenu = !this.displayMenu;
  }
/**
 * toggle lenguage
 */
  public onToggleLanguageSelector(): void {
    if (this.displayLanguageSelector) {
      this.focusElement(this.languageFocus);
    }
    this.displayLanguageSelector = !this.displayLanguageSelector;
  }
/**
 * @param language language
 */
  public onChangeLanguage(language: string): void {
    this.languageSelected = language.toUpperCase();
    this.translate.use(language);
    this.onToggleLanguageSelector();
    this.saveOnSessionStorage('LANG', this.languageSelected);
  }
/**
 * @param elem element
 */
  private focusElement(elem: ElementRef): void {
    elem.nativeElement.focus();
  }
/**
 * @param key key
 * @param value value
 * @returns return
 */
  private saveOnSessionStorage(key: string, value: any): void {
    return sessionStorage.setItem(key, value);
  }
/**
 * language navigator
 */
  private languageNavigator(): void {
    if (navigator.language.includes('ca')) {
      this.translate.use('ct');
      this.saveOnSessionStorage('LANG', 'CT');
    } else {
      this.translate.use('es');
      this.saveOnSessionStorage('LANG', 'ES');
    }
  }
/**
 * @param event event
 * @param focusId focus id
 */
  public onRestartTabNavigation(event: KeyboardEvent, focusId: string): void {
    if (event.key === 'Tab') {
      this.focusElement(this[focusId]);
    } else if (event.key === ' ' || event.key === 'Enter') {
      this.onToggleLanguageSelector();
    } else {}
  }
/**
 * @param url url
 */
  onNavigateTo(url: string): void {
    this.router.navigate([url]);
  }

  // public onNavigateToMainContent() {
  //   const target: any = document.querySelector('#mainContent');

  //   if (target) {
  //     target.focus();
  //   } else {
  //     return;
  //   }
  // }

  // public onChangeText(sel: string) {
  //   if (sel === 'reduce') {
  //     this.currentFontSize--;
  //     document.querySelector('html').style.fontSize = this.currentFontSize + 'px';
  //   } else if (sel === 'increase') {
  //     this.currentFontSize++;
  //     document.querySelector('html').style.fontSize = this.currentFontSize + 'px';
  //   } else {
  //     this.currentFontSize = 10;
  //     document.querySelector('html').style.fontSize = this.currentFontSize + 'px';
  //   }
  // }
}
