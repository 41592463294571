import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../providers/local/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  /**
   * @param authService authService
   */
  constructor(private authService: AuthService) {
    this.authService = authService;
  }
/**
 * @returns return
 */
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const canEditQuestions = this.authService.getUserParam('userType').toLowerCase() === 'as';

    return canEditQuestions;
  }
}
