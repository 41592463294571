import { Component, EventEmitter, Input, Output } from '@angular/core';

enum Options {
  DETAIL = 'detail',
  SHOW = 'show',
  DOWNLOAD = 'download',
  DELETE = 'delete'
}

@Component({
  selector: 'app-control-icons',
  templateUrl: './control-icons.component.html',
  styleUrls: [ './control-icons.component.scss' ]
})
export class ControlIconsComponent {
  @Input() iconsState: string;
  @Output() clickEvent = new EventEmitter<any>();
/**
 * @param option option
 */
  onClick(option: string): void {
    this.clickEvent.emit(option);
  }
}
