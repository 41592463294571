import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen, take } from 'rxjs/operators';
import { AuthService } from '../providers/local/auth/auth.service';
import { Numbers } from '../../utils/constants';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  /**
   * @param authServ authServ
   */
  constructor(private authServ: AuthService) {
    this.authServ = authServ;
  }
  /**
   * @param req req
   * @param next next
   * @returns return
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen((error) => {
        return error.pipe(mergeMap(this.handleErrors), delay(Numbers.number1000), take(Numbers.number2));
      })
    );
  }
/**
 * @param elem elem
 * @returns return
 */
  private handleErrors(elem: HttpErrorResponse): Observable<HttpErrorResponse> {
    const errorsToControl: number[] = [Numbers.number403, Numbers.number502, Numbers.number503];

    const status: number = elem.status;

    const isAuthError = status === Numbers.number403;

    if (isAuthError) {
      this.refreshToken();
    }

    return errorsToControl.includes(status) ? of(elem) : throwError(elem);
  }
/**
 * refreshToken
 */
  private refreshToken(): void {
    const keycloakAuth: Keycloak.KeycloakInstance = this.authServ.keycloakInstance();

    if (keycloakAuth.refreshToken) {
      const TWO_HOURS_SECONDS = 7200;
      keycloakAuth.updateToken(TWO_HOURS_SECONDS);
    } else {
      keycloakAuth.login();
    }
  }
}
