import { Component } from '@angular/core';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent {
  /**
   * constructor
   */
  constructor() {}
}
