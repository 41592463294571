import { Numbers } from '../../../utils/constants';

/**
 * Validar por tipo
 * @param type type
 * @returns return
 */
export const getValidatorFromType: (type: string) => RegExp = (type: string): RegExp => {
  let regex: any;

  switch (type.toLowerCase()) {
    case 'dni':
      regex = /^(\d{8})([a-zA-Z]{1})$/;
      break;
    case 'nie':
      regex = /^([XYZ]{1})(\d{7,8})([A-Z]{1})$/;
      break;
    case 'nif':
      regex = /^(\d{8})([a-zA-Z]{1})$/;
      break;
    case 'cif':
      regex = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-XJ])$/;
      break;
    case 'pasaporte':
      regex = /^[a-z]{3}[0-9]{6}[a-z]?$/i;
      break;
    default:
      regex = /(.*?)/;
      break;
  }

  return regex;
};
/**
 * Validar NIF
 * @param nif nif
 * @returns return
 */
export const validateNIF: (nif: string) => boolean = (nif: string): boolean => {
  const allNumbersRegex: RegExp = new RegExp(/\d+/);
  const letterRegex: RegExp = new RegExp(/[A-Z]/);
  const nifRegex: RegExp = new RegExp(/^\d{8}[a-zA-Z]$/);
  const letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E', 'T'];
  let isValidLetter = false ;

  if (nifRegex.test(nif)) {
    const nifNumber = (nif.match(allNumbersRegex) || [])[Numbers.number0] || '';
    const remainder = Number(nifNumber) % Numbers.number23;
    const nifLetter = (nif.match(letterRegex) || [])[Numbers.number0] || '';

    isValidLetter = letters[remainder] === nifLetter;
  }
  return isValidLetter;
};
