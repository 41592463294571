import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { Numbers } from '../../utils/constants';

@Directive({
  selector: '[appAvoidSpecialCharacters]'
})
export class AvoidSpecialCharactersDirective {
  public regexStr = '^[a-zA-ZÀ-ÿ0-9_ ]*$';
  @Input() isAlphaNumeric: boolean;
/**
 * @param el element
 */
  constructor(private el: ElementRef) {
    this.el = el;
  }
/**
 * @param event evento
 * @returns return
 */
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent): boolean {
    return new RegExp(this.regexStr).test(event.key);
  }
/**
 * @param event evento
 */
  @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent): void {
    this.validateFields(event);
  }
/**
 * @param event evento
 */
  validateFields(event: any): void {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^a-zA-ZÀ-ÿ0-9_ ]/g, '');
      event.preventDefault();
    }, Numbers.number100);
  }
}
