import { AfterViewChecked, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appInputAriaRadio]'
})
/**
 * InputAriaRadioDirective
 */
export class InputAriaRadioDirective implements AfterViewChecked, OnInit {
  @Input() inputAriaLabel: string;

  public currentElement: any;

  /**
   * constructor
   * @param el ElementRef obj
   * @param translate TranslateService obj
   */
  constructor(private el: ElementRef, private translate: TranslateService) {
    this.el = el;
    this.translate = translate;
  }
/**
 * ngOnInit
 */
  ngOnInit(): void {
    this.currentElement = this.el.nativeElement;
  }
/**
 * ngAfterViewChecked
 */
  ngAfterViewChecked(): void {
    const inputId: any = [ ...this.currentElement.querySelectorAll('input') ][0];
    let ariaLabel: string;

    this.translate.get(this.inputAriaLabel).subscribe((res) => (ariaLabel = res));

    if (this.currentElement.tagName === 'P-RADIOBUTTON' && this.currentElement.textContent) {
      ariaLabel = ariaLabel + ' ' + this.currentElement.textContent;
    }
    inputId.setAttribute('aria-label', ariaLabel);

  }
}
