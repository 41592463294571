import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Numbers } from 'projects/portal-asegurados-front/src/app/utils/constants';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: [ './notification-menu.component.scss' ]
})
export class NotificationMenuComponent implements OnInit {
  @Input() notificationList: Array<any>;
  @ViewChild('close', {static: false}) close: ElementRef;
  public displayMenu: boolean;
/**
 * on init
 */
  ngOnInit(): void {
    this.checkMobile();
  }
/**
 * check mobile
 */
  private checkMobile(): void {
    if (window.innerWidth <= Numbers.number768) {
      this.notificationList.map((item: any) => {
        if (item.message.length >= Numbers.number75) {
          item.message = item.message.slice(Numbers.number0, Numbers.number72) + '...';
        }
      });
    }
  }
/**
 * @param elem element
 */
  private focusElement(elem: ElementRef): void {
    elem.nativeElement.focus();
  }
/**
 * @param event event
 * @param focusId focus id
 */
  public onRestartTabNavigation(event: KeyboardEvent, focusId: string): void {
    if (event.key === 'Tab') {
      this.focusElement(this[focusId]);
    } else if (event.key === ' ' || event.key === 'Enter') {
      this.displayMenu = !this.displayMenu;
    } else {}
  }

  /**
   * trackByFn
   * @param item item
   * @returns item id
   */
  trackByFn(item: any): number {
    return item.id;
  }

}
