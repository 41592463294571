import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakInstance } from 'keycloak-js';

interface UserData {
  userName?: string; // Nombre usuario
  userSurname?: string; // Apellidos usuario
  userMail?: string; // Email usuario
  userDocId?: string; // Número documento identidad (NIF, DNI, CIF)
  userIdRequestISOS?: string; // Identificador para peticiones ISOS
  userIdRequestIdentDig?: string; // Identificador para peticiones Identidad Digital
  userCompany?: string; // Compañia (válido para PA)
  lang?: string;
  userType?: 'TM' | 'AS' | 'BE' | 'AD';
}
@Injectable()
/**
 * Simple class to manage the app auth
 */
export class AuthService {
  /**
   * @param httpClient http client
   * @param keyCloakServ keyCloakServ
   */
  constructor(protected httpClient: HttpClient, private keyCloakServ: KeycloakService) {
    this.httpClient = httpClient;
    this.keyCloakServ = keyCloakServ;
  }

  private userData: UserData;

  /**
   * Method to manage USER DATA
   */
  public getUserData(): UserData {
    return this.userData;
  }
/**
 * @param param params
 * @returns return
 */
  public getUserParam(param: 'userName' | 'userSurname' | 'userMail' | 'userDocId' | 'userIdRequestISOS' | 'userIdRequestIdentDig' | 'userType' | 'userCompany' | 'lang'): string {
    return this.userData[param] || null;
  }
/**
 * @param newLang new language
 */
  public changeUserLang(newLang: 'ES' | 'CT'): void {
    this.userData.lang = newLang;
  }
/**
 * @returns return
 */
  public getUserId(): string {
    return this.userData.userIdRequestISOS;
  }
/**
 * @returns return
 */
  public getUserType(): string {
    return this.userData.userType;
  }
/**
 * @param newUserData new user data
 */
  public setUserData(newUserData: UserData): void {
    this.userData = newUserData;
  }

  /**
   * Logout current user session from Keycloak
   */
  public logout(): void {
    sessionStorage.clear();
    this.keyCloakServ.logout();
  }
/**
 * @returns return
 */
  keycloakInstance(): KeycloakInstance {
    const keycloakInstance: KeycloakInstance = this.keyCloakServ.getKeycloakInstance();

    return keycloakInstance;
  }
}
