import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component {
  /**
   * @param location location
   * @param router router
   */
  constructor(private location: Location, private router: Router) {
    this.location = location;
    this.router = router;
  }
/**
 * navigate back
 */
  onNavigateBack(): void {
    this.location.back();
  }
/**
 * navigate to
 * @param url url
 */
  onNavigateTo(url: string): void {
    this.router.navigate([url]);
  }
}
