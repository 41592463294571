import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public subject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLoading$: Observable<boolean> = this.subject.asObservable();

  // Manage FOCUS
  private activeElement: any;
/**
 * display loader
 */
  public displayLoader(): void {
    this.activeElement = document.activeElement;
    this.subject.next(true);
  }
/**
 * hide loader
 */
  public hideLoader(): void {
    this.subject.next(false);

    // Manage FOCUS
    this.activeElement.focus();
  }
}
