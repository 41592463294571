import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { AuthService } from './core/providers/local/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  /**
   * @param angulartics2GoogleTagManager angulartics2GoogleTagManager
   * @param translate translate
   * @param authServ authServ
   */
  constructor(angulartics2GoogleTagManager: Angulartics2GoogleTagManager, private translate: TranslateService, private authServ: AuthService) {
    this.translate = translate;
    this.authServ = authServ;
    angulartics2GoogleTagManager.startTracking();
  }
/**
 * on init
 */
  ngOnInit() {
    this.consentCookies();

    // Change user language
    this.translate.onLangChange.subscribe((translateData) => {
      const { lang }: any = translateData;
      this.authServ.changeUserLang(lang.toUpperCase());
    });

    this.translate.onLangChange.subscribe((res) => {
      this.consentCookies();
    });
  }
/**
 * consent cookies
 */
  private consentCookies(): void {
    this.translate.get('COOKIES').subscribe((cookieText) => {
      this.setCookiesMessage(cookieText);
    });
  }
/**
 * @param cookietext cookie text
 */
  private setCookiesMessage(cookietext: any): void {
    const cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#28334a'
        },
        button: {
          background: '#fff',
          text: '#28334a'
        }
      },
      theme: 'classic',
      type: 'opt-in',
      content: {
        header: cookietext['COOKIE-HEADER'],
        message: cookietext['COOKIE-MESSAGE'],
        dismiss: cookietext['COOKIE-DISMISS'],
        allow: cookietext['COOKIE-ALLOW'],
        deny: cookietext['COOKIE-DENY'],
        link: cookietext['COOKIE-LINK'],
        href: 'https://www.internationalsos.es/sos/Media/docs/Politica_de_cookies_SOS.pdf',
        close: '&#x274c;',
        target: '_blank'
      }
    });
  }
}
