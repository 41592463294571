import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LogoPath } from 'projects/portal-asegurados-front/src/app/core/models/local/logoPath.model';
import { RibbonModal } from 'projects/portal-asegurados-front/src/app/core/models/local/ribbon-modal.model';
import { UserData } from 'projects/portal-asegurados-front/src/app/core/models/local/userdata.model';
import { FilterService } from 'projects/portal-asegurados-front/src/app/core/providers/local/filter/filter.service';

import { AuthService } from '../../../../../core/providers/local/auth/auth.service';

export interface MenuListData {
  icon: string;
  id: string;
  url?: string;
  hideMobile?: boolean;
  hideDesktop?: boolean;
}

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements AfterViewChecked {
  //#region PROPS
  @ViewChild('navigationFocus', { static: false }) navigationFocus: ElementRef;
  @Input() userData: UserData;
  @Input() menuListData: MenuListData[];
  @Input() menuStatus: boolean;
  @Output() changeStatus = new EventEmitter();
  @Output() closeMenu = new EventEmitter();
  //#endregion

  //#region STATE
  public logoPath: LogoPath = this.authService.getUserParam('userCompany').toLowerCase() === 'sos' ? LogoPath.ISOS : LogoPath.Santander;

  // MODAL STATE
  public ribbonModal: RibbonModal = { display: false, type: null };
  public currentURL: string;
  //#endregion
/**
 * @param router router
 * @param authService auth
 * @param filterService filter
 */
  constructor(private router: Router, private authService: AuthService, private filterService: FilterService) {
    this.router = router;
    this.authService = authService;
    this.filterService = filterService;
  }

/**
 * ng AfterView
 */
  ngAfterViewChecked(): void {
    // this.focusElement(this.navigationFocus);
    this.currentURL = window.location.href;
  }
/**
 * @param url url
 */
  public onNavigate(url: string): void {
    this.changeStatus.emit();
    this.router.navigate([url]);
  }
/**
 * hide menu
 */
  public onHideMenu(): void {
    this.changeStatus.emit();
  }
/**
 * logout
 */
  public onLogout(): void {
    this.authService.logout();
    this.filterService.clear();
  }
/**
 * @param event event
 * @param focusId focus id
 */
  public onRestartTabNavigation(event: KeyboardEvent, focusId: string): void {
    if (event.key === 'Tab') {
      this.focusElement(this[focusId]);
    } else if (event.key === ' ' || event.key === 'Enter') {
      this.onHideMenu();
    } else {}
  }
/**
 * @param elem elem
 */
  private focusElement(elem: ElementRef): void {
    if (elem) {
      elem.nativeElement.focus();
    }
  }
/**
 * hide modal
 */
  hideRegisterModal(): void {
    this.ribbonModal.display = false;
  }
/**
 * open logout modal
 */
  onOpenPreventLogoutModal(): void {
    this.ribbonModal.display = true;
    this.ribbonModal.type = 'MODAL.LOGOUT';
  }

  /**
   * trackByFn
   * @param item item
   * @returns item id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
