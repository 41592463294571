import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Numbers } from '../../../utils/constants';

@Injectable()
export class NavigatorsCheckGuard implements CanActivate, CanActivateChild, CanLoad {
  /**
   * @param deviceService device service
   * @param router router
   */
  constructor(private deviceService: DeviceDetectorService, private router: Router) {
    this.deviceService = deviceService;
    this.router = router;
  }
/**
 * @returns return
 */
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkDevice();
  }
/**
 * @returns return
 */
  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkDevice();
  }
/**
 * @returns return
 */
  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkDevice();
  }
/**
 * @returns return
 */
  checkDevice(): boolean | Promise<boolean> {
    const deviceInfo: DeviceInfo = this.deviceService.getDeviceInfo();
    let result = true;

    if (deviceInfo.browser === 'IE' && Number(deviceInfo.browser_version) < Numbers.number11) {
      this.router.navigate(['browser-no-compatible']);
      result = false;
    }

    return result;
  }
}
