import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../../shared/components/loader/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private currentRequests: HttpRequest<any>[] = [];
/**
 * @param loaderService loader service
 */
  constructor(public loaderService: LoaderService) {
    this.loaderService = loaderService;
  }
/**
 * @param req req
 * @param next next
 * @returns return
 */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.addActiveRequest(req);

    this.loaderService.displayLoader();

    return next.handle(req).pipe(
      finalize(() => {
        this.removeFinishedRequest(req);

        this.manageLoaderObserver(this.currentRequests);
      })
    );
  }
/**
 * @param req req
 */
  private addActiveRequest(req: HttpRequest<any>): void {
    this.currentRequests.push(req);
  }
/**
 * @param req req
 */
  private removeFinishedRequest(req: HttpRequest<any>): void {
    this.currentRequests = this.currentRequests.filter((elem) => elem !== req);
  }
/**
 * @param currentRequests current request
 */
  private manageLoaderObserver(currentRequests: HttpRequest<any>[]): void {
    if (currentRequests.length === 0) {
      this.loaderService.hideLoader();
    } else {
      this.loaderService.displayLoader();
    }
  }
}
