/**
 * @param text text
 * @returns return
 */

export const capitalizeFirstLetterOfEveryWord: (text: any) => any = (text): any =>
  text
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
/**
 * @param text text
 * @returns return
 */
export const deleteUnderscoreAndCapitalize: (text: string) => string = (text: string): string =>
  text
    .toLowerCase()
    .replace(/_\w/g, (x) => ` ${x[1]}`)
    .replace(/^\w/, (x) => x.toUpperCase());
