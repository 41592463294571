import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-attached-docs-modal',
  templateUrl: './attached-docs-modal.component.html',
  styleUrls: ['./attached-docs-modal.component.scss']
})
export class AttachedDocsModalComponent implements OnInit {
  @Input() inputModalData: { documents: Array<string> };
  @Output() downloadIconClicked: EventEmitter<any> = new EventEmitter();
  @Output() fileStoredEmiter: EventEmitter<any> = new EventEmitter();
  @Output() eraseIconClicked: EventEmitter<any> = new EventEmitter();
  @Output() clickEmitter = new EventEmitter<any>();
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  public fileStored: File;
  public multipleWebcamsAvailable = false;
  public photoNameForm = new FormGroup({
    photoName: new FormControl(null, Validators.required)
  });
  public showFile = true;
  public showList = true;
  public takePhoto = false;
/**
 * constructor
 */
  constructor() {}
/**
 * on init
 */
  public ngOnInit(): void {
    this.clickEmitter.emit(this.changeVariablesTrue.bind(this));
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }
/**
 * @param doc document
 */
  public downloadDoc(doc: string): void {
    this.downloadIconClicked.emit(doc);
  }
/**
 * @param doc document
 */
  public eraseDoc(doc: string): void {
    this.eraseIconClicked.emit(doc);
  }
/**
 * @param event event
 */
  storeFile(event: any): void {
    this.fileStored = event;
  }
/**
 * @param directionOrDeviceId device or direction id
 */
  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }
/**
 * trigger snapshot
 */
  public triggerSnapshot(): void {
    this.trigger.next();
  }
/**
 * @param event event
 */
  public uploadPhoto(event: any): void {
    if (this.photoNameForm.valid) {
      this.fileStoredEmiter.emit({ img: event, name: this.photoNameForm.get('photoName').value});
      this.changeVariables(true);
      this.photoNameForm.reset();
      this.fileStored = null;
    }
  }
/**
 * upload file
 */
  public uploadFile(): void {
    this.fileStoredEmiter.emit(this.fileStored);
    this.changeVariables(true);
    this.fileStored = null;
  }
/**
 * @param bool boolean
 */
  public changeVariables(bool: boolean): void {
    this.showList = bool;
    this.showFile = bool;
  }
/**
 * change variables to true
 */
  public changeVariablesTrue(): void {
    this.showList = true;
    this.showFile = true;
  }
/**
 * nextWebcamObservable
 */
  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }
/**
 * triggerObservable
 */
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
/**
 * @param error err
 */
  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      console.warn('Camera access was not allowed by user!');
    }
  }

  /**
   * trackByFn
   * @param item item
   * @returns item id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
