import { Pipe, PipeTransform } from '@angular/core';
import { TableCol } from '../components/no-category/table/models/table';
import { Numbers } from '../../utils/constants';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
  /**
   * @param value value
   * @param display display
   * @returns return
   */
  transform(value: TableCol[], display: string): TableCol[] {
    const arr: TableCol[] = [ ...value ];
    let arrayDisplay: TableCol[] = [ ...arr ];
    arr.map((item) => {
      item.columnOrderInCard
        ? (item.columnOrderInCard = item.columnOrderInCard)
        : (item.columnOrderInCard = Numbers.number100);
    });

    arr.map((item) => {
      item.columnOrderInTable
        ? (item.columnOrderInTable = item.columnOrderInTable)
        : (item.columnOrderInTable = Numbers.number100);
    });
/**
 * @param a a
 * @param b b
 * @returns return
 */
    const compareOnCard: (a: TableCol, b: TableCol) => number = (a: TableCol, b: TableCol): number => {
      const itemA: number = a.columnOrderInCard;
      const itemB: number = b.columnOrderInCard;

      let comparison: number = Numbers.number0;
      if (itemA > itemB) {
        comparison = Numbers.number1;
      } else if (itemA < itemB) {
        comparison = Numbers.numberN1;
      } else {}

      return comparison;
    };
  /**
   * @param a a
   * @param b b
   * @returns return
   */
    const compareOnTable: (a: TableCol, b: TableCol) => number = (a: TableCol, b: TableCol): number => {
      const itemA: number = a.columnOrderInTable;
      const itemB: number = b.columnOrderInTable;

      let comparison: number = Numbers.number0;
      if (itemA > itemB) {
        comparison = Numbers.number1;
      } else if (itemA < itemB) {
        comparison = Numbers.numberN1;
      } else {}

      return comparison;
    };

    if (display === 'table') {
      arrayDisplay = [ ...arr.sort(compareOnTable) ];
    } else if (display === 'card') {
      arrayDisplay = [ ...arr.sort(compareOnCard) ];
    } else {}
    return arrayDisplay;
  }
}
