import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { FilterService } from 'projects/portal-asegurados-front/src/app/core/providers/local/filter/filter.service';
import { Table, TableCol, TableDataSource, TableOrder, TableStatus } from './models/table';
import { Numbers } from 'projects/portal-asegurados-front/src/app/utils/constants';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnChanges {
  //#region PROPS
  @Input() inputTableStatus: TableStatus;
  @Input() inputTableConfig: Table;
  @Input() iconsState: string;
  @Input() tableType = false;

  @Output() updatePaginator = new EventEmitter<any>();
  @Output() rowsSelectedEvent: EventEmitter<any | any[]> = new EventEmitter<any>();
  @Output() iconClick: EventEmitter<any | any[]> = new EventEmitter<any>();
  //#endregion

  //#region STATE
  public filteredDataSource: TableDataSource[];
  public filtersSelected: any[] = [];
  //#endregion
/**
 * @param filterService filterService
 */
  constructor(private filterService: FilterService) {
    this.filterService = filterService;
  }
/**
 * on changes
 */
  ngOnChanges(): void {
    this.filtersSelected = [];
    this.filterService.clearDataSource();
    this.filterService.storeOriginalDataSource(this.inputTableConfig.dataSource);

    this.setSortButtonInColumn();

    // Set table data from INPUT
    const defaultTableStatus: TableStatus = {
      rowsToDisplay: Numbers.number20,
      firstElement: Numbers.number0
    };

    this.inputTableStatus = this.inputTableStatus || defaultTableStatus;
  }
/**
 * sort button in column
 */
  private setSortButtonInColumn(): void {
    if (this.inputTableConfig && this.inputTableConfig.cols) {
      this.inputTableConfig.cols.forEach((col) => (col.order = TableOrder.UP));
    }
  }
/**
 * @param rowData row data
 * @param option options
 */
  onManageRow(rowData: TableDataSource, option: string): void {
    this.iconClick.emit({ rowData, option });
  }
/**
 * @param col column
 */
  onManageFilterVisibility(col: TableCol): void {
    if (col.header === 'TABLE.HEADERS.ATTACH' || col.header === 'BUTTONS.DOWNLOAD-DOCUMENT') {
    } else {
      col.showFilter = !col.showFilter;
    }
  }
/**
 * @param event event
 */
  manageFilterSelected(event: TableDataSource[]): void {
    this.inputTableConfig.dataSource = event;

    // Set filtersSelected in order to give style on col title selected
    this.filtersSelected = this.filterService.getColsSelected();
  }
/**
 * on row
 */
  onRowChecked(): void {
    this.rowsSelectedEvent.emit(this.inputTableStatus.rowsSelected);
  }
/**
 * @param data data
 */
  onUpdateTable(data: TableStatus): void {
    this.inputTableStatus = data;
  }
/**
 * @param col column
 */
  sort(col: TableCol): void {
    this.inputTableConfig.cols.filter((column) => column.field !== col.field).forEach((column) => (column.order = TableOrder.UP));

    // Invert current col order
    col.order = col.order === TableOrder.UP ? TableOrder.DOWN : TableOrder.UP;

    this.shortData(this.inputTableConfig.dataSource, col, col.colType === 'date');
  }
/**
 * @param arr array
 * @param col column
 * @param isDate is date
 */
  private shortData(arr: Array<any>, col: TableCol, isDate: boolean): void {
    arr.sort((a, b) => {
      const aConverted: string | number = this.transformCellValue(a[col.field], isDate);
      const bConverted: string | number = this.transformCellValue(b[col.field], isDate);

      let order: number;

      if (aConverted > bConverted) {
        order = col.order === TableOrder.UP ? Numbers.number1 : Numbers.numberN1;
      } else if (aConverted < bConverted) {
        order = col.order === TableOrder.UP ? Numbers.numberN1 : Numbers.number1;
      } else {
        order = Numbers.number0;
      }
      return order;
    });
  }
/**
 * @param value value
 * @param isDate is date
 * @returns return
 */
  private transformCellValue(value: any, isDate: any): string | number {
    let transformedValue: string | number;
    if (isDate) {
      transformedValue = this.convertDateToTime(value);
    } else if (!isNaN(value)) {
      transformedValue = Math.abs(Number(value));
    } else {
      transformedValue = value;
    }

    return transformedValue;
  }
/**
 * @param inputDate input date
 * @returns return
 */
  private convertDateToTime(inputDate: string): number {
    const date: Date = new Date(this.changeDateFormat(inputDate));
    const dateValue: number = date.getTime();
    return dateValue;
  }
/**
 * @param date date
 * @returns return
 */
  private changeDateFormat(date: string): string {
    const arr: string[] = date.split('-');
    const month: string[] = arr.splice(Numbers.number1, Numbers.number1);

    arr.unshift(...month);
    return arr.join('-');
  }

  /**
   * trackByFn
   * @param item item
   * @returns item id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
