import { AbstractControl } from '@angular/forms';
import { Numbers } from '../../utils/constants';
/**
 * Validar NIF
 * @param control control
 * @returns return
 */
export const validNIFValidator: (control: AbstractControl) =>
 {[key: string]: boolean; } = (control: AbstractControl): { [key: string]: boolean } => {
  const nif: any = control.value;
  let isValidLetter: any;

  // ** Check if NIF has the correct format
  const isInvalidNIF: { isInvalidNIF: boolean; } = checkNIF(nif);

  if (!isInvalidNIF) {
    const allNumbersRegex: RegExp = new RegExp(/\d+/);
    const letterRegex: RegExp = new RegExp(/[a-zA-Z]/);

    const letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E', 'T'];

    // ** Get the 'REMAINDER' of the NUM and the current Letter
    const nifNumber = (nif.match(allNumbersRegex) || [])[Numbers.number0] || '';
    const remainder = Number(nifNumber) % Numbers.number23;

    const nifLetter = (nif.match(letterRegex) || [])[Numbers.number0] || '';

    isValidLetter = letters[remainder].toLowerCase() === nifLetter.toLowerCase();
  }
  return isValidLetter ? null : isInvalidNIF;
};
/**
 * @param nif nif
 * @returns return
 */
function checkNIF(nif: any): { isInvalidNIF: boolean; } {
  const NIF_REGEX: RegExp = new RegExp(/^\d{8}[a-zA-Z]$/);

  if (!NIF_REGEX.test(nif)) {
    return { isInvalidNIF: true };
  }
}
/**
 * Validar CIF
 * @param control control
 * @returns return
 */
export const validCIFValidator: (control: any) => {[key: string]: boolean; } =
(control: any): { [key: string]: boolean } => {
  const cif: any = control.value;
  const CIF_REGEX = new RegExp(/^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/);

  let result: any = { isInvalidCIF: true };

  if (CIF_REGEX.test(cif)) {
    result = logicToValidateCif(cif);
  }

  return result;
};
/**
 * @param cif cif
 * @returns return
 */
const logicToValidateCif: (cif: any) => any = (cif: any): any => {
  let result: any;

  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
  const cifNumbers: any = (cif.match(/\d{7}/) || [])[0];
  const cifControlDig: any = (cif.match(/([0-9A-J])$/) || [])[0];
  const sumEvenAndOdds: any = cifNumbers.split('').reduce(
    (acc: { even: number; odd: number }, curr: string, i: number) => {
      if (i % Numbers.number2 === Numbers.number0) {
        const num = String(Number(curr) * Numbers.number2);
        acc.odd +=
          num.length > Numbers.number1
            ? Number(num.charAt(Numbers.number0)) + Number(num.charAt(Numbers.number1))
            : Number(num.charAt(Numbers.number0));
      } else {
        acc.even += Number(curr);
      }
      return acc;
    },
    { even: 0, odd: 0 }
  );

  const sumDigits = String(sumEvenAndOdds.even + sumEvenAndOdds.odd);
  const resultControlDigit: number = Number(sumDigits) % Numbers.number10 === Numbers.number0 ? Numbers.number0 : Numbers.number10 - Number(sumDigits.charAt(Numbers.number1));

  if (isNaN(cifControlDig)) {
    result = letters[resultControlDigit] === cifControlDig
      ? null
      : { isInvalidCIF: true };
  }

  if (!result) {
    result = Number(cifControlDig) === resultControlDigit
      ? null
      : { isInvalidCIF: true };
  }

  return result;
};
/**
 * Validar NIE
 * @param control control
 * @returns return
 */
export const validNIEValidator: (control: AbstractControl) => {[key: string]: boolean; } =
(control: AbstractControl): { [key: string]: boolean } => {
  const NIE_REGEX: RegExp = new RegExp(/^([XYZ]{1})(\d{7,8})([A-Z]{1})$/);
  let isValidLetter: any;
  if (NIE_REGEX.test(control.value)) {

    const nif: string = transformNIEintoNIF(control.value);
    const allNumbersRegex: RegExp = new RegExp(/\d+/);
    const letterRegex: RegExp = new RegExp(/[a-zA-Z]/);
    const letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E', 'T'];
    const nifNumber = (nif.match(allNumbersRegex) || [])[Numbers.number0] || '';
    const remainder = Number(nifNumber) % Numbers.number23;
    const nifLetter = (nif.match(letterRegex) || [])[Numbers.number0] || '';
    isValidLetter = letters[remainder].toLowerCase() === nifLetter.toLowerCase();
  }

  return isValidLetter ? null : { isInvalidNIE: true };
};

// transform NIE into equivalent NIF
const transformNIEintoNIF: (nie: string) => string = (nie: string): string => {
  const niePrefix: string = nie.charAt(Numbers.number0);
  const convertedValues: any = { X: Numbers.number0, Y: Numbers.number1, Z: Numbers.number2 };

  return convertedValues[niePrefix] + nie.substring(1);
};
