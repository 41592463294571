import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { StatusModalService } from '../../modals/status-modal/status-modal.service';
import { Numbers } from 'projects/portal-asegurados-front/src/app/utils/constants';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {
  @Output() uploadFile = new EventEmitter<any>();

  public fileSelectedName: string;
/**
 * @param statusModalServ statusModalServ
 */
  constructor(private statusModalServ: StatusModalService) {
    this.statusModalServ = statusModalServ;
  }

  /**
   * Drag over
   * @param evt drag event
   */
  @HostListener('dragover', ['$event'])
  onDragOver(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  /**
   * Drag action
   * @param event drop event
   */
  @HostListener('drop', ['$event'])
  onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.handleFileProperties(event.dataTransfer.files[Numbers.number0]);
  }
/**
 * @param event file selected
 */
  onFileSelected(event: any): void {
    this.handleFileProperties(event.target.files[Numbers.number0]);
  }
/**
 * @param file file
 */
  private handleFileProperties(file: File): void {
    const { name, size, type }: File = file;
    let displayErrorMessageParam = 'AVOID-FILE-TYPE';
    let updateFileParam: any;

    if (size > Numbers.number20000000) { displayErrorMessageParam = 'FILE-SIZE'; }

    if (this.isFileAcceptedByName(name) || this.isFileAcceptedByType(type)) { updateFileParam = file; }

    if (!updateFileParam) { this.statusModalServ.displayErrorMessage(displayErrorMessageParam, true); }

    this.updateFile(updateFileParam);

  }
/**
 * @param file file
 */
  private updateFile(file?: File): void {
    this.uploadFile.emit(file);
    if (file) {
      this.fileSelectedName = file.name;
    } else {
      this.fileSelectedName = '';
    }
  }
/**
 * @param type type
 * @returns return
 */
  private isFileAcceptedByType(type: any): boolean {
    const filesAcepted = ['application/pdf', 'image/jpeg', 'image/png'];
    return filesAcepted.includes(type);
  }
/**
 * @param name name
 * @returns return
 */
  private isFileAcceptedByName(name: string): boolean {
    const filesAcepted = ['pdf', 'doc', 'docx', 'png', 'jpeg', 'jpg', 'xlsx', 'xls', 'raw', 'heic'];

    const fileExtensionArray: string[] = name.split('.');

    const fileExtension: string = fileExtensionArray[fileExtensionArray.length - Numbers.number1];

    return filesAcepted.includes(fileExtension.toLowerCase());
  }
}
