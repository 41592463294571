import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FilterService } from 'projects/portal-asegurados-front/src/app/core/providers/local/filter/filter.service';

import { TableCol, TableDataSource } from '../../no-category/table/models/table';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss']
})
export class FilterMenuComponent implements OnInit, AfterViewInit {
  @ViewChild('filterFocus', { static: false }) filterFocus: ElementRef;

  //#region PROPS
  @Input() isFilterDisplayed: boolean;
  @Input() filterData: TableDataSource[];
  @Input() col: TableCol;

  @Output() closeFilter = new EventEmitter();
  @Output() filterSelected = new EventEmitter();
  //#endregion

  //#region STATE
  public displayData: any[] = [];
  public baseColData: any[] = [];

  public inputText = '';

  // Store elements selected from filter
  public elementsSelected: string[] = [];

  public colType: string;
  //#endregion
/**
 * @param filterServ filterServ
 */
  constructor(private filterServ: FilterService) {
    this.filterServ = filterServ;
  }
/**
 * on init
 */
  ngOnInit(): void {
    this.filterDataToDisplay();

    this.storeBaseData();

    this.setColType();

    this.checkElementsSelected();
  }
/**
 * filter data
 */
  private filterDataToDisplay(): void {
    // Set initial data of the list, depends on COL selected. Not repeated
    this.displayData = [...new Set(this.filterData.map((elem) => elem[this.col.field]))];
  }
/**
 * store base data
 */
  private storeBaseData(): void {
    // Store BASE DATA to manage it in case of reset filter (use to manage OnSearchData method)
    this.baseColData = [...this.displayData];
  }
/**
 * set column type
 */
  private setColType(): void {
    // Set colType to manage columns with different TableData
    this.colType = this.col.colType || null;
  }
/**
 * check element selected
 */
  private checkElementsSelected(): void {
    // Check selected elements on current filter
    this.elementsSelected = this.filterServ.getDataSelectedFromCol(this.col.field);
  }
/**
 * after view init
 */
  ngAfterViewInit(): void {
    this.filterFocus.nativeElement.focus();
  }
/**
 * @param inputData input data
 */
  onSearchData(inputData: string): void {
    const upperInputData: string = inputData.toLowerCase();

    this.displayData = this.baseColData.filter((elem: string) => elem.toLowerCase().toString().includes(upperInputData));
  }
/**
 * on close
 */
  onClose(): void {
    this.closeFilter.emit(null);
  }
/**
 * @param state state
 */
  onFilter(state: string | string[]): void {
    const filterServ: FilterService = this.filterServ;
    let dataToEmit: TableDataSource[];

    if (state === 'reset' || state.length === 0) {
      filterServ.deleteCol(this.col.field);
      dataToEmit = filterServ.getDataFiltered();
    } else {
      filterServ.addFilter(this.col.field, state as string[]);
      dataToEmit = filterServ.getDataFiltered(this.filterData);
    }

    this.closeFilter.emit(state);
    this.filterSelected.emit(dataToEmit);
  }
/**
 * @param event event
 */
  onRestartTabNavigation(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      this.focusElement(this.filterFocus);
    } else if (event.key === ' ' || event.key === 'Enter') {
      this.onClose();
    } else {}
  }
/**
 * @param elem element
 */
  private focusElement(elem: ElementRef): void {
    elem.nativeElement.focus();
  }

  /**
   * trackByFn
   * @param item item
   * @returns item id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
