import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

export interface StatusModal {
  display: boolean;
  isSuccess: boolean;
  translateText?: string;
  displayedText?: string;
  variableText?: string;
}

@Injectable({
  providedIn: 'root'
})
export class StatusModalService {
  public subject: Subject<StatusModal> = new Subject<StatusModal>();
  public statusModalState: Observable<StatusModal> = this.subject.asObservable();
/**
 * @param translateService translateService
 */
  constructor(private translateService: TranslateService) {
    this.translateService = translateService;
  }
/**
 * @param text text
 * @param isFromTranslator translatos
 * @param variableText variable text
 */
  displaySuccessMessage(text?: string, isFromTranslator?: boolean, variableText?: any): void {
    const displayedText: string = this.getDisplayText(text, isFromTranslator, true, variableText);

    this.subject.next({
      display: true,
      isSuccess: true,
      displayedText
    });
  }
/**
 * @param text text
 * @param isFromTranslator translatos
 * @param variableText variable text
 */
  displayErrorMessage(text?: string, isFromTranslator?: boolean, variableText?: any): void {
    const displayedText: string = this.getDisplayText(text, isFromTranslator, false, variableText);

    this.subject.next({
      display: true,
      isSuccess: false,
      displayedText
    });
  }
/**
 * @param text text
 * @param isFromTranslator is from translator
 * @param isSuccess is success
 * @param variableText varriable text
 * @returns return
 */
  private getDisplayText(text: string, isFromTranslator: boolean, isSuccess: boolean, variableText: any): string {
    const variableSuffix: any = variableText ? variableText : '';
    let messageToDisplay: string = text;

    if (!text) {
      const statusModalMessage: string = isSuccess ? 'STATUS-MODAL.RES' : 'STATUS-MODAL.REJ';
      messageToDisplay = this.translateService.instant(statusModalMessage);
    }

    if (isFromTranslator && text) {
      const statusModalMessagePrefix: string = isSuccess ? 'STATUS-MODAL.SUCCESS.' : 'STATUS-MODAL.ERROR.';
      messageToDisplay = this.translateService.instant(statusModalMessagePrefix + text);
    }

    return messageToDisplay + variableSuffix;
  }
/**
 * turn of status display
 */
  public turnOffStatusDisplay(): void {
    this.subject.next();
  }
}
