import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';
import { BootService } from '../providers/local/boot/boot.service';
import { Numbers } from '../../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenInterceptor implements HttpInterceptor {
  /**
   * @param http http
   * @param bootService boot service
   */
  constructor(public http: HttpClient, private bootService: BootService) {
    this.http = http;
    this.bootService = bootService;
  }
/**
 * @param req req
 * @param next next
 * @returns return
 */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url: string = req.url;
    let result: any = next.handle(req);

    if (url.includes('/identidad-digital') || url.includes('/isos/datos-comunes')) {
      result = next.handle(req).pipe(
        catchError((err: HttpErrorResponse) => {
          const foo: any = this.manageErrorRequest(err, req, next);
          return err.url.includes('/identidad-digital')
            ? foo(this.bootService.getTokenRequest('identidad'), 'X-TOKEN-IdentidadDigital')
            : foo(this.bootService.getTokenRequest('comunes'), 'X-TOKEN-DatosComunes');
        })
      );
    }

    return result;
  }
/**
 * @param err err
 * @param req req
 * @param next next
 * @returns return
 */
private manageErrorRequest(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): (tokenObservable: Observable<any>, localStorageId: string) => Observable<HttpEvent<any>> {
  return (tokenObservable: Observable<any>, localStorageId: string) => {
      if (err instanceof HttpErrorResponse && (err.status === Numbers.number401 || err.status === Numbers.number403)) {
        return tokenObservable.pipe(
          flatMap((result) => {
            localStorage.setItem(localStorageId, result.access_token);
            if (result.access_token) {
              req = req.clone({
                setHeaders: {
                  authorization: `Bearer ${result.access_token}`
                }
              });
            }
            return next.handle(req);
          })
        );
      }
      throw err;
    };
  }
}
