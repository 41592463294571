import { NgModule } from '@angular/core';
/**
 * import
 */
import { RegistrationComponent } from './registration.component';
import { RegistrationRoutingModule } from './registration-routing.module';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [ RegistrationComponent ],
  imports: [
    SharedModule,
    RegistrationRoutingModule
  ]
})
/**
 * RegistrationModule
 */
export class RegistrationModule {
}
