import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-backdrop-nav',
  templateUrl: './backdrop-nav.component.html',
  styleUrls: [ './backdrop-nav.component.scss' ]
})
export class BackdropNavComponent {
  @Output() clickEvent = new EventEmitter<any>();
  @Output() keyDownEvent = new EventEmitter<any>();
/**
 * on click
 */
  onClick(): void {
    this.clickEvent.emit();
  }
/**
 * @param event event
 */
  onKeyDown(event: KeyboardEvent): void {
    this.keyDownEvent.emit(event);
  }
}
