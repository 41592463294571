import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { CertificadoActualizarEntrada } from '../../../models/api/asegurados/CertificadoActualizarEntrada';
import { CertificadoDetalleSalida } from '../../../models/api/asegurados/CertificadoDetalleSalida';
import { ConsentimientosActualizar } from '../../../models/api/asegurados/ConsentimientosActualizar';
import { ConsentimientosSalida } from '../../../models/api/asegurados/ConsentimientosSalida';
import { DevolverAvances } from '../../../models/api/asegurados/DevolverAvances';
import { DireccionEnvio } from '../../../models/api/asegurados/DireccionEnvio';
import { ExpedienteDetalleSalida } from '../../../models/api/asegurados/ExpedienteDetalleSalida';
import { ExpedientesSalida } from '../../../models/api/asegurados/ExpedientesSalida';
import { ActualizarConsulta } from '../../../models/api/datos-comunes/ActualizarConsulta';
import { DevolverMensaje } from '../../../models/api/datos-comunes/DevolverMensaje';
import { IdentidadActualizar } from '../../../models/api/datos-comunes/IdentidadActualizar';
import { Identidades } from '../../../models/api/datos-comunes/Identidades';
import { IdentidadesSalida } from '../../../models/api/datos-comunes/IdentidadesSalida';
import { InsertarConsulta } from '../../../models/api/datos-comunes/InsertarConsulta';
import { MaestroCertFiltrado } from '../../../models/api/datos-comunes/MaestroCertFiltrado';
import { MaestroEstados } from '../../../models/api/datos-comunes/MaestroEstados';
import { MaestroEstadosCertificados } from '../../../models/api/datos-comunes/MaestroEstadosCertificados';
import { ResActualizarConsulta } from '../../../models/api/datos-comunes/ResActualizarConsulta';
import { ResConsultasAsegurado } from '../../../models/api/datos-comunes/ResConsultasAsegurado';
import { ResInsertarConsulta } from '../../../models/api/datos-comunes/ResInsertarConsulta';
import { ResListadoMisConsultas } from '../../../models/api/datos-comunes/ResListadoMisConsultas';
import { ResMaestros } from '../../../models/api/datos-comunes/ResMaestros';
import { ResMaestrosCita } from '../../../models/api/datos-comunes/ResMaestrosCita';
import { ResMisConsultas } from '../../../models/api/datos-comunes/ResMisConsultas';
import { RespuestaServiciosFacturados } from '../../../models/api/datos-comunes/RespuestaServiciosFacturados';
import { ResultadoMaestros } from '../../../models/api/datos-comunes/ResultadoMaestros';
import { TiposIncidencias } from '../../../models/api/datos-comunes/TiposIncidencias';
import { AseguradosService } from '../../api/asegurados/asegurados.service';
import { IdentidadDigitalSOSService } from '../../api/datos-comunes/identidadDigitalSOS.service';
import { MaestrosService } from '../../api/datos-comunes/maestros.service';
import { MisConsultasService } from '../../api/datos-comunes/misConsultas.service';
import { Utils } from 'projects/portal-asegurados-front/src/app/shared/utils/utils';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusModalService } from 'projects/portal-asegurados-front/src/app/shared/components/modals/status-modal/status-modal.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManageRequestSosService {
  /**
   * @param err error
   * @returns return
   */
  public handleBaseErrorFromServer(err: HttpErrorResponse): Observable<HttpErrorResponse> {
    Utils.error.displayServerErrorInConsole(err);

    this.statusModalServ.displayErrorMessage(err.error.mensaje);

    return throwError(err);
  }
/**
 * @param httpRequest peticion http
 * @param sessionStorageCode almacenamiento en sesion
 * @returns return
 */
  private cacheRequestResponse(httpRequest: Observable<any>, sessionStorageCode: string): Observable<any | HttpErrorResponse> {
    {
      return sessionStorage.getItem(sessionStorageCode)
        ? of(JSON.parse(sessionStorage.getItem(sessionStorageCode)))
        : httpRequest.pipe(
            tap((res) => {
              sessionStorage.setItem(sessionStorageCode, JSON.stringify(res));
            }),
            catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err))
          );
    }
  }
  /**
   * @param maestrosServ Serv
   * @param identDigSOSServ Serv
   * @param misConsultasServ Serv
   * @param asegServ Serv
   * @param statusModalServ Serv
   */
  constructor(
    private maestrosServ: MaestrosService,
    private identDigSOSServ: IdentidadDigitalSOSService,
    private misConsultasServ: MisConsultasService,
    private asegServ: AseguradosService,
    private statusModalServ: StatusModalService
  ) {
    this.maestrosServ = maestrosServ;
    this.identDigSOSServ = identDigSOSServ;
    this.misConsultasServ = misConsultasServ;
    this.asegServ = asegServ;
    this.statusModalServ = statusModalServ;
  }

  // <<<<----- ASEGURADOS ----->>>>>

  //#region *** ASEGURADOS ***
  /**
   * @description (AS1) Consulta de los expedientes de un asegurado
   * @param idAsegurado id asegurado
   * @param idioma idiomaidioma
   * @param filtro filtro
   * @returns return
   */
  asegConsultarExpedientes$(idAsegurado: number, idioma: string, filtro: number): Observable<ExpedientesSalida | HttpErrorResponse> {
    return this.asegServ.aseguradosConsultarExpedientes(idAsegurado, idioma, filtro).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (AS2) Devuelve el detalle del expediente consultado
   * @param identificador id identificador
   * @param idioma idiomaidioma
   * @returns return
   */
  asegDevolverDetalleExpediente$(identificador: string, idioma: string): Observable<ExpedienteDetalleSalida | HttpErrorResponse> {
    return this.asegServ.aseguradosConsultarDetalle(identificador, idioma).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (AS3) Devuelve los consentimientos de un asegurado
   * @param identificador id
   * @param idioma idioma
   * @returns return
   */
  asegDevolverConsentimiento$(identificador: string, idioma: string): Observable<ConsentimientosSalida | HttpErrorResponse> {
    return this.asegServ.aseguradosConsultarConsentimientos(identificador, idioma).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (AS4) Actualiza los consentimientos de un asegurado
   * @param identificador id
   * @param consentimientos consentimientos
   * @returns return
   */
  asegActualizarConsentimiento$(identificador: string, consentimientos: Array<ConsentimientosActualizar>): Observable<DevolverAvances | HttpErrorResponse> {
    return this.asegServ.aseguradosActualizarConsentimientos(identificador, consentimientos).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (AS5) Devuelve el certificado del expediente consultado
   * @param identificador id
   * @param indiceLista indice
   * @param idioma idioma
   * @returns return
   */
  asegDevolverCertificado$(identificador: string, indiceLista: string, idioma: string): Observable<CertificadoDetalleSalida | HttpErrorResponse> {
    return this.asegServ.aseguradosConsultarCertificado(identificador, indiceLista, idioma).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (AS6) Crea un fuera de cobertura
   * @param identificador id
   * @returns return
   */
  asegCrearFueraCobertura$(identificador: string): Observable<any> {
    return this.asegServ.aseguradosCrearfueraCobertura(identificador).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (AS7) Actualiza una dirección de envío
   * @param identificador id
   * @param direccionEnvio direccion
   * @returns return
   */
  asegActualizarDireccionEnvio$(identificador: string, direccionEnvio: DireccionEnvio): Observable<DevolverAvances | HttpErrorResponse> {
    return this.asegServ.aseguradosActualizarDireccionEnvio(direccionEnvio, identificador).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (AS8) Actualiza los datos de una incidencia
   * @param identificador id
   * @param indiceLista indice
   * @param datosActualizar datos a actualizar
   * @returns return
   */
  asegActualizarDatosIncidencia$(identificador: string, indiceLista: string, datosActualizar: CertificadoActualizarEntrada): Observable<DevolverAvances | HttpErrorResponse> {
    return this.asegServ.aseguradosActualizarDatosIncidencia(identificador, indiceLista, datosActualizar).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (AS9) Recuperar la lista de documentos fijos del beneficiario
   * @param lista list
   * @param observe observable
   * @param reportProgress report
   * @returns return
   */
  asegRecuperarListadoDocumentacion(lista: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string> | HttpErrorResponse> {
    return this.asegServ.aseguradosRecuperarListadoDocumentacion(lista, observe, reportProgress).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  //#endregion

  // <<<<----- COMUNES ----->>>>>
  //#region  *** MAESTROS ***
  /**
   * @description (M1) Muestra los tipos de incidencias
   * @returns return
   */
  maestrosMostrarTiposIncidencias$(): Observable<Array<TiposIncidencias>> {
    return this.cacheRequestResponse(this.maestrosServ.maestrosConsultarTiposIncidencias(), 'tiposIncidencias');
  }
  /**
   * @description (M2) Muestra los tipos de estados
   * @returns return
   */
  maestrosMostrarEstados$(): Observable<Array<MaestroEstados>> {
    return this.cacheRequestResponse(this.maestrosServ.maestrosConsultarEstados(), 'estados');
  }
  /**
   * @description (M3) Muestra los certificados
   * @returns return
   */
  maestrosMostrarCertificados$(): Observable<MaestroCertFiltrado> {
    return this.cacheRequestResponse(this.maestrosServ.maestrosConsultarCertificadosFiltrados(), 'certificados');
  }
  /**
   * @description (M4) Muestra los servicios facturados
   * @returns return
   */
  maestrosMostrarServiciosFacturados$(): Observable<RespuestaServiciosFacturados> {
    return this.cacheRequestResponse(this.maestrosServ.maestrosConsultarServiciosFacturados(), 'serviciosFacturados');
  }
  /**
   * @description (M5) Muestra los resultados de la cita
   * @returns return
   */
  maestrosMostrarResultadosCita$(): Observable<Array<ResMaestrosCita>> {
    return this.cacheRequestResponse(this.maestrosServ.maestrosConsultarResultadoCita(), 'resultadosCita');
  }
  /**
   * @description (M6) Muestra los motivos de cancelación
   * @returns return
   */
  maestrosMostrarMotivosCancelacion$(): Observable<ResultadoMaestros> {
    return this.cacheRequestResponse(this.maestrosServ.maestrosConsultarMotivosCancelacion(), 'motivosCancelacion');
  }
  /**
   * @description (M7) Muestra los estados civiles
   * @returns return
   */
  maestrosMostrarEstadosCiviles$(): Observable<ResMaestros> {
    return this.cacheRequestResponse(this.maestrosServ.maestrosEstadoCivil(), 'estadosCiviles');
  }
  /**
   * @description (M8) Muestra los tipos de personas
   * @returns return
   */
  maestrosMostrarTiposPersonas$(): Observable<ResMaestros> {
    return this.cacheRequestResponse(this.maestrosServ.maestrosTipoPersona(), 'tiposPersonas');
  }
  /**
   * @description (M9) Muestra las relaciones
   * @returns return
   */
  maestrosMostrarRelaciones$(): Observable<ResMaestros> {
    return this.cacheRequestResponse(this.maestrosServ.maestrosRelacion(), 'mostrarRelaciones');
  }
  /**
   * @description (M10) Muestra los estados de los certificados por idioma
   * @param idioma idioma
   * @returns return
   */
  maestrosMostrarEstadosCertificados$(idioma: string): Observable<Array<MaestroEstadosCertificados> | HttpErrorResponse> {
    return this.maestrosServ.maestrosConsultarEstadosCertificados(idioma).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (M11) Muestra los tipos de estados de consulta por idioma
   * @param idioma idioma
   * @returns return
   */
  maestrosMostrarTiposEstadosConsultas$(idioma: string): Observable<Array<MaestroEstadosCertificados> | HttpErrorResponse> {
    return this.maestrosServ.maestrosConsultarEstadosConsulta(idioma).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (M12) Muestra las relaciones de fallecidos
   * @returns return
   */
  maestrosMostrarRelacionesFallecidos$(): Observable<ResMaestros | HttpErrorResponse> {
    return this.cacheRequestResponse(this.maestrosServ.maestrosRelacionFallecido(), 'relacionesFallecidos');
  }
  /**
   * @description (M13) Muestra las provincias
   * @returns return
   */
  maestrosMostrarProvincias$(): Observable<ResMaestros | HttpErrorResponse> {
    return this.cacheRequestResponse(
      this.maestrosServ.maestrosProvincias().pipe(
        map((res: any) => {
          return res.sort((a, b) => {
            const desA: any = a.Description;
            const desB: any = b.Description;
            return desA.localeCompare(desB);
          });
        })
      ),
      'provincias'
    );
  }
  /**
   * @description (M14) Muestra las localidades por provincia
   * @param provincia provincia
   * @returns return
   */
  maestrosMostrarLocalidades$(provincia: string): Observable<ResMaestros | HttpErrorResponse> {
    return this.maestrosServ.maestrosLocalidades(provincia).pipe(
      map((res: any) => {
        return res.sort((a, b) => {
          const desA: any = a.Description;
          const desB: any = b.Description;
          return desA.localeCompare(desB);
        });
      }),
      catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err))
    );
  }
  /**
   * @description (M15) Muestra los países
   * @returns return
   */
  maestrosMostrarPaises$(): Observable<ResMaestros | HttpErrorResponse> {
    return this.cacheRequestResponse(
      this.maestrosServ.maestrosPaises().pipe(
        map((res: any) => {
          return res.sort((a, b) => {
            const desA: any = a.Description;
            const desB: any = b.Description;
            return desA.localeCompare(desB);
          });
        })
      ),
      'paises'
    );
  }

  //#endregion

  //#region *** IDENTIDAD DIGITAL SOS***
  /**
   * @description (ISOS1) Devuelve los datos de un usuario
   * @param hash hash
   * @returns return
   */
  identDigSOSDevolverDatosUsuario$(hash: string): Observable<Identidades | HttpErrorResponse> {
    return this.identDigSOSServ.identidadDigitalSOSRecuperarIdentidades('0', hash).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (ISOS2) Actualiza un usuario en el sistema
   * @param identificacion identificador
   * @param identidad id
   * @returns return
   */
  identDigSOSActualizarUsuario$(identificacion: string, identidad: IdentidadActualizar): Observable<DevolverMensaje | HttpErrorResponse> {
    return this.identDigSOSServ.identidadDigitalSOSActualizarIdentidades(identificacion, identidad).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (ISOS3) Creación de un usuario en el sistema
   * @param identidad id
   * @param identificacion identificador
   * @returns return
   */
  identDigSOSCrearUsuario$(identidad: Identidades, identificacion: string): Observable<IdentidadesSalida | HttpErrorResponse> {
    return this.identDigSOSServ.identidadDigitalSOSCrearIdentidades(identidad, identificacion).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (ISOS4) Eliminación de un usuario en el sistema
   * @param identificacion id
   * @param tipoDocumento tipo de documento
   * @returns return
   */
  identDigSOSEliminarUsuario$(identificacion: string, tipoDocumento: string): Observable<IdentidadesSalida | HttpErrorResponse> {
    return this.identDigSOSServ.identidadDigitalSOSEliminarIdentidades(identificacion, tipoDocumento).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  //#endregion

  //#region *** MIS CONSULTAS ***
  /**
   * @description (Q1) Insertar una consulta
   * @param consulta consulta
   * @returns return
   */
  misConsultasInsertarConsulta$(consulta: InsertarConsulta): Observable<ResInsertarConsulta | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasInsertarConsulta(consulta).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (Q2) Devuelve una consulta determinada
   * @param idConsulta  id consulta
   * @returns return
   */
  misConsultasRecuperarConsulta$(idConsulta: string): Observable<ResMisConsultas | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasRecuperarConsulta(idConsulta).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (Q3) Actualiza una consulta determinada
   * @param idConsulta  id consulta
   * @param consulta consulta
   * @returns return
   */
  misConsultasActualizarConsulta$(idConsulta: string, consulta: ActualizarConsulta): Observable<ResActualizarConsulta | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasActualizarConsulta(idConsulta, consulta).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (Q4) Devuelve un listado de consultas de un caso
   * @param identificador id
   * @returns return
   */
  misConsultasRecuperarListadoConsulta$(identificador: string): Observable<ResListadoMisConsultas | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasRecuperarListadoConsulta(identificador).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  /**
   * @description (Q5) Devuelve una consulta por asegurado
   * @param idAsegurado id asegurado
   * @returns return
   */
  misConsultasRecuperarConsultaAsegurado$(idAsegurado: string): Observable<ResConsultasAsegurado | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasRecuperarConsultaAsegurado(idAsegurado).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  //#endregion
  //#endregion
}
