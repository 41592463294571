import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserService } from './providers/local/user/user.service';
import { AuthService } from './providers/local/auth/auth.service';
import { BootService } from './providers/local/boot/boot.service';
import { ApiModule } from './providers/api/api.module';
import { AppConfig } from './providers/local/boot/models/boot.model';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { AuthGuard } from './guards/auth-guard/auth.guard';
import { NavigatorsCheckGuard } from './guards/navigators-check-guard/navigators-check.guard';
import { ErrorsHandler } from './errors/errors.handle';
import { DEFAULT_CONFIG, Driver, NgForageOptions } from 'ngforage';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Angulartics2Module } from 'angulartics2';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { HeaderInterceptor } from './interceptors/manage-headers.interceptor';
import { RefreshTokenInterceptor } from './interceptors/refresh-token.interceptor';
import { KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { Utils } from '../shared/utils/utils';
import { ErrorsInterceptor } from './interceptors/errors.interceptor';
import { KeycloakUserData } from './models/local/userdata.model';
import { Numbers } from '../utils/constants';

export const ngfRootOptions: NgForageOptions = {
  name: 'next-storage',
  driver: [Driver.INDEXED_DB, Driver.WEB_SQL, Driver.LOCAL_STORAGE]
};

/**
 * setupBootstrapFactory
 * @param boot boot service
 * @returns return
 */
export const setupBootstrapFactory: (boot: BootService) => () => Promise<any> = (boot: BootService): () => Promise<any> => {
  return () => {
    const promise: Promise<void> = boot.init();
    return promise;
  };
};

/**
 * HttpLoaderFactory
 * @param http http
 * @returns return
 */
export const HttpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(http);
};

/**
 * @description setupTranslateFactory
 * @param service service
 * @returns return
 */
export const setupTranslateFactory: (service: TranslateService) => () => Observable<any> = (service: TranslateService): () => Observable<any> => {
  return () => service.use('es');
};

/**
 * Inicio de servicio de Keycloak
 * @param keycloak keycloak
 * @param authService auth
 * @param bootService boot service
 * @returns return
 */
export const initializeKeycloak: (keycloak: KeycloakService, authService: AuthService, bootService: BootService) => () => Promise<void> =
(keycloak: KeycloakService, authService: AuthService, bootService: BootService): () => Promise<void> => {
  return async () => {
    let appConfig: AppConfig;

    await bootService.loadAndStoreAppConfig().then((res: AppConfig) => (appConfig = res));

    // console.log(`%c Environment: ${appConfig.environment}, version: ${version}`, 'background: lightblue; color: black; font-weight: 700');

    return new Promise<void>((resolve): void => {
      keycloak
        .init({
          config: {
            url: appConfig.configKeycloak.url,
            realm: appConfig.configKeycloak.realm,
            clientId: appConfig.configKeycloak.clientId
          },
          initOptions: {
            pkceMethod: 'S256',
            onLoad: window.location.href.indexOf('registration') === -1 ? 'login-required' : null,
            checkLoginIframe: false, // Needed for iOS
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            redirectUri: Boolean(sessionStorage.getItem('login')) ? window.location.href : appConfig.loginUrl // Fix problem on refresh app during navigation
          },
          bearerPrefix: 'Bearer',
          enableBearerInterceptor: true,
          bearerExcludedUrls: ['/isos/datos-comunes', '/identidad-digital']
        })
        .then(async () => {
          const userData: any = await keycloak.loadUserProfile();

          displayUserData(appConfig, userData);

          storeUserData(userData, authService);

          localStorage.setItem('COMPANY-ID', authService.getUserParam('userCompany'));
          sessionStorage.setItem('login', 'true');

          // Change base color depends on company (red: santander, blue: isos)
          Utils.lookFeel.changeLookAndFeel(authService.getUserParam('userCompany'));

          sessionStorage.setItem('USER-ID', authService.getUserParam('userIdRequestISOS'));
          sessionStorage.setItem('USER-COMPANY', authService.getUserParam('userCompany'));
          sessionStorage.setItem('LANG', 'ES');

          resolve();
        })
        .catch((err) => {
          if (window.location.href.includes('registration')) {
            resolve();
          }
        });

      const keycloakAuth: Keycloak.KeycloakInstance = keycloak.getKeycloakInstance();

      keycloakAuth.onTokenExpired = () => {
        if (keycloakAuth.refreshToken) {
          keycloakAuth.updateToken(Numbers.number5);
        } else {
          keycloakAuth.login();
        }
      };
    });
  };
};

export const services: any[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: setupTranslateFactory,
    deps: [TranslateService],
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: setupBootstrapFactory,
    deps: [BootService],
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [KeycloakService, AuthService, BootService]
  },
  BootService,
  UserService,
  AuthService,
  ErrorsHandler,
  KeycloakService,
  {
    provide: ErrorHandler,
    useClass: ErrorsHandler
  },
  {
    provide: DEFAULT_CONFIG,
    useValue: ngfRootOptions
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: KeycloakBearerInterceptor,
    multi: true
  }
];

const interceptors: any[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorsInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HeaderInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true
  }
];

export const guards: any[] = [AuthGuard, NavigatorsCheckGuard];

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    DeviceDetectorModule.forRoot(),
    Angulartics2Module.forRoot(),
    ApiModule
  ],
  providers: [...interceptors, ...services, ...guards]
})
export class CoreModule {
  /**
   * @param parentModule module parent
   */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }
  }
}

/**
 * Guardar usuario de KeyCloak
 * @param userData user data
 * @param authService auth
 */
export const storeUserData: (userData: KeycloakUserData, authService: any) => void = (userData: KeycloakUserData, authService: any): void => {
  authService.setUserData({
    userName: userData.firstName || '',
    userSurname: userData.lastName || '',
    userMail: userData.email || '',
    userDocId: userData.attributes.identificationNumber[Numbers.number0] || '',
    userIdRequestISOS: userData.attributes.LDAP_ENTRY_DN[Numbers.number0].match(new RegExp('gslUserId=' + '(.*)' + ',ou=insureds'))[1] || '',
    userIdRequestIdentDig: userData.attributes.LDAP_ENTRY_DN[Numbers.number0].match(new RegExp('gslUserId=' + '(.*)' + ',ou=insureds'))[1] || '',
    userCompany: userData.attributes.company[Numbers.number0] || '',
    lang: 'ES' || '',
    userType: userData.attributes.userType[Numbers.number0] || ''
  });
};

/**
 * Informacion por consola del usuario de Keycloak
 * @param appConfig app config
 * @param userData user data
 */
export const displayUserData: (appConfig: any, userData: KeycloakUserData) => void = (appConfig: any, userData: KeycloakUserData): void => {
  if (appConfig.environment.includes('DEV') || appConfig.environment.includes('TST')) {
    console.groupCollapsed('%c USER DATA ', 'color: white; background: darkgreen');
    console.table({ userData });
    console.groupEnd();
  }
};
