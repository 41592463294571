import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ManageRequestSosService } from 'projects/portal-asegurados-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service';
import { ManageRequestServiceExtended } from 'projects/portal-asegurados-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service.utils-extended';
import { AuthService } from 'projects/portal-asegurados-front/src/app/core/providers/local/auth/auth.service';
import { IdentidadDigitalConsulta } from 'projects/portal-asegurados-front/src/app/core/models/api/identidad-digital/IdentidadDigitalConsulta';
import { StatusModalService } from '../../modals/status-modal/status-modal.service';
import { IdentidadDigitalParcheo } from 'projects/portal-asegurados-front/src/app/core/models/api/identidad-digital/IdentidadDigitalParcheo';
import { IdentidadDigitalModificacion } from 'projects/portal-asegurados-front/src/app/core/models/api/identidad-digital/IdentidadDigitalModificacion';
import { Utils } from '../../../utils/utils';
import { PersonalUserData, UserData, UserType, USER_DATA_DOCUMENT_TYPE_OPTIONS, USER_DATA_MAP_USER_DATA } from 'projects/portal-asegurados-front/src/app/core/models/local/userdata.model';
import { IdentidadActualizar } from 'projects/portal-asegurados-front/src/app/core/models/api/datos-comunes/IdentidadActualizar';
import { validCIFValidator, validNIEValidator, validNIFValidator } from '../../../validators/document-validator';
import { DevolverMensaje } from 'projects/portal-asegurados-front/src/app/core/models/api/datos-comunes/DevolverMensaje';
import { Numbers } from 'projects/portal-asegurados-front/src/app/utils/constants';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {
  //#region PROPS
  @ViewChild('userMenuFocus', { static: true }) userMenuFocus: ElementRef;
  @Input() fullscreen = false;
  //#endregion

  //#region STATE
  private mapUserData: any = USER_DATA_MAP_USER_DATA;
  public personalDataList: any = USER_DATA_DOCUMENT_TYPE_OPTIONS;
  // public options: string[] = ['DNI', 'NIF', 'CIF'];

  public userDisplayData: UserData;
  private userServerData: IdentidadDigitalConsulta;

  public displayMenu: boolean;
  public displayData = false;
  public displayPasw = false;

  public personalDataForm: FormGroup = new FormGroup({
    user: new FormControl({ value: '', disabled: true }, Validators.required),
    name: new FormControl('', [Validators.required, Validators.maxLength(Numbers.number50)]),
    surname1: new FormControl('', [Validators.required, Validators.maxLength(Numbers.number50)]),
    surname2: new FormControl('', [Validators.required, Validators.maxLength(Numbers.number50)]),
    docType: new FormControl('', Validators.required),
    id: new FormControl('')
  });

  // * Manage visualization of surname1 and surname2
  public documentType = 'NIF';

  public error = '';
  public errorpasw = '';
/**
 * @param manageReqSOS manageReqSOS
 * @param manageReqSOSExtended manageReqSOSExtended
 * @param authServ authServ
 * @param statusModal statusModal
 */
  constructor(private manageReqSOS: ManageRequestSosService, private manageReqSOSExtended: ManageRequestServiceExtended,
              private authServ: AuthService, private statusModal: StatusModalService, private renderer: Renderer2) {
                this.manageReqSOS = manageReqSOS;
                this.manageReqSOSExtended = manageReqSOSExtended;
                this.authServ = authServ;
                this.statusModal = statusModal;
                this.renderer = renderer;
              }
/**
 * on init
 */
  ngOnInit(): void {
    // this.checkLanguaje();
    // this.translateDropdown();

    this.setInitalUserDataFromKeycloak();
  }
/**
 * on destroy
 */
  ngOnDestroy(): void {
    this.statusModal.turnOffStatusDisplay();
  }
/**
 * set initial user data
 */
  public setInitalUserDataFromKeycloak(): void {
    this.userDisplayData = {
      name: this.authServ.getUserParam('userName') || 'No identificado',
      profile: UserType[this.authServ.getUserParam('userType')] || '-',
      initials: `${this.authServ.getUserParam('userName')[0]}` +
                `${this.authServ.getUserParam('userSurname')[0]}` || '',
      color: 'blue'
    };
  }
/**
 * @param identidad identity
 */
  public getAndStoreUserDataFromSL(identidad: string): void {
    this.manageReqSOSExtended.identDigSLConsultarIdentidadDigital$(identidad, 'A').subscribe(
      (userRes: IdentidadDigitalConsulta) => {
        this.changeIdNumberValidatorAndCheck(userRes.atributos['isos.tipoDocumentoIdentidad']);

        this.userDisplayData = {
          name: userRes.nombre || 'No identificado',
          profile: UserType[userRes.atributos['isos.tipoUsuario']] || '-',
          initials: `${userRes.nombre[0]}` +
                    `${userRes.primerApellido[0]}` || '',
          color: 'blue'
        };

        this.userServerData = userRes;

        this.setInitialFormValues(userRes);
      },
      (err: HttpErrorResponse) => {
        this.statusModal.displayErrorMessage('NO-USER-DATA', true);
        this.userDisplayData = {
          name: 'Username',
          profile: 'No identificado',
          initials: `U`,
          color: 'blue'
        };
      }
    );
  }
  /**
   * Fill form with user data from param userData
   * @param userRes user res
   */
  private setInitialFormValues(userRes: IdentidadDigitalConsulta): void {
    // Transform user data to stay at same level on object
    const transformedData: any = Utils.manageServerData.simplifyUserDataSL(userRes);

    for (const data in transformedData) {
      if (transformedData.hasOwnProperty(data) && this.personalDataForm.get(this.mapUserData[data])) {
        const userElement: AbstractControl = this.personalDataForm.get(this.mapUserData[data]);
        userElement.setValue(transformedData[data]);
      }
    }
  }
/**
 * display menu
 */
  public displayMenuAndGetUserData(): void {
    this.getAndStoreUserDataFromSL(this.authServ.getUserParam('userIdRequestIdentDig'));
  }
/**
 * on change pass
 */
  public onChangePassLDAP(): void {
    const identidad: string = this.authServ.getUserParam('userIdRequestIdentDig');

    this.manageReqSOSExtended.credentResetearPassSL$(identidad, 'A').subscribe((res: any) => {
    });
  }
/**
 * @param event event
 * @param focusId focus id
 */
  public onRestartTabNavigation(event: KeyboardEvent, focusId: string): void {
    if (event.key === 'Tab') {
      this.focusElement(this[focusId]);
    } else if (event.key === ' ' || event.key === 'Enter') {
      this.displayMenu = !this.displayMenu;
    } else {}
  }
/**
 * toggle personal data
 */
  public togglePersonalData(): void {
    if (this.displayData === false) {
      this.getAndStoreUserDataFromSL(this.authServ.getUserParam('userIdRequestIdentDig'));
    }
    this.displayPasw = this.displayPasw ? false : this.displayPasw;
    if (this.fullscreen) {
      // this.displayData = this.displayData ? this.displayData : !this.displayData;
      this.displayData = !this.displayData;
    } else {
      this.displayData = !this.displayData;
    }
  }
/**
 * @param event event
 */
  onChangeDrop(event: { originalEvent: any; value: string }): void {
    // ** Change document type to manage visualization of user info
    this.documentType = event.value;

    this.changeIdNumberValidatorAndCheck(event.value);

    Utils.screenReader.speak(`El tipo de documento seleccionado es ${event.value}`, this.renderer);
  }
/**
 * @param documentType document type
 */
  private changeIdNumberValidatorAndCheck(documentType: string): void {
    this.documentType = documentType;
    const idNumberInput: AbstractControl = this.personalDataForm.get('id');

    idNumberInput.enable();

    if (documentType.toLowerCase() === 'nif' || documentType.toLowerCase() === 'dni') {
      idNumberInput.setValidators([Validators.pattern(Utils.validatorGen.getValidatorFromType(documentType)), Validators.maxLength(Numbers.number10), Validators.required, validNIFValidator]);
    } else if (documentType.toLowerCase() === 'cif') {
      idNumberInput.setValidators([Validators.pattern(Utils.validatorGen.getValidatorFromType(documentType)), Validators.maxLength(Numbers.number10), Validators.required, validCIFValidator]);
    } else if (documentType.toLowerCase() === 'nie') {
      idNumberInput.setValidators([Validators.pattern(Utils.validatorGen.getValidatorFromType(documentType)), Validators.maxLength(Numbers.number10), Validators.required, validNIEValidator]);
    } else {
      idNumberInput.setValidators([Validators.pattern(Utils.validatorGen.getValidatorFromType(documentType)), Validators.maxLength(Numbers.number10), Validators.required]);
    }

    idNumberInput.updateValueAndValidity();
    idNumberInput.markAsTouched();

    Utils.screenReader.speak(`El tipo de documento seleccionado es ${documentType}`, this.renderer);
  }
/**
 * toggle pasw
 */
  public togglePasw(): void {
    this.displayData = this.displayData ? false : this.displayData;
    if (this.fullscreen) {
      // this.displayPasw = this.displayPasw ? this.displayPasw : !this.displayPasw;
      this.displayPasw = !this.displayPasw;
    } else {
      this.displayPasw = !this.displayPasw;
    }
  }
/**
 * @param elem element
 */
  private focusElement(elem: ElementRef): void {
    elem.nativeElement.focus();
  }

  // private checkLanguaje(): void {
  //   this.translate.onLangChange.subscribe(() => {
  //     this.translateDropdown();
  //   });
  // }

  // private translateDropdown(): void {
  //   for (const [i, item] of this.options.entries()) {
  //     this.personalDataList[i].label = this.translate.instant(item);
  //   }
  // }
/**
 * on update user data
 */
  public onUpdateUserData(): void {
    if (this.personalDataForm.valid) {
      this.error = '';

      this.updateUserOnLDAP(this.personalDataForm.value, this.userServerData as IdentidadDigitalModificacion);
    } else {
      Object.keys(this.personalDataForm.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.personalDataForm.get(key).errors;
        if (controlErrors !== null) {
          Object.keys(controlErrors).forEach((keyError) => {
            if (keyError === 'required') {
              this.error = 'HEADER.PROFILE.ERROR-REQUIRED';
            } else if (keyError === 'pattern') {
              this.error = 'HEADER.PROFILE.ERROR-PATTERN';
            } else {}
          });
        }
      });
    }
  }
/**
 * @param userForm user from
 * @param userServerData user server data
 */
  private updateUserOnLDAP(userForm: PersonalUserData, userServerData: IdentidadDigitalModificacion): void {
    // console.log('%c Actualizar usuario en LDAP', 'color: #07B6FF');

    const identidad: string = this.authServ.getUserParam('userIdRequestIdentDig');
    const datosParcheoIdentidadDigital: IdentidadDigitalParcheo = {
      tipoIdentidad: 'A', // Este parámetro es siempre 'A' para el portal asegurados
      identidad: userForm.id,
      nombre: userForm.name,
      primerApellido: userForm.surname1,
      segundoApellido: userForm.surname2,
      correo: userServerData.correo,
      atributos: {
        'isos.tipoDocumentoIdentidad': userForm.docType,
        'isos.empresa': userServerData.atributos['isos.empresa'],
        'isos.usuario': userServerData.atributos['isos.usuario'],
        'isos.tipoUsuario': userServerData.atributos['isos.tipoUsuario'],
        'isos.idUsuario': userServerData.atributos['isos.idUsuario'],
        'isos.cuentaActiva': userServerData.atributos['isos.cuentaActiva'],
        'isos.cambioPassword': userServerData.atributos['isos.cambioPassword']
      }
    };

    this.manageReqSOSExtended.identDigSLModificarAtributosParticularesIdentidadDigital$(identidad, datosParcheoIdentidadDigital).subscribe(
      (res: any) => {
        // If everything is OK, then update user on ISOS too
        this.updateUserOnISOS(userForm, userServerData);
        this.getAndStoreUserDataFromSL(this.authServ.getUserParam('userIdRequestIdentDig'));
        this.statusModal.displaySuccessMessage('USER-DATA-UPDATED', true);
      },
      (err: HttpErrorResponse) => {
        this.statusModal.displayErrorMessage('USER-DATA-UPDATE-FAILS', true);
        this.getAndStoreUserDataFromSL(this.authServ.getUserParam('userIdRequestIdentDig'));
      }
    );
  }
/**
 * @param userForm user from
 * @param userServerData user server data
 */
  private updateUserOnISOS(userForm: PersonalUserData, userServerData: IdentidadDigitalModificacion): void {
    // console.log('%c Actualizar usuario en ISOS', 'color: #07B6FF');

    const identificacion: string = this.authServ.getUserParam('userIdRequestIdentDig');
    const identidad: IdentidadActualizar = {
      nombre: userForm.name,
      apellido1: userForm.surname1 || '',
      apellido2: userForm.surname2 || '',
      tipoDocumento: userForm.docType,
      email: userServerData.correo,
      tipoUsuario: userServerData.atributos['isos.tipoUsuario'],
      idioma: this.authServ.getUserParam('lang'),
      empresa: userServerData.atributos['isos.empresa'],
      cuentaActiva: true,
      identidad: userForm.id,
      cambioContrasena: true
    };

    this.manageReqSOS.identDigSOSActualizarUsuario$(identificacion, identidad).subscribe((res: DevolverMensaje) => {
    });
  }
}
