/**
 * iSOS Asegurados
 * La API de asegurados iSOS proporciona a los desarrolladores un conjunto de operaciones para la gestión de asegurados
 *
 * OpenAPI spec version: 1.1
 * Contact: apis@santalucia.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { CertificadoActualizarEntrada } from '../../../models/api/asegurados/CertificadoActualizarEntrada';
import { CertificadoDetalleSalida } from '../../../models/api/asegurados/CertificadoDetalleSalida';
import { ConsentimientosActualizar } from '../../../models/api/asegurados/ConsentimientosActualizar';
import { ConsentimientosSalida } from '../../../models/api/asegurados/ConsentimientosSalida';
import { DevolverAvances } from '../../../models/api/asegurados/DevolverAvances';
import { DevolverError } from '../../../models/api/asegurados/DevolverError';
import { DireccionEnvio } from '../../../models/api/asegurados/DireccionEnvio';
import { ExpedienteDetalleSalida } from '../../../models/api/asegurados/ExpedienteDetalleSalida';
import { ExpedientesSalida } from '../../../models/api/asegurados/ExpedientesSalida';
import { ListadoAdjuntos } from '../../../models/api/asegurados/ListadoAdjuntos';
import { ListadoDocumentacion } from '../../../models/api/asegurados/ListadoDocumentacion';
import { ListadoDocumentacionSuministros } from '../../../models/api/asegurados/ListadoDocumentacionSuministros';
import { ListadosAdjuntos } from '../../../models/api/asegurados/ListadosAdjuntos';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class AseguradosService {

    protected basePath = '/isos/asegurados/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath + '';
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param - consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Actualiza los consentimientos de un aseguro.
     *
     * @param - identificador Identificador de los consentimientos a actualizar.
     * @param - consentimientos Consentimientos a modificar
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosActualizarConsentimientos(identificador: string, consentimientos: Array<ConsentimientosActualizar>, observe?: 'body', reportProgress?: boolean): Observable<DevolverAvances>;
    public aseguradosActualizarConsentimientos(identificador: string, consentimientos: Array<ConsentimientosActualizar>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverAvances>>;
    public aseguradosActualizarConsentimientos(identificador: string, consentimientos: Array<ConsentimientosActualizar>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverAvances>>;
    public aseguradosActualizarConsentimientos(identificador: string, consentimientos: Array<ConsentimientosActualizar>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosActualizarConsentimientos.');
        }

        if (consentimientos === null || consentimientos === undefined) {
            throw new Error('Required parameter consentimientos was null or undefined when calling aseguradosActualizarConsentimientos.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DevolverAvances>(`${this.basePath}/consentimientos/${encodeURIComponent(String(identificador))}`,
            consentimientos,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Actualizar los datos de una incidencia.
     *
     * @param - identificador Identificador de la incidencia a actualizar
     * @param - indiceLista Indice de la lista a actualizar
     * @param - datosActualizar Datos de la incidencia a modificar.
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosActualizarDatosIncidencia(identificador: string, indiceLista: string, datosActualizar: CertificadoActualizarEntrada, observe?: 'body', reportProgress?: boolean): Observable<DevolverAvances>;
    public aseguradosActualizarDatosIncidencia(identificador: string, indiceLista: string, datosActualizar: CertificadoActualizarEntrada, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverAvances>>;
    public aseguradosActualizarDatosIncidencia(identificador: string, indiceLista: string, datosActualizar: CertificadoActualizarEntrada, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverAvances>>;
    public aseguradosActualizarDatosIncidencia(identificador: string, indiceLista: string, datosActualizar: CertificadoActualizarEntrada, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosActualizarDatosIncidencia.');
        }

        if (indiceLista === null || indiceLista === undefined) {
            throw new Error('Required parameter indiceLista was null or undefined when calling aseguradosActualizarDatosIncidencia.');
        }

        if (datosActualizar === null || datosActualizar === undefined) {
            throw new Error('Required parameter datosActualizar was null or undefined when calling aseguradosActualizarDatosIncidencia.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (indiceLista !== undefined && indiceLista !== null) {
            queryParameters = queryParameters.set('indiceLista', indiceLista as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DevolverAvances>(`${this.basePath}/completardatos/${encodeURIComponent(String(identificador))}`,
            datosActualizar,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Actualizar una dirección de envio
     *
     * @param - direccionEnvio Dirección del envio a modificar.
     * @param - identificador Identificador de la dirección sobre el que se actualizarán los datos
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosActualizarDireccionEnvio(direccionEnvio: DireccionEnvio, identificador: string, observe?: 'body', reportProgress?: boolean): Observable<DevolverAvances>;
    public aseguradosActualizarDireccionEnvio(direccionEnvio: DireccionEnvio, identificador: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverAvances>>;
    public aseguradosActualizarDireccionEnvio(direccionEnvio: DireccionEnvio, identificador: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverAvances>>;
    public aseguradosActualizarDireccionEnvio(direccionEnvio: DireccionEnvio, identificador: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (direccionEnvio === null || direccionEnvio === undefined) {
            throw new Error('Required parameter direccionEnvio was null or undefined when calling aseguradosActualizarDireccionEnvio.');
        }

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosActualizarDireccionEnvio.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DevolverAvances>(`${this.basePath}/direccionenvio/${encodeURIComponent(String(identificador))}`,
            direccionEnvio,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Devuelve el certificado del expediente consultado.
     *
     * @param - identificador Identificador del expediente
     * @param - indiceLista Indicador de la posicion en el array
     * @param - idioma ES,CA,EN o FR
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosConsultarCertificado(identificador: string, indiceLista: string, idioma: string, observe?: 'body', reportProgress?: boolean): Observable<CertificadoDetalleSalida>;
    public aseguradosConsultarCertificado(identificador: string, indiceLista: string, idioma: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CertificadoDetalleSalida>>;
    public aseguradosConsultarCertificado(identificador: string, indiceLista: string, idioma: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CertificadoDetalleSalida>>;
    public aseguradosConsultarCertificado(identificador: string, indiceLista: string, idioma: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosConsultarCertificado.');
        }

        if (indiceLista === null || indiceLista === undefined) {
            throw new Error('Required parameter indiceLista was null or undefined when calling aseguradosConsultarCertificado.');
        }

        if (idioma === null || idioma === undefined) {
            throw new Error('Required parameter idioma was null or undefined when calling aseguradosConsultarCertificado.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (indiceLista !== undefined && indiceLista !== null) {
            queryParameters = queryParameters.set('indiceLista', indiceLista as any);
        }
        if (idioma !== undefined && idioma !== null) {
            queryParameters = queryParameters.set('idioma', idioma as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CertificadoDetalleSalida>(`${this.basePath}/certificado/${encodeURIComponent(String(identificador))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Devuelve los consentimientos de un asegurado.
     *
     * @param - identificador Identificador a consultar.
     * @param - idioma ES,CA,EN o FR
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosConsultarConsentimientos(identificador: string, idioma: string, observe?: 'body', reportProgress?: boolean): Observable<ConsentimientosSalida>;
    public aseguradosConsultarConsentimientos(identificador: string, idioma: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ConsentimientosSalida>>;
    public aseguradosConsultarConsentimientos(identificador: string, idioma: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ConsentimientosSalida>>;
    public aseguradosConsultarConsentimientos(identificador: string, idioma: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosConsultarConsentimientos.');
        }

        if (idioma === null || idioma === undefined) {
            throw new Error('Required parameter idioma was null or undefined when calling aseguradosConsultarConsentimientos.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idioma !== undefined && idioma !== null) {
            queryParameters = queryParameters.set('idioma', idioma as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ConsentimientosSalida>(`${this.basePath}/consentimientos/${encodeURIComponent(String(identificador))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Devuelve el detalle del expediente consultado.
     *
     * @param - identificador Identificador del expediente
     * @param - idioma ES,CA,EN o FR
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosConsultarDetalle(identificador: string, idioma: string, observe?: 'body', reportProgress?: boolean): Observable<ExpedienteDetalleSalida>;
    public aseguradosConsultarDetalle(identificador: string, idioma: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExpedienteDetalleSalida>>;
    public aseguradosConsultarDetalle(identificador: string, idioma: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExpedienteDetalleSalida>>;
    public aseguradosConsultarDetalle(identificador: string, idioma: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosConsultarDetalle.');
        }

        if (idioma === null || idioma === undefined) {
            throw new Error('Required parameter idioma was null or undefined when calling aseguradosConsultarDetalle.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idioma !== undefined && idioma !== null) {
            queryParameters = queryParameters.set('idioma', idioma as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ExpedienteDetalleSalida>(`${this.basePath}/expedientes/${encodeURIComponent(String(identificador))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Consulta de los expedientes de un asegurado.
     *
     * @param - idAsegurado Indica el asegurado a consultar
     * @param - idioma ES,CA,EN o FR
     * @param - filtro Filtro 0-Abiertos, 1-Resueltos,2-Todos
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosConsultarExpedientes(idAsegurado: number, idioma: string, filtro: number, observe?: 'body', reportProgress?: boolean): Observable<ExpedientesSalida>;
    public aseguradosConsultarExpedientes(idAsegurado: number, idioma: string, filtro: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExpedientesSalida>>;
    public aseguradosConsultarExpedientes(idAsegurado: number, idioma: string, filtro: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExpedientesSalida>>;
    public aseguradosConsultarExpedientes(idAsegurado: number, idioma: string, filtro: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idAsegurado === null || idAsegurado === undefined) {
            throw new Error('Required parameter idAsegurado was null or undefined when calling aseguradosConsultarExpedientes.');
        }

        if (idioma === null || idioma === undefined) {
            throw new Error('Required parameter idioma was null or undefined when calling aseguradosConsultarExpedientes.');
        }

        if (filtro === null || filtro === undefined) {
            throw new Error('Required parameter filtro was null or undefined when calling aseguradosConsultarExpedientes.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idAsegurado !== undefined && idAsegurado !== null) {
            queryParameters = queryParameters.set('idAsegurado', idAsegurado as any);
        }
        if (idioma !== undefined && idioma !== null) {
            queryParameters = queryParameters.set('idioma', idioma as any);
        }
        if (filtro !== undefined && filtro !== null) {
            queryParameters = queryParameters.set('filtro', filtro as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ExpedientesSalida>(`${this.basePath}/expedientes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Crea un fuera de cobertura.
     *
     * @param - identificador Identificador para crear el fuera de cobertura.
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosCrearfueraCobertura(identificador: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public aseguradosCrearfueraCobertura(identificador: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public aseguradosCrearfueraCobertura(identificador: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public aseguradosCrearfueraCobertura(identificador: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosCrearfueraCobertura.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/fueracobertura/${encodeURIComponent(String(identificador))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Borra un archivo de un documento.
     * Borra un archivo de un documento.
     * @param - identificador Identificador del archivo a buscar
     * @param - indiceLista Indice de la lista del archivo a buscar
     * @param - nombreArchivo Nombre del archivo a eliminar
     * @param - tipoEncargo Tipo del archivo GD o ORD
     * @param - canal Canal usado PA-Portal asegurados o PP-Portal proveedores
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosEliminarArchivo(identificador: string, indiceLista: string, nombreArchivo: string, tipoEncargo: string, canal: string, observe?: 'body', reportProgress?: boolean): Observable<DevolverAvances>;
    public aseguradosEliminarArchivo(identificador: string, indiceLista: string, nombreArchivo: string, tipoEncargo: string, canal: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverAvances>>;
    public aseguradosEliminarArchivo(identificador: string, indiceLista: string, nombreArchivo: string, tipoEncargo: string, canal: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverAvances>>;
    public aseguradosEliminarArchivo(identificador: string, indiceLista: string, nombreArchivo: string, tipoEncargo: string, canal: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosEliminarArchivo.');
        }

        if (indiceLista === null || indiceLista === undefined) {
            throw new Error('Required parameter indiceLista was null or undefined when calling aseguradosEliminarArchivo.');
        }

        if (nombreArchivo === null || nombreArchivo === undefined) {
            throw new Error('Required parameter nombreArchivo was null or undefined when calling aseguradosEliminarArchivo.');
        }

        if (tipoEncargo === null || tipoEncargo === undefined) {
            throw new Error('Required parameter tipoEncargo was null or undefined when calling aseguradosEliminarArchivo.');
        }

        if (canal === null || canal === undefined) {
            throw new Error('Required parameter canal was null or undefined when calling aseguradosEliminarArchivo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (indiceLista !== undefined && indiceLista !== null) {
            queryParameters = queryParameters.set('indiceLista', indiceLista as any);
        }
        if (nombreArchivo !== undefined && nombreArchivo !== null) {
            queryParameters = queryParameters.set('nombreArchivo', nombreArchivo as any);
        }
        if (tipoEncargo !== undefined && tipoEncargo !== null) {
            queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
        }
        if (canal !== undefined && canal !== null) {
            queryParameters = queryParameters.set('canal', canal as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<DevolverAvances>(`${this.basePath}/documentacion/${encodeURIComponent(String(identificador))}/archivos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Borra un archivo de un documento de suministros.
     *
     * @param - identificador Identificador del archivo a buscar
     * @param - indiceLista Indice de la lista del archivo a buscar
     * @param - nombreArchivo Nombre del archivo a eliminar
     * @param - tipoEncargo Tipo del archivo GD o ORD
     * @param - canal Canal usado PA-Portal asegurados o PP-Portal proveedores
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosEliminarArchivoSuministros(
        identificador: string,
        indiceLista: string,
        nombreArchivo: string,
        tipoEncargo: string,
        canal: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<DevolverAvances>;
    public aseguradosEliminarArchivoSuministros(
        identificador: string,
        indiceLista: string,
        nombreArchivo: string,
        tipoEncargo: string,
        canal: string,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<DevolverAvances>>;
    public aseguradosEliminarArchivoSuministros(
        identificador: string,
        indiceLista: string,
        nombreArchivo: string,
        tipoEncargo: string,
        canal: string,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<DevolverAvances>>;
    public aseguradosEliminarArchivoSuministros(
        identificador: string,
        indiceLista: string,
        nombreArchivo: string,
        tipoEncargo: string,
        canal: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosEliminarArchivoSuministros.');
        }

        if (indiceLista === null || indiceLista === undefined) {
            throw new Error('Required parameter indiceLista was null or undefined when calling aseguradosEliminarArchivoSuministros.');
        }

        if (nombreArchivo === null || nombreArchivo === undefined) {
            throw new Error('Required parameter nombreArchivo was null or undefined when calling aseguradosEliminarArchivoSuministros.');
        }

        if (tipoEncargo === null || tipoEncargo === undefined) {
            throw new Error('Required parameter tipoEncargo was null or undefined when calling aseguradosEliminarArchivoSuministros.');
        }

        if (canal === null || canal === undefined) {
            throw new Error('Required parameter canal was null or undefined when calling aseguradosEliminarArchivoSuministros.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (indiceLista !== undefined && indiceLista !== null) {
            queryParameters = queryParameters.set('indiceLista', indiceLista as any);
        }
        if (nombreArchivo !== undefined && nombreArchivo !== null) {
            queryParameters = queryParameters.set('nombreArchivo', nombreArchivo as any);
        }
        if (tipoEncargo !== undefined && tipoEncargo !== null) {
            queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
        }
        if (canal !== undefined && canal !== null) {
            queryParameters = queryParameters.set('canal', canal as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<DevolverAvances>(`${this.basePath}/documentacion/suministros/${encodeURIComponent(String(identificador))}/archivos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Adjunta un archivo en un documento.
     *
     * @param - identificador Referencia, caso, etc
     * @param - indiceLista Indicador del indice de la lista.
     * @param - nombreArchivo Nombre del archivo.
     * @param - tipoEncargo Tipo del encargho (GD o FDC).
     * @param - canal PP(PortalProveedores) o PA(PortalAsegurados)
     * @param - visiblePA Visibilidad para el portal de asegurados.
     * @param - todosAdjuntados Determina si son ficheros obligatorios.
     * @param - fichero Fichero a insertar
     * @param - tipoRecordatorio Determina el tipo de recordatorio
     * @param - subTipoRecordatorio determina el sub tipo de recordatorio
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosInsertarArchivo(
        identificador: string,
        indiceLista: string,
        nombreArchivo: string,
        tipoEncargo: string,
        canal: string,
        visiblePA: boolean,
        todosAdjuntados: boolean,
        fichero: Blob,
        tipoRecordatorio?: string,
        subTipoRecordatorio?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<DevolverAvances>;
    public aseguradosInsertarArchivo(
        identificador: string,
        indiceLista: string,
        nombreArchivo: string,
        tipoEncargo: string,
        canal: string,
        visiblePA: boolean,
        todosAdjuntados: boolean,
        fichero: Blob,
        tipoRecordatorio?: string,
        subTipoRecordatorio?: string,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<DevolverAvances>>;
    public aseguradosInsertarArchivo(
        identificador: string,
        indiceLista: string,
        nombreArchivo: string,
        tipoEncargo: string,
        canal: string,
        visiblePA: boolean,
        todosAdjuntados: boolean,
        fichero: Blob,
        tipoRecordatorio?: string,
        subTipoRecordatorio?: string,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<DevolverAvances>>;
    public aseguradosInsertarArchivo(
        identificador: string,
        indiceLista: string,
        nombreArchivo: string,
        tipoEncargo: string,
        canal: string,
        visiblePA: boolean,
        todosAdjuntados: boolean,
        fichero: Blob,
        tipoRecordatorio?: string,
        subTipoRecordatorio?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosInsertarArchivo.');
        }

        if (indiceLista === null || indiceLista === undefined) {
            throw new Error('Required parameter indiceLista was null or undefined when calling aseguradosInsertarArchivo.');
        }

        if (nombreArchivo === null || nombreArchivo === undefined) {
            throw new Error('Required parameter nombreArchivo was null or undefined when calling aseguradosInsertarArchivo.');
        }

        if (tipoEncargo === null || tipoEncargo === undefined) {
            throw new Error('Required parameter tipoEncargo was null or undefined when calling aseguradosInsertarArchivo.');
        }

        if (canal === null || canal === undefined) {
            throw new Error('Required parameter canal was null or undefined when calling aseguradosInsertarArchivo.');
        }

        if (visiblePA === null || visiblePA === undefined) {
            throw new Error('Required parameter visiblePA was null or undefined when calling aseguradosInsertarArchivo.');
        }

        if (todosAdjuntados === null || todosAdjuntados === undefined) {
            throw new Error('Required parameter todosAdjuntados was null or undefined when calling aseguradosInsertarArchivo.');
        }

        if (fichero === null || fichero === undefined) {
            throw new Error('Required parameter fichero was null or undefined when calling aseguradosInsertarArchivo.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (indiceLista !== undefined && indiceLista !== null) {
            queryParameters = queryParameters.set('indiceLista', indiceLista as any);
        }
        if (nombreArchivo !== undefined && nombreArchivo !== null) {
            queryParameters = queryParameters.set('nombreArchivo', nombreArchivo as any);
        }
        if (tipoEncargo !== undefined && tipoEncargo !== null) {
            queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
        }
        if (canal !== undefined && canal !== null) {
            queryParameters = queryParameters.set('canal', canal as any);
        }
        if (visiblePA !== undefined && visiblePA !== null) {
            queryParameters = queryParameters.set('visiblePA', visiblePA as any);
        }
        if (todosAdjuntados !== undefined && todosAdjuntados !== null) {
            queryParameters = queryParameters.set('todosAdjuntados', todosAdjuntados as any);
        }
        if (tipoRecordatorio !== undefined && tipoRecordatorio !== null) {
            queryParameters = queryParameters.set('tipoRecordatorio', tipoRecordatorio as any);
        }
        if (subTipoRecordatorio !== undefined && subTipoRecordatorio !== null) {
            queryParameters = queryParameters.set('subTipoRecordatorio', subTipoRecordatorio as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        const convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (fichero !== undefined) {
            formParams = formParams.append('fichero', fichero as any) || formParams;
        }

        return this.httpClient.post<DevolverAvances>(`${this.basePath}/documentacion/${encodeURIComponent(String(identificador))}/archivos`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Adjunta un archivo en un documento de suministros.
     *
     * @param - identificador Identificador del archivo a buscar
     * @param - indiceLista Indicador del indice de la lista.
     * @param - nombreArchivo Nombre del archivo a adjuntar.
     * @param - tipoEncargo Tipo del encargho (GD o FDC).
     * @param - canal PP(PortalProveedores) o PA(PortalAsegurados)
     * @param - visiblePA Visibilidad para el portal de asegurados.
     * @param - todosAdjuntados Determina si son ficheros obligatorios
     * @param - fichero Fichero a insertar
     * @param - tipoRecordatorio Determina el tipo de recordatorio
     * @param - subTipoRecordatorio determina el sub tipo de recordatorio
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosInsertarArchivoSuministros(
        identificador: string,
        indiceLista: string,
        nombreArchivo: string,
        tipoEncargo: string,
        canal: string,
        visiblePA: boolean,
        todosAdjuntados: boolean,
        fichero: Blob,
        tipoRecordatorio?: string,
        subTipoRecordatorio?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<DevolverAvances>;
    public aseguradosInsertarArchivoSuministros(
        identificador: string,
        indiceLista: string,
        nombreArchivo: string,
        tipoEncargo: string,
        canal: string,
        visiblePA: boolean,
        todosAdjuntados: boolean,
        fichero: Blob,
        tipoRecordatorio?: string,
        subTipoRecordatorio?: string,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<DevolverAvances>>;
    public aseguradosInsertarArchivoSuministros(
        identificador: string,
        indiceLista: string,
        nombreArchivo: string,
        tipoEncargo: string,
        canal: string,
        visiblePA: boolean,
        todosAdjuntados: boolean,
        fichero: Blob,
        tipoRecordatorio?: string,
        subTipoRecordatorio?: string,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<DevolverAvances>>;
    public aseguradosInsertarArchivoSuministros(
        identificador: string,
        indiceLista: string,
        nombreArchivo: string,
        tipoEncargo: string,
        canal: string,
        visiblePA: boolean,
        todosAdjuntados: boolean,
        fichero: Blob,
        tipoRecordatorio?: string,
        subTipoRecordatorio?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosInsertarArchivoSuministros.');
        }

        if (indiceLista === null || indiceLista === undefined) {
            throw new Error('Required parameter indiceLista was null or undefined when calling aseguradosInsertarArchivoSuministros.');
        }

        if (nombreArchivo === null || nombreArchivo === undefined) {
            throw new Error('Required parameter nombreArchivo was null or undefined when calling aseguradosInsertarArchivoSuministros.');
        }

        if (tipoEncargo === null || tipoEncargo === undefined) {
            throw new Error('Required parameter tipoEncargo was null or undefined when calling aseguradosInsertarArchivoSuministros.');
        }

        if (canal === null || canal === undefined) {
            throw new Error('Required parameter canal was null or undefined when calling aseguradosInsertarArchivoSuministros.');
        }

        if (visiblePA === null || visiblePA === undefined) {
            throw new Error('Required parameter visiblePA was null or undefined when calling aseguradosInsertarArchivoSuministros.');
        }

        if (todosAdjuntados === null || todosAdjuntados === undefined) {
            throw new Error('Required parameter todosAdjuntados was null or undefined when calling aseguradosInsertarArchivoSuministros.');
        }

        if (fichero === null || fichero === undefined) {
            throw new Error('Required parameter fichero was null or undefined when calling aseguradosInsertarArchivoSuministros.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (indiceLista !== undefined && indiceLista !== null) {
            queryParameters = queryParameters.set('indiceLista', indiceLista as any);
        }
        if (nombreArchivo !== undefined && nombreArchivo !== null) {
            queryParameters = queryParameters.set('nombreArchivo', nombreArchivo as any);
        }
        if (tipoEncargo !== undefined && tipoEncargo !== null) {
            queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
        }
        if (canal !== undefined && canal !== null) {
            queryParameters = queryParameters.set('canal', canal as any);
        }
        if (visiblePA !== undefined && visiblePA !== null) {
            queryParameters = queryParameters.set('visiblePA', visiblePA as any);
        }
        if (todosAdjuntados !== undefined && todosAdjuntados !== null) {
            queryParameters = queryParameters.set('todosAdjuntados', todosAdjuntados as any);
        }
        if (tipoRecordatorio !== undefined && tipoRecordatorio !== null) {
            queryParameters = queryParameters.set('tipoRecordatorio', tipoRecordatorio as any);
        }
        if (subTipoRecordatorio !== undefined && subTipoRecordatorio !== null) {
            queryParameters = queryParameters.set('subTipoRecordatorio', subTipoRecordatorio as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        const convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (fichero !== undefined) {
            formParams = formParams.append('fichero', fichero as any) || formParams;
        }

        return this.httpClient.post<DevolverAvances>(`${this.basePath}/documentacion/suministros/${encodeURIComponent(String(identificador))}/archivos`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Obtiene un listado de archivos adjuntos asociados a un documento.
     * Obtiene un listado de archivos adjuntos asociados a un documento.
     * @param - identificador Identificador del archivo a buscar
     * @param - indiceLista Indice de la lista del archivo a buscar
     * @param - tipoEncargo Tipo del archivo GD o ORD
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosListadoAdjuntosDocumentacion(identificador: string, indiceLista: string, tipoEncargo: string, observe?: 'body', reportProgress?: boolean): Observable<ListadoAdjuntos>;
    public aseguradosListadoAdjuntosDocumentacion(identificador: string, indiceLista: string, tipoEncargo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListadoAdjuntos>>;
    public aseguradosListadoAdjuntosDocumentacion(identificador: string, indiceLista: string, tipoEncargo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListadoAdjuntos>>;
    public aseguradosListadoAdjuntosDocumentacion(identificador: string, indiceLista: string, tipoEncargo: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosListadoAdjuntosDocumentacion.');
        }

        if (indiceLista === null || indiceLista === undefined) {
            throw new Error('Required parameter indiceLista was null or undefined when calling aseguradosListadoAdjuntosDocumentacion.');
        }

        if (tipoEncargo === null || tipoEncargo === undefined) {
            throw new Error('Required parameter tipoEncargo was null or undefined when calling aseguradosListadoAdjuntosDocumentacion.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (indiceLista !== undefined && indiceLista !== null) {
            queryParameters = queryParameters.set('indiceLista', indiceLista as any);
        }
        if (tipoEncargo !== undefined && tipoEncargo !== null) {
            queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ListadoAdjuntos>(`${this.basePath}/documentacion/${encodeURIComponent(String(identificador))}/adjuntos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Obtiene un listado de archivos adjuntos de suministros asociados a un documento.
     *
     * @param - identificador Identificador del archivo a buscar
     * @param - indiceLista Indice de la lista del archivo a buscar
     * @param - tipoEncargo Tipo del archivo GD o ORD
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosListadoAdjuntosDocumentacionSuministros(identificador: string, indiceLista: string, tipoEncargo: string, observe?: 'body', reportProgress?: boolean): Observable<ListadoAdjuntos>;
    public aseguradosListadoAdjuntosDocumentacionSuministros(identificador: string, indiceLista: string, tipoEncargo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListadoAdjuntos>>;
    public aseguradosListadoAdjuntosDocumentacionSuministros(identificador: string, indiceLista: string, tipoEncargo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListadoAdjuntos>>;
    public aseguradosListadoAdjuntosDocumentacionSuministros(identificador: string, indiceLista: string, tipoEncargo: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosListadoAdjuntosDocumentacionSuministros.');
        }

        if (indiceLista === null || indiceLista === undefined) {
            throw new Error('Required parameter indiceLista was null or undefined when calling aseguradosListadoAdjuntosDocumentacionSuministros.');
        }

        if (tipoEncargo === null || tipoEncargo === undefined) {
            throw new Error('Required parameter tipoEncargo was null or undefined when calling aseguradosListadoAdjuntosDocumentacionSuministros.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (indiceLista !== undefined && indiceLista !== null) {
            queryParameters = queryParameters.set('indiceLista', indiceLista as any);
        }
        if (tipoEncargo !== undefined && tipoEncargo !== null) {
            queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ListadoAdjuntos>(`${this.basePath}/documentacion/suministros/${encodeURIComponent(String(identificador))}/adjuntos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Obtiene un listado de documentos para la tramitación de encargos.
     * Obtiene un listado de documentos para la tramitación de encargos.
     * @param - identificador
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosListadoDocumentacion(identificador: string, observe?: 'body', reportProgress?: boolean): Observable<ListadoDocumentacion>;
    public aseguradosListadoDocumentacion(identificador: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListadoDocumentacion>>;
    public aseguradosListadoDocumentacion(identificador: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListadoDocumentacion>>;
    public aseguradosListadoDocumentacion(identificador: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosListadoDocumentacion.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ListadoDocumentacion>(`${this.basePath}/documentacion/${encodeURIComponent(String(identificador))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Obtiene un listado de documentos de suministros para la tramitación de encargos.
     *
     * @param - identificador
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosListadoDocumentacionSuministros(identificador: string, observe?: 'body', reportProgress?: boolean): Observable<ListadoDocumentacionSuministros>;
    public aseguradosListadoDocumentacionSuministros(identificador: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListadoDocumentacionSuministros>>;
    public aseguradosListadoDocumentacionSuministros(identificador: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListadoDocumentacionSuministros>>;
    public aseguradosListadoDocumentacionSuministros(identificador: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosListadoDocumentacionSuministros.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ListadoDocumentacionSuministros>(`${this.basePath}/documentacion/suministros/${encodeURIComponent(String(identificador))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Obtiene un archivo de un Documento.
     * Obtiene un archivo de un Documento.
     * @param - identificador Identificador del archivo a buscar
     * @param - indiceLista Indice de la lista del archivo a buscar
     * @param - nombreArchivo Nombre del archivo a buscar.
     * @param - tipoEncargo Tipo del archivo GD o ORD
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosObtenerArchivo(identificador: string, indiceLista: string, nombreArchivo: string, tipoEncargo: string, observe?: 'body', reportProgress?: boolean): Observable<ListadosAdjuntos>;
    public aseguradosObtenerArchivo(identificador: string, indiceLista: string, nombreArchivo: string, tipoEncargo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListadosAdjuntos>>;
    public aseguradosObtenerArchivo(identificador: string, indiceLista: string, nombreArchivo: string, tipoEncargo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListadosAdjuntos>>;
    public aseguradosObtenerArchivo(identificador: string, indiceLista: string, nombreArchivo: string, tipoEncargo: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosObtenerArchivo.');
        }

        if (indiceLista === null || indiceLista === undefined) {
            throw new Error('Required parameter indiceLista was null or undefined when calling aseguradosObtenerArchivo.');
        }

        if (nombreArchivo === null || nombreArchivo === undefined) {
            throw new Error('Required parameter nombreArchivo was null or undefined when calling aseguradosObtenerArchivo.');
        }

        if (tipoEncargo === null || tipoEncargo === undefined) {
            throw new Error('Required parameter tipoEncargo was null or undefined when calling aseguradosObtenerArchivo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (indiceLista !== undefined && indiceLista !== null) {
            queryParameters = queryParameters.set('indiceLista', indiceLista as any);
        }
        if (nombreArchivo !== undefined && nombreArchivo !== null) {
            queryParameters = queryParameters.set('nombreArchivo', nombreArchivo as any);
        }
        if (tipoEncargo !== undefined && tipoEncargo !== null) {
            queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ListadosAdjuntos>(`${this.basePath}/documentacion/${encodeURIComponent(String(identificador))}/archivos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Obtiene un archivo de un Documento de suministros.
     *
     * @param - identificador Identificador del archivo a buscar
     * @param - indiceLista Indice de la lista del archivo a buscar
     * @param - nombreArchivo Nombre del archivo a buscar.
     * @param - tipoEncargo Tipo del archivo GD o ORD
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosObtenerArchivoSuministros(identificador: string, indiceLista: string, nombreArchivo: string, tipoEncargo: string, observe?: 'body', reportProgress?: boolean): Observable<ListadosAdjuntos>;
    public aseguradosObtenerArchivoSuministros(identificador: string, indiceLista: string, nombreArchivo: string, tipoEncargo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListadosAdjuntos>>;
    public aseguradosObtenerArchivoSuministros(identificador: string, indiceLista: string, nombreArchivo: string, tipoEncargo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListadosAdjuntos>>;
    public aseguradosObtenerArchivoSuministros(identificador: string, indiceLista: string, nombreArchivo: string, tipoEncargo: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosObtenerArchivoSuministros.');
        }

        if (indiceLista === null || indiceLista === undefined) {
            throw new Error('Required parameter indiceLista was null or undefined when calling aseguradosObtenerArchivoSuministros.');
        }

        if (nombreArchivo === null || nombreArchivo === undefined) {
            throw new Error('Required parameter nombreArchivo was null or undefined when calling aseguradosObtenerArchivoSuministros.');
        }

        if (tipoEncargo === null || tipoEncargo === undefined) {
            throw new Error('Required parameter tipoEncargo was null or undefined when calling aseguradosObtenerArchivoSuministros.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (indiceLista !== undefined && indiceLista !== null) {
            queryParameters = queryParameters.set('indiceLista', indiceLista as any);
        }
        if (nombreArchivo !== undefined && nombreArchivo !== null) {
            queryParameters = queryParameters.set('nombreArchivo', nombreArchivo as any);
        }
        if (tipoEncargo !== undefined && tipoEncargo !== null) {
            queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ListadosAdjuntos>(`${this.basePath}/documentacion/suministros/${encodeURIComponent(String(identificador))}/archivos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Recupera un fichero de la lista de documentos.
     * Recupera un fichero de la lista de documentos.
     * @param - identificador
     * @param - lista Lista de ficheros a mostrar
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosRecuperarFicheroDocumentacion(identificador: string, lista: number, observe?: 'body', reportProgress?: boolean): Observable<DevolverAvances>;
    public aseguradosRecuperarFicheroDocumentacion(identificador: string, lista: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverAvances>>;
    public aseguradosRecuperarFicheroDocumentacion(identificador: string, lista: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverAvances>>;
    public aseguradosRecuperarFicheroDocumentacion(identificador: string, lista: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling aseguradosRecuperarFicheroDocumentacion.');
        }

        if (lista === null || lista === undefined) {
            throw new Error('Required parameter lista was null or undefined when calling aseguradosRecuperarFicheroDocumentacion.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (lista !== undefined && lista !== null) {
            queryParameters = queryParameters.set('lista', lista as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DevolverAvances>(`${this.basePath}/documentacion/beneficiarios/${encodeURIComponent(String(identificador))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Recupera la lista de documentos fijos del beneficiario.
     * Recupera la lista de documentos fijos del beneficiario.
     * @param - lista Lista de ficheros a mostrar
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public aseguradosRecuperarListadoDocumentacion(lista: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public aseguradosRecuperarListadoDocumentacion(lista: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public aseguradosRecuperarListadoDocumentacion(lista: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public aseguradosRecuperarListadoDocumentacion(lista: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (lista === null || lista === undefined) {
            throw new Error('Required parameter lista was null or undefined when calling aseguradosRecuperarListadoDocumentacion.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (lista !== undefined && lista !== null) {
            queryParameters = queryParameters.set('lista', lista as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/documentacion/beneficiarios`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
