import { IdentidadDigitalConsulta } from '../../../core/models/api/identidad-digital/IdentidadDigitalConsulta';
/**
 * @param userData user data
 * @returns return
 */
export const simplifyUserDataSL: (userData: IdentidadDigitalConsulta) => IdentidadDigitalConsulta =
(userData: IdentidadDigitalConsulta): IdentidadDigitalConsulta => {
  const newObject: {} = {};
  for (const elem in userData) {
    if (elem === 'atributos') {
      for (const attribute of Object.entries(userData[elem])) {
        Object.assign(newObject, { [attribute[0]]: attribute[1] });
      }
    } else {
      Object.assign(newObject, { [elem]: userData[elem] });
    }
  }
  return newObject;
};
