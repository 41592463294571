import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expirationPipe'
})
export class ExpirationPipe implements PipeTransform {
  /**
   * @param value value
   * @returns return
   */
  transform(value: any): string {
    let convertedValue: any = value;

    if (value >= 0) {
      convertedValue = `${value} días`;
    } else if (value < 0) {
      convertedValue = `Hace ${Math.abs(value)} días que venció`;
    } else {
      convertedValue = '-';
    }

    return convertedValue;
  }
}
