import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DevolverAvances } from 'projects/portal-asegurados-front/src/app/core/models/api/asegurados/DevolverAvances';
import { ManageRequestServiceExtended } from 'projects/portal-asegurados-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service.utils-extended';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-delete-file-modal',
  templateUrl: './delete-file-modal.component.html',
  styleUrls: ['./delete-file-modal.component.scss']
})
export class DeleteFileModalComponent implements OnInit, OnDestroy {
  //#region PROPS
  @Input() identifier: any;
  @Input() docName: any;

  @Output() clickEmitter = new EventEmitter<any>();
  @Output() updateTableData = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  //#endregion

  //#region STATE
  private subscriptions: Subscription[] = [];

  //#endregion
/**
 * @param manageReqSOSExtended manageReqSOSExtended
 */
  constructor(private manageReqSOSExtended: ManageRequestServiceExtended) {
    this.manageReqSOSExtended = manageReqSOSExtended;
  }
/**
 * on init
 */
  ngOnInit(): void {
    this.clickEmitter.emit(this.handleClick.bind(this));
  }
/**
 * on destroy
 */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }
/**
 * unsubscribeFromSubscriptions
 */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }
/**
 * handleclick
 */
  public handleClick(): void {
    this.subscriptions.push(
      this.manageReqSOSExtended
        .provAsegEliminarAdjunto$(this.identifier, this.docName)
        .pipe(
          tap(() => {
            this.successOperation.emit();
            this.manageSelfDisplay.emit(false);
          })
        )
        .subscribe((res: DevolverAvances) => {
          this.updateTableData.emit();
        })
    );
  }
}
