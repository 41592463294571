/**
 * Identidad Digital
 * La API Identidad digital proporciona un conjunto de operaciones que permiten gestionar datos de acceso a las plataformas de distintos roles (clientes, mediadores, usuarios, etc).
 *
 * OpenAPI spec version: 1.2
 * Contact: apis@santalucia.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class CredencialesService {

    protected basePath = '/identidad-digital/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath + '';
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param - consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Reseteo de userPassword. Se recibe un id polimorfico
     * Reseteo de userPassword mediante llamada a servicios de RHSSO(keycloak)
     * @param - identidad Identificador único de la identidad digital. Generalmente será el NIF/NIE pero puede ser un idUsuario interno
     * @param - tipoIdentidad Tipos de identidad.  Valores permitidos:  C – Clientes Web E - Empleados M – Mediadores P - Isos Proveedores A - Isos Asegurados
     * @param - xRequestId Identificador único de la petición
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public putReseteoPassword(identidad: string, tipoIdentidad: 'C' | 'E' | 'M' | 'P' | 'A', xRequestId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public putReseteoPassword(identidad: string, tipoIdentidad: 'C' | 'E' | 'M' | 'P' | 'A', xRequestId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public putReseteoPassword(identidad: string, tipoIdentidad: 'C' | 'E' | 'M' | 'P' | 'A', xRequestId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public putReseteoPassword(identidad: string, tipoIdentidad: 'C' | 'E' | 'M' | 'P' | 'A', xRequestId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identidad === null || identidad === undefined) {
            throw new Error('Required parameter identidad was null or undefined when calling putReseteoPassword.');
        }

        if (tipoIdentidad === null || tipoIdentidad === undefined) {
            throw new Error('Required parameter tipoIdentidad was null or undefined when calling putReseteoPassword.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tipoIdentidad !== undefined && tipoIdentidad !== null) {
            queryParameters = queryParameters.set('tipoIdentidad', tipoIdentidad as any);
        }

        let headers = this.defaultHeaders;
        if (xRequestId !== undefined && xRequestId !== null) {
            headers = headers.set('X-Request-Id', String(xRequestId));
        }

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];

        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/identidades/${encodeURIComponent(String(identidad))}/reseteo-password`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
