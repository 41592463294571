import { AfterViewInit, Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-required-docs-modal',
  templateUrl: './no-required-docs-modal.component.html',
  styleUrls: [ './no-required-docs-modal.component.scss' ]
})
export class NoRequiredDocsModalComponent {
  @Input() inputModalData: { documents: Array<string> };
/**
 * constructor
 */
  constructor() {}
}
