/**
 * Generate a random pass with length-1 alphanumeric characters and a special one
 *
 * @param length Length of the needed pass
 * @returns return
 */
export const generateRandomPass: (length: number) => string = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const specials = '!"·$%&/()=?¿"';
  const charactersLength: number = characters.length;
  const specialsLength: number = specials.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result + specials.charAt(Math.floor(Math.random() * specialsLength));
};
