import { HttpErrorResponse } from '@angular/common/http';
import { Numbers } from '../../../utils/constants';
import { environment } from 'projects/portal-asegurados-front/src/environments/environment';

enum Colors {
  Red = 'FF0707',
  Yellow = 'FFFE07'
}

enum Severity {
  HIGH = 'FF0707',
  ADVICE = 'ffb521',
  INFO = '2160ff'
}

/**
 * Impresion de error de servidor por consola
 * @param error err
 */
export const displayServerErrorInConsole: (error: HttpErrorResponse) => void = (error: HttpErrorResponse): void => {
  // Get code status error (400,401,500...)
  const status = error.status || null;
  const message = error.error.mensaje || error.message || null;
  setCriticality(error);

  const configConsoleLog: (errorCode?: string | number, errorMessage?: string, color?: string) => void =
                          (errorCode: string | number = 'Error desconocido', errorMessage?: string, color: string = Colors.Red): void =>
    // console.log(`%c ERROR ${errorCode}: ${errorMessage}`, `color: #${color}`);

  configConsoleLog(status, message, Colors.Yellow);
};
/**
 * insercion en tabla de todas las llamadas con errores
 * @param error todos los datos del error
 */
export const setCriticality: (error: HttpErrorResponse) => void = (error: HttpErrorResponse): void => {
  let criticy: string;
  if (error.status === Numbers.number500 || error.status === Numbers.number503 || error.status === Numbers.number400) {
    criticy = 'alta';
  } else if (error.status === Numbers.number504 || error.status === Numbers.number403 || error.status === Numbers.number401 || error.status === Numbers.number502) {
    criticy = 'media';
  } else {
    criticy = 'baja';
  }
  let errorText: string;
  const nameApi: string = error.url.replace(environment.loginUrl, '');
  let mensajeErr: string;
  if (error.error.mensaje === null) {
    mensajeErr = '';
  } else {
    mensajeErr = ' - ' + error.error.mensaje;
  }
  errorText = 'Portal Asegurados; Criticidad: ' + criticy + '; Error: ' + error.status + mensajeErr + '; Api: ' + nameApi;
};

/**
 * Impresión de error de cliente por consola
 * @param error err
 * @param severity severity
 */
export const displayClientErrorInConsole: (error: string, severity?: 'HIGH' | 'ADVICE' | 'INFO') => void = (error: string, severity: 'HIGH' | 'ADVICE' | 'INFO' = 'HIGH'): void => {
  let color = 'white';
  if (severity === 'ADVICE') {
    color = 'black';
  }
  // console.log(`%c ERROR: ${error} `, `background: #${Severity[severity]}; color: ${color}`);
};
