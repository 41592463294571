import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Table, TableDataSource, TableStatus } from '../no-category/table/models/table';
import { Numbers } from '../../../utils/constants';

interface CardDisplay {
  header?: boolean;
  footer?: boolean;
}

@Component({
  selector: 'app-card-data',
  templateUrl: './card-data.component.html',
  styleUrls: ['./card-data.component.scss']
})
export class CardDataComponent implements OnInit {
  @Input() inputTableStatus: TableStatus;
  @Input() tableConfig: Table;
  @Input() cardDisplay: CardDisplay;
  @Input() iconsState: string;
  @Input() detail: boolean;

  @Output() rowsSelectedEvent: EventEmitter<any | any[]> = new EventEmitter<any>();
  @Output() iconClick: EventEmitter<any | any[]> = new EventEmitter<any>();

  public tableStatus: TableStatus;
  public rowsSelected: any[] = [];
/**
 * on init
 */
  ngOnInit(): void {
    const defaultTableStatus: TableStatus = {
      rowsToDisplay: Numbers.number20,
      firstElement: Numbers.number0
    };
    if (this.inputTableStatus) {
      this.tableStatus = this.inputTableStatus;
    } else {
      this.tableStatus = defaultTableStatus;
    }
  }
/**
 * rpw selected
 */
  onRowSelect(): void {
    this.rowsSelectedEvent.emit(this.inputTableStatus.rowsSelected);
  }
/**
 * @param rowData row data
 * @param option options
 */
  onManageRow(rowData: TableDataSource, option: string): void {
    this.iconClick.emit({ rowData, option });
  }

  /**
   * trackByFn
   * @param item item
   * @returns item id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
