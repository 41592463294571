/**
 * imports
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { AccordionModule } from 'primeng/accordion';

import { FilterMenuComponent } from './components/menus/filter-menu/filter-menu.component';
import { ButtonComponent } from './components/no-category/button/button.component';
import { UserMenuComponent } from './components/menus/user-menu/user-menu.component';
import { HelpModalComponent } from './components/modals/help-modal/help-modal.component';
import { BackdropRibbonModalComponent } from './components/modals/backdrop-ribbon-modal/backdrop-ribbon-modal.component';
import { TimerComponent } from './components/no-category/timer/timer.component';
import { TableComponent } from './components/no-category/table/table.component';
import { TabsComponent } from './components/selectors/tabs/tabs.component';
import { NotificationMenuComponent } from './components/menus/notification-menu/notification-menu.component';
import { MobileFilterMenuComponent } from './components/menus/mobile-filter-menu/mobile-filter-menu.component';
import { BasicModalComponent } from './components/modals/basic-modal/basic-modal.component';
import { CardDataComponent } from './components/card-data/card-data.component';
import { UploadFileComponent } from './components/no-category/upload-file/upload-file.component';
import { ControlIconsComponent } from './components/no-category/table/control-icons/control-icons.component';
import { BackdropNavComponent } from './components/no-category/backdrop-nav/backdrop-nav.component';
import { LoaderComponent } from './components/loader/loader.component';

import { SortByPipe } from './pipes/sort-by.pipe';

import { InputAriaDirective } from './directives/input-aria.directive';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { SelectBoxDirective } from './directives/select-box.directive';
import { AvoidSpecialCharactersDirective } from './directives/avoid-special-characters.directive';
import { AvoidNumberOrZerosDirective } from './directives/avoid-number-or-zeros.directive';
import { LimitCharactersDirective } from './directives/limit-characters.directive';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { InputAriaRadioDirective } from './directives/input-aria-radio.directive';
import { StatusModalComponent } from './components/modals/status-modal/status-modal.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { AcceptsRegexCharactersDirective } from './directives/accepts-regex-characters.directive';
import { DeleteFileModalComponent } from './components/modals/delete-file-modal/delete-file-modal.component';
import { ConvertDataInfoPipe } from './pipes/convert-data-info.pipe';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ExpirationPipe } from './pipes/expiration.pipe';
import { CardAttachComponent } from './components/card-attach/card-attach.component';
import { AttachedDocsModalComponent } from './components/modals/attached-docs-modal/attached-docs-modal.component';
import { WebcamModule } from 'ngx-webcam';
import { NoRequiredDocsModalComponent } from './components/modals/no-required-docs-modal/no-required-docs-modal.component';
/**
 * components
 */
const components: any = [
  FilterMenuComponent,
  UserMenuComponent,
  ButtonComponent,
  HelpModalComponent,
  BackdropRibbonModalComponent,
  TimerComponent,
  TableComponent,
  TabsComponent,
  NotificationMenuComponent,
  PaginatorComponent,
  MobileFilterMenuComponent,
  BasicModalComponent,
  CardDataComponent,
  UploadFileComponent,
  ControlIconsComponent,
  BackdropNavComponent,
  LoaderComponent,
  StatusModalComponent,
  ConfirmationModalComponent,
  StatusModalComponent,
  DeleteFileModalComponent,
  CardAttachComponent,
  AttachedDocsModalComponent,
  NoRequiredDocsModalComponent
];
/**
 * directives
 */
const directives: any = [
  InputAriaDirective,
  AcceptsRegexCharactersDirective,
  AutoFocusDirective,
  SelectBoxDirective,
  AvoidSpecialCharactersDirective,
  AvoidNumberOrZerosDirective,
  LimitCharactersDirective,
  InputAriaRadioDirective
];
/**
 * pipes
 */
const pipes: any = [SortByPipe, ConvertDataInfoPipe, ExpirationPipe];
/**
 * primeNGModules
 */
const primeNGModules: any = [ButtonModule, CheckboxModule, DialogModule, TableModule, DropdownModule, InputTextModule, CalendarModule, RadioButtonModule, AccordionModule];
/**
 * modules
 */
const modules: any[] = [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, RouterModule, HttpClientModule, GooglePlaceModule, WebcamModule, ...primeNGModules];
/**
 * NgModule
 */
@NgModule({
  declarations: [...components, ...directives, ...pipes],
  entryComponents: [MobileFilterMenuComponent],
  imports: [...modules],
  exports: [...modules, ...components, ...directives, ...pipes]
})
export class SharedModule {}
