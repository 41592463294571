import { AfterContentInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Numbers } from '../../utils/constants';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit, OnDestroy {
  /**
   * @param el element
   */
  constructor(private el: ElementRef) {
    this.el = el;
  }
/**
 * after content init
 */
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, Numbers.number300);
  }
/**
 * on destroy
 */
  ngOnDestroy(): void {
    const input: any = document.querySelector('#after-modal-focus');
    if (input) {
      input.focus();
    }
  }
}
