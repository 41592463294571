import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-attach',
  templateUrl: './card-attach.component.html',
  styleUrls: ['./card-attach.component.scss']
})
export class CardAttachComponent {
  @Input() item: { contenido: string; fechaCreacion: string; nombre: string; soloDescarga: boolean; usuario: string; visiblePA: string; visiblePP: string };

  @Output() showDocumentClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() downloadDocumentClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() eraseDocumentClick: EventEmitter<{ documentName: string; soloDescarga: boolean }> = new EventEmitter<{ documentName: string; soloDescarga: boolean }>();
/**
 * @param documentName name document
 */
  showDocument(documentName: string): void {
    this.showDocumentClick.emit(documentName);
  }
/**
 * @param documentName name document
 */
  downloadDocument(documentName: string): void {
    this.downloadDocumentClick.emit(documentName);
  }
/**
 * @param documentName document name
 * @param soloDescarga only download
 */
  onOpenEraseDocumentModal(documentName: string, soloDescarga: boolean): void {
    this.eraseDocumentClick.emit({ documentName, soloDescarga });
  }
}
