import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Numbers } from 'projects/portal-asegurados-front/src/app/utils/constants';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  @Input() displayTimer = true;
  @Output() btnClick: EventEmitter<any> = new EventEmitter<any>();

  public time: Date = new Date();
/**
 * on init
 */
  ngOnInit() {
    setInterval(() => {
      this.time = new Date();
    }, Numbers.number30000);
  }
}
